import React from "react";
import { Document } from "@contentful/rich-text-types";
import { BentoCoreModulesCollectionItem } from "@src/lib/services/server/contentful";
import { RichTextBlock, SmartLink } from "@src/components";

type BentoCoreDetailsProps = {
  items: BentoCoreModulesCollectionItem[];
  activePath: string | null;
};

const BentoCoreDetails: React.FC<BentoCoreDetailsProps> = ({
  items,
  activePath
}) => {
  const handleSVGClick = () => {
    const firstModuleSVGElement = document.querySelector(
      ".bentocore-module[aria-labelledby='Restaurant Website']"
    );

    firstModuleSVGElement?.dispatchEvent(
      new MouseEvent("click", { bubbles: true })
    );
  };

  return (
    <>
      {items.map(item => {
        const {
          moduleTitle,
          sectionTitle,
          sectionDescription,
          ctaTitle,
          ctaUrl
        } = item;

        const richText = sectionDescription?.json as Document;

        return (
          activePath?.toLowerCase() === moduleTitle?.toLowerCase() && (
            <div key={moduleTitle} className="text-center md:text-left">
              {sectionTitle && (
                <h2 className="text-balance text-3xl font-bold text-pr-dark-gray-500 md:text-5xl">
                  {sectionTitle}
                </h2>
              )}
              {sectionDescription && <RichTextBlock text={richText} />}
              {ctaTitle && activePath === "BentoCore" ? (
                <button
                  onClick={handleSVGClick}
                  className="pr-button pr-button-lg pr-button ease mx-auto bg-ada-salmon-500 text-white hover:bg-ada-teal-400 lg:mx-0"
                >
                  {ctaTitle}
                </button>
              ) : (
                ctaTitle && (
                  <SmartLink
                    href={ctaUrl ?? ""}
                    className="pr-button pr-button-lg pr-button ease mx-auto bg-ada-salmon-500 text-white hover:bg-ada-teal-400 lg:mx-0"
                  >
                    {ctaTitle}
                  </SmartLink>
                )
              )}
            </div>
          )
        );
      })}
    </>
  );
};

export { BentoCoreDetails };

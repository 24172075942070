/* eslint-disable tailwindcss/no-contradicting-classname */
import * as React from "react";
import { Service } from "@src/lib/services/server/contentful";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import {
  ProductContainer,
  RichText,
  SmartLink,
  ContentfulImage,
  ProductVideoBlock
} from "@src/components";
import Arrow from "@/img/icons/arrow.svg";
import { PlyrVideo } from "../PlyrVideo";

export type TrendReportImageWithTextBlockProps = {
  heading?: string | null;
  eyebrow?: string | null;
  text?: Document | null;
  image?: IContentfulImage | null;
  video?: string | null;
  primaryIcon?: IContentfulImage | null;
  secondaryIcon?: IContentfulImage | null;
  secondaryText?: Document | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
  ctaId?: string | null;
  orientation?: string | null;
  imageMobilePosition?: string | null;
  servicesLabel?: string | null;
  items: Service[];
};

export function TrendReportImageWithTextBlock({
  eyebrow,
  heading,
  text,
  image,
  video,
  primaryIcon,
  secondaryIcon,
  secondaryText,
  ctaTitle,
  ctaUrl,
  ctaStyle = "primary",
  ctaId,
  orientation = "fullwidthwithimageonright",
  imageMobilePosition,
  servicesLabel,
  items
}: TrendReportImageWithTextBlockProps) {
  const containedImageDimensions = {
    width: image?.width || 1648,
    height: image?.height || 1470
  };

  const containedImageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: containedImageDimensions.width,
    height: containedImageDimensions.height
  };

  const fullWidthImageDimensions = {
    width: 1648,
    height: 1470
  };

  const fullWidthImageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: fullWidthImageDimensions.width,
    height: fullWidthImageDimensions.height
  };

  const defaultOrientation: string = "fullwidthwithimageonright";

  const mobileOrientation =
    imageMobilePosition == "Bottom" ? "imageonbottom" : "imageontop";

  const headingClassNames: { [key: string]: string } = {
    containedwithimageonleft: "type-pr-beta lg:text-[2.75rem]",
    containedwithimageonright: "type-pr-beta lg:text-[2.75rem]",
    fullwidthwithimageonleft: "type-pr-delta lg:text-[2.75rem]",
    fullwidthwithimageonright: "type-pr-delta lg:text-[2.75rem]"
  };

  const figureClassNames: { [key: string]: string } = {
    containedwithimageonleft: "",
    containedwithimageonright: "",
    fullwidthwithimageonleft: "h-full aspect-square",
    fullwidthwithimageonright: "h-full aspect-square"
  };

  const imageClassNames: { [key: string]: string } = {
    containedwithimageonleft: "",
    containedwithimageonright: "",
    fullwidthwithimageonleft: "object-cover",
    fullwidthwithimageonright: "object-cover"
  };

  const imageLayouts: { [key: string]: "fill" | "responsive" } = {
    containedwithimageonleft: "responsive",
    containedwithimageonright: "responsive",
    fullwidthwithimageonleft: "fill",
    fullwidthwithimageonright: "fill"
  };

  const colClassNames: { [key: string]: string } = {
    containedwithimageonleft:
      "mx-auto max-w-[1200px] md:gap-5 lg:gap-10 xl:gap-20",
    containedwithimageonright:
      "mx-auto max-w-[1200px] md:gap-5 lg:gap-10 xl:gap-20",
    fullwidthwithimageonleft: "md:-mx-5 lg:-mx-8 2xl:-mx-24",
    fullwidthwithimageonright: "md:-mx-5 lg:-mx-8 2xl:-mx-24"
  };

  const imageColClassNames: { [key: string]: string } = {
    containedwithimageonleft:
      "flex items-center md:col-span-2 md:col-start-1 md:col-end-2",
    containedwithimageonright:
      "flex items-center md:col-span-2 md:col-start-2 md:col-end-3",
    fullwidthwithimageonleft:
      "-mx-5 md:mx-0 md:col-span-2 md:col-start-1 md:col-end-2",
    fullwidthwithimageonright:
      "-mx-5 md:mx-0 md:col-span-2 md:col-start-2 md:col-end-3",
    imageonbottom: "order-2",
    imageontop: "order-1"
  };

  const textColClassNames: { [key: string]: string } = {
    containedwithimageonleft: "py-6 md:col-span-2 md:col-start-2 md:col-end-3",
    containedwithimageonright: "py-6 md:col-span-2 md:col-start-1 md:col-end-2",
    fullwidthwithimageonleft:
      "py-12 w-full md:col-span-2 md:col-start-2 md:col-end-3 md:max-w-[696px] md:justify-self-start md:px-5 lg:px-8 2xl:px-24",
    fullwidthwithimageonright:
      "py-12 w-full md:col-span-2 md:col-start-1 md:col-end-2 md:max-w-[696px] md:justify-self-end md:px-5 lg:px-8 2xl:px-24",
    imageonbottom: "order-1",
    imageontop: "order-2"
  };

  const headingClassName =
    headingClassNames[orientation ? orientation : defaultOrientation];
  const figureClassName =
    figureClassNames[orientation ? orientation : defaultOrientation];
  const imageClassName =
    imageClassNames[orientation ? orientation : defaultOrientation];
  const imageLayout =
    imageLayouts[orientation ? orientation : defaultOrientation];
  const colClassName =
    colClassNames[orientation ? orientation : defaultOrientation];
  const imageColClassName =
    imageColClassNames[orientation ? orientation : defaultOrientation];
  const textColClassName =
    textColClassNames[orientation ? orientation : defaultOrientation];
  const mobileImageColClassName = imageColClassNames[mobileOrientation];
  const mobileTextColClassName = textColClassNames[mobileOrientation];

  const renderItems = (items: Service[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          href={contentfulEntryUrl(item)}
          className="pr-button pr-button-sm pr-button-light-gray"
          key={index}
        >
          {item.previewTitle || item.title}
        </SmartLink>
      );
    });
  };

  return (
    <ProductContainer
      fullWidth={
        orientation == "fullwidthwithimageonleft" ||
        orientation == "fullwidthwithimageonright"
      }
    >
      <div
        className={`grid content-center md:grid-flow-col-dense md:grid-cols-2 ${colClassName}`}
      >
        {image && !video && (
          <div
            className={`col-span-full ${mobileImageColClassName} ${imageColClassName}`}
          >
            <figure className={`relative w-full ${figureClassName}`}>
              <ContentfulImage
                image={image}
                transforms={
                  orientation == "fullwidthwithimageonleft" ||
                  orientation == "fullwidthwithimageonright"
                    ? fullWidthImageTransforms
                    : containedImageTransforms
                }
                layout={imageLayout}
                className={imageClassName}
              />
            </figure>
          </div>
        )}
        {video && (
          <div
            className={`col-span-full flex items-center ${imageColClassName}`}
          >
            <figure className={`relative w-full`}>
              <div className="overflow-hidden rounded-2xl md:rounded-3xl">
                <PlyrVideo video={video} />
              </div>
            </figure>
          </div>
        )}
        <div
          className={`col-span-full flex flex-col justify-center ${mobileTextColClassName} ${textColClassName}`}
        >
          {primaryIcon && (
            <figure className="relative mx-auto h-10 w-10 lg:h-16 lg:w-16 xl:h-[90px] xl:w-[90px]">
              <ContentfulImage
                image={primaryIcon}
                layout="fill"
                className="object-contain"
              />
            </figure>
          )}

          {eyebrow && (
            <p className={`type-pr-theta md:type-pr-eta text-pr-dark-gray-400`}>
              {eyebrow}
            </p>
          )}

          <div
            className={`space-y-2 lg:space-y-4 ${
              eyebrow ? "mt-1.5 lg:mt-3" : ""
            } ${primaryIcon ? "mt-11 xl:mt-20" : ""}`}
          >
            <h2 className={headingClassName}>{heading}</h2>
            {text && (
              <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
                <RichText text={text} />
              </div>
            )}
          </div>

          {secondaryIcon && secondaryText && (
            <div className="mt-4 flex items-center gap-4 lg:mt-5">
              <figure className="relative h-16 w-16 shrink-0 lg:h-[88px] lg:w-28">
                <ContentfulImage
                  image={secondaryIcon}
                  layout="fill"
                  className="object-contain"
                />
              </figure>
              <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
                <RichText text={secondaryText} />
              </div>
            </div>
          )}

          {ctaUrl && ctaTitle && (
            <div className={`${ctaStyle == "primary" ? "mt-8" : "mt-1"}`}>
              {ctaStyle == "primary" ? (
                <SmartLink
                  href={ctaUrl}
                  className={"pr-button pr-button-lg pr-button-green"}
                  ctaId={ctaId}
                >
                  {ctaTitle}
                </SmartLink>
              ) : (
                <SmartLink
                  href={ctaUrl}
                  className="link-underline-animated-sm inline-flex items-center gap-3 text-pr-red-600"
                  ctaId={ctaId}
                >
                  <span className="text-pr-xl">{ctaTitle}</span>
                  <Arrow className="h-3 w-5 fill-current" />
                </SmartLink>
              )}
            </div>
          )}

          {items.length > 0 && (
            <div className="mt-4 space-y-2.5 lg:mt-[60px] lg:space-y-4">
              {servicesLabel && (
                <p className="text-center text-pr-xs uppercase md:text-left lg:text-pr-base">
                  {servicesLabel}
                </p>
              )}
              <div className=" flex flex-wrap gap-1">{renderItems(items)}</div>
            </div>
          )}
        </div>
      </div>
    </ProductContainer>
  );
}

export default TrendReportImageWithTextBlock;

import * as React from "react";
import { SmartLink } from "@src/components";

export type CardTopicsProps = {
  url: string;
  heading?: string | null;
  text?: string | null;
  meta?: string;
  borderTop?: boolean;
  borderBottom?: boolean;
  borderRight?: boolean;
};

export function CardTopics({
  url,
  heading,
  text,
  meta,
  borderTop = false,
  borderBottom = false,
  borderRight = false
}: CardTopicsProps) {
  const outerClasses = [];
  if (borderTop) {
    outerClasses.push("border-t md:border-t-0");
  }
  if (borderBottom) {
    outerClasses.push("md:border-b");
  }

  return (
    <div className={`flex border-gray-200 md:py-5 ${outerClasses.join(" ")}`}>
      <div
        className={`w-full space-y-2 py-5 md:space-y-3 md:px-2.5 lg:px-5 ${
          borderRight ? "md:border-r" : ""
        }`}
      >
        <SmartLink href={url} className="group block">
          <h3 className="type-delta link-underline-animated-sm text-red-500">
            {heading}
          </h3>
        </SmartLink>
        {text && <p className="text-gray-400">{text}</p>}
        {meta && <p className="type-zeta text-blue-200">{meta}</p>}
      </div>
    </div>
  );
}

export default CardTopics;

/* eslint-disable @next/next/no-img-element */
import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import { SmartLink, ContentfulImage, RichText } from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type CtaProps = {
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  text?: Document | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaExternalUrl?: string | null;
  theme?: string | null;
  headingStyle?: string | null;
  centeredEyebrow?: boolean;
  ctaId?: string | null;
};

export function Cta({
  media,
  eyebrow,
  heading,
  text,
  ctaTitle,
  ctaUrl,
  ctaExternalUrl,
  theme = "red",
  headingStyle = "large",
  centeredEyebrow = false,
  ctaId
}: CtaProps) {
  const dimensions = {
    width: media?.width || 228,
    height: media?.height || 346
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  const defaultTheme: string = "red";
  const defaultHeadingStyle: string = "large";

  const classNames: { [key: string]: string } = {
    red: "lg:pt-7 lg:pb-10 bg-red-500 text-white",
    black: "lg:pt-7 lg:pb-10 bg-black text-white",
    gray: "lg:py-10 bg-blue-100",
    redctainterstitialtext: "lg:pt-7 lg:pb-10 bg-red-500 text-white",
    blackctainterstitialtext: "lg:pt-7 lg:pb-10 bg-black text-white",
    grayctainterstitialtext: "lg:py-10 bg-blue-100 text-gray-400"
  };

  const proseClassNames: { [key: string]: string } = {
    red: `text-white prose-p:text-sm md:prose-p:text-base prose-a:text-white prose-strong:text-white ${
      heading || eyebrow ? "mt-2.5" : ""
    } ${heading && headingStyle == "large" ? "lg:mt-7" : "lg:mt-3.5"}`,
    black: `text-white prose-p:text-sm md:prose-p:text-base prose-a:text-white prose-strong:text-white ${
      heading || eyebrow ? "mt-2.5" : ""
    } ${heading && headingStyle == "large" ? "lg:mt-7" : "lg:mt-3.5"}`,
    gray: `prose-p:text-sm md:prose-p:text-base text-gray-400 ${
      heading || eyebrow ? "mt-2.5" : ""
    } ${heading && headingStyle == "large" ? "lg:mt-7" : "lg:mt-3.5"}`,
    redctainterstitialtext: `prose-p:text-base md:prose-p:text-xl text-white prose-a:text-white prose-strong:text-white ${
      heading || eyebrow ? "mt-2.5 lg:mt-3.5" : ""
    }`,
    blackctainterstitialtext: `prose-p:text-base md:prose-p:text-xl text-white prose-a:text-white prose-strong:text-white ${
      heading || eyebrow ? "mt-2.5 lg:mt-3.5" : ""
    }`,
    grayctainterstitialtext: `prose-p:text-base md:prose-p:text-xl text-lg text-gray-400 ${
      heading || eyebrow ? "mt-2.5 lg:mt-3.5" : ""
    }`
  };

  const linkClassNames: { [key: string]: string } = {
    red: "button-text-red",
    black: "button-text-black",
    gray: "button-text-red",
    redctainterstitialtext: "button-text-red",
    blackctainterstitialtext: "button-text-black",
    grayctainterstitialtext: "button-text-red"
  };

  const eyebrowClassNames: { [key: string]: string } = {
    red: "type-eta",
    black: "type-eta",
    gray: "type-eta",
    redctainterstitialtext: "uppercase tracking-wide",
    blackctainterstitialtext: "uppercase tracking-wide",
    grayctainterstitialtext: "uppercase tracking-wide"
  };

  const headingClassNames: { [key: string]: string } = {
    large: "type-beta",
    medium: "type-delta"
  };

  const className = classNames[theme ? theme : defaultTheme];
  const proseClassName = proseClassNames[theme ? theme : defaultTheme];
  const linkClassName = linkClassNames[theme ? theme : defaultTheme];
  const eyebrowClassName = eyebrowClassNames[theme ? theme : defaultTheme];
  const headingClassName =
    headingClassNames[headingStyle ? headingStyle : defaultHeadingStyle];

  // console.log("the theme: ", theme);
  // console.log("the eyebrow: ", eyebrow)
  // console.log("centered eyebrow? ", centeredEyebrow)

  let ctaUrlValue: string | null = null;
  if (ctaUrl) {
    ctaUrlValue = ctaUrl;
  } else if (ctaExternalUrl) {
    ctaUrlValue = ctaExternalUrl;
  }

  return (
    <div className={`p-5 lg:px-7 ${className}`}>
      <div className="flex gap-4 lg:gap-7">
        {media && (
          <div className="flex shrink-0 items-start">
            <figure className="w-[144px] lg:ml-4">
              <ContentfulImage image={media} transforms={transforms} />
            </figure>
          </div>
        )}

        <div className="grow">
          {eyebrow && (
            <p
              className={`${
                centeredEyebrow ? "text-center" : ""
              } ${eyebrowClassName}`}
            >
              {eyebrow}
            </p>
          )}
          {heading && (
            <h3
              className={`${eyebrow ? "mt-2.5" : ""} ${
                eyebrow && !centeredEyebrow ? "lg:mt-3.5" : ""
              } ${
                eyebrow && centeredEyebrow ? "lg:mt-7" : ""
              } ${headingClassName} `}
            >
              {heading}
            </h3>
          )}
          {text && (
            <div className={`prose max-w-none ${proseClassName}`}>
              <RichText text={text} />
            </div>
          )}
          {ctaUrlValue && ctaTitle && (
            <div
              className={`hidden justify-start sm:flex ${
                heading || eyebrow || text ? "mt-7" : ""
              }`}
            >
              <SmartLink
                href={ctaUrlValue}
                className={`${linkClassName}`}
                ctaId={ctaId}
              >
                {ctaTitle}
              </SmartLink>
            </div>
          )}
        </div>
      </div>

      {ctaUrlValue && ctaTitle && (
        <div
          className={`flex justify-center sm:hidden ${
            heading || eyebrow || text ? "mt-5" : ""
          }`}
        >
          <SmartLink
            href={ctaUrlValue}
            className={`w-full ${linkClassName}`}
            ctaId={ctaId}
          >
            {ctaTitle}
          </SmartLink>
        </div>
      )}
    </div>
  );
}

export default Cta;

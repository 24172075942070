import * as React from "react";
import type { ProductFooterQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { ProductContainer, SmartLink, ContentfulImage } from "@src/components";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms,
  filterNull
} from "@src/lib/utils";
import {
  ProductNavigationGroup,
  ProductNavigationLink,
  ProductNavigationGroupLinksCollectionItem,
  SocialMediaAccountsCollectionItem,
  UtilityLinksCollectionItem
} from "@src/lib/services/server/contentful";
import { IContentfulImage } from "@src/lib/types";
import TwitterIcon from "@/img/icons/twitter.svg";
import FacebookIcon from "@/img/icons/facebook.svg";
import LinkedinAltIcon from "@/img/icons/linkedinalt.svg";
import InstagramIcon from "@/img/icons/instagram.svg";

export type ProductFooterProps = {
  footer: ProductFooterQuery;
};

export function ProductFooter({ footer }: ProductFooterProps) {
  const footerItem = footer.productFooterCollection
    ? footer.productFooterCollection.items[0]
    : null;

  if (!footerItem) {
    return null;
  }

  // const iconDimensions = {
  //   width: 60,
  //   height: 60,
  // };

  // const iconTransforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: iconDimensions.width,
  //   height: iconDimensions.height,
  // };

  const navigationElements = filterNull(
    footerItem.navigationElementsCollection?.items
  ) as ProductNavigationGroup[] | ProductNavigationLink[] | undefined;
  const utilityLinks = filterNull(footerItem.utilityLinksCollection?.items) as
    | UtilityLinksCollectionItem[]
    | undefined;
  const socialMediaAccounts = filterNull(
    footerItem.socialMediaAccountsCollection?.items
  ) as SocialMediaAccountsCollectionItem[] | undefined;

  const socialMediaAccountsLabel =
    footerItem.socialMediaAccountsLabel as string;

  const renderNavigationElements = (
    items: ProductNavigationGroup[] | ProductNavigationLink[]
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationLink":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          const phonenumber: string | undefined = isPhoneNumber
            ? item.url?.replace(/[^\d]/g, "")
            : "";
          return (
            <SmartLink
              key={index}
              href={
                isPhoneNumber
                  ? `tel:+${phonenumber as string}`
                  : (item.url as string)
              }
              className="type-alpha space-y-5 p-4 text-pr-3xl md:space-y-8 md:p-0 md:px-4 lg:px-8"
            >
              {item.title}
            </SmartLink>
          );

        case "ProductNavigationGroup":
          const label = item.label;
          const linksCollection = item.linksCollection;
          const items = linksCollection?.items as
            | ProductNavigationGroupLinksCollectionItem[]
            | undefined;

          return (
            <div
              key={index}
              className="space-y-5 pt-4 md:space-y-7 md:px-4 lg:px-8"
            >
              {label && <p className="type-alpha text-pr-3xl">{label}</p>}
              <div className="flex flex-col items-start space-y-1.5 text-pr-dark-gray-400">
                {items && renderNavigationLinks(items)}
              </div>
            </div>
          );
      }
    });
  };

  const renderNavigationLinks = (
    items: ProductNavigationGroupLinksCollectionItem[]
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationLink":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          return (
            <SmartLink
              key={index}
              href={
                isPhoneNumber
                  ? `tel:+${item.url as string}`
                  : (item.url as string)
              }
              className="group"
            >
              <div className="type-pr-eta link-underline-animated-sm">
                {item.title}
              </div>
            </SmartLink>
          );
        case "Pillar":
          const icon = item.icon as IContentfulImage;
          return (
            <SmartLink
              key={index}
              href={contentfulEntryUrl(item)}
              className="group flex items-center gap-2.5 pb-1 md:pb-3"
            >
              <figure className="relative h-7 w-7 shrink-0 self-start">
                <ContentfulImage
                  image={icon}
                  layout="fill"
                  className="object-contain"
                />
              </figure>
              <div>
                <span className="link-underline-animated-sm text-pr-xl font-bold text-pr-dark-gray-400">
                  {item.title}
                </span>
              </div>
            </SmartLink>
          );
        default:
          return (
            <SmartLink
              key={index}
              href={contentfulEntryUrl(item)}
              className="group"
            >
              <div className="type-pr-eta link-underline-animated-sm">
                {item.title}
              </div>
            </SmartLink>
          );
      }
    });
  };

  const renderUtilityLinks = (items: UtilityLinksCollectionItem[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          key={index}
          href={contentfulEntryUrl(item)}
          className="type-pr-theta link-underline-animated-sm"
        >
          {item.title}
        </SmartLink>
      );
    });
  };

  const renderSocialMediaAccounts = (
    items: SocialMediaAccountsCollectionItem[]
  ) => {
    return items.map((item, index) => {
      switch (item.title?.toLowerCase()) {
        case "instagram":
          return (
            <SmartLink
              key={index}
              href={item.url as string}
              ariaLabel="instagram link"
            >
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white text-black transition duration-200 hover:bg-pr-dark-gray-500 hover:text-white focus:bg-pr-dark-gray-500 focus:text-white">
                <InstagramIcon className="h-6 w-6 fill-current" />
              </div>
            </SmartLink>
          );
        case "facebook":
          return (
            <SmartLink
              key={index}
              href={item.url as string}
              ariaLabel="facebook link"
            >
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white text-black transition duration-200 hover:bg-pr-dark-gray-500 hover:text-white focus:bg-pr-dark-gray-500 focus:text-white">
                <FacebookIcon className="h-6 w-6 fill-current" />
              </div>
            </SmartLink>
          );
        case "twitter":
          return (
            <SmartLink
              key={index}
              href={item.url as string}
              ariaLabel="twitter link"
            >
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white text-black transition duration-200 hover:bg-pr-dark-gray-500 hover:text-white focus:bg-pr-dark-gray-500 focus:text-white">
                <TwitterIcon className="h-6 w-6 fill-current" />
              </div>
            </SmartLink>
          );
        case "linkedin":
          return (
            <SmartLink
              key={index}
              href={item.url as string}
              ariaLabel="linkedin link"
            >
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white text-black transition duration-200 hover:bg-pr-dark-gray-500 hover:text-white focus:bg-pr-dark-gray-500 focus:text-white">
                <LinkedinAltIcon className="h-6 w-6 fill-current" />
              </div>
            </SmartLink>
          );
      }
    });
  };

  return (
    <>
      <div className="bg-pr-yellow-200">
        <ProductContainer>
          <div className="py-8 md:-mx-4 md:py-14 lg:-mx-8">
            <div className="grid gap-4 divide-y md:grid-cols-3 md:gap-x-0 md:gap-y-7 md:divide-y-0 xl:grid-cols-5 xl:divide-x">
              {navigationElements &&
                renderNavigationElements(navigationElements)}
              {(footerItem.trustBadge ||
                (socialMediaAccountsLabel && socialMediaAccounts)) && (
                <div className="flex h-full flex-col items-start space-y-4 md:space-y-5  md:pl-4 lg:space-y-7 lg:pl-8">
                  {socialMediaAccountsLabel && socialMediaAccounts && (
                    <div className="space-y-5 pt-4 md:space-y-7">
                      <p className="type-alpha text-pr-3xl">
                        {socialMediaAccountsLabel}
                      </p>
                      <div className="flex flex-wrap gap-3.5">
                        {renderSocialMediaAccounts(socialMediaAccounts)}
                      </div>
                    </div>
                  )}
                  {footerItem.trustBadge && (
                    <div>
                      <img
                        src={footerItem.trustBadge.url || undefined}
                        alt={footerItem.trustBadge.description || undefined}
                        className="h-[80%] w-[80%]"
                      ></img>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </ProductContainer>
      </div>
      {utilityLinks && utilityLinks.length > 0 && (
        <div className="bg-white py-8 md:py-11">
          <ProductContainer>
            <div className="flex gap-5 text-pr-dark-gray-400">
              {renderUtilityLinks(utilityLinks)}
            </div>
          </ProductContainer>
        </div>
      )}
    </>
  );
}

export default ProductFooter;

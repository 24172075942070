import * as React from "react";
import { Pagination } from "swiper";
import { filterNull } from "@src/lib/utils";
import {
  PricingContentModulesTabbedColumns,
  PricingCMTabbedColumnsCollectionItem
} from "@src/lib/services/server/contentful";
import { ProductContainer } from "@src/components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css";

export type PricingTabbedColumnsProps = {
  content: PricingContentModulesTabbedColumns;
};

const PricingTabbedColumns = ({ content }: PricingTabbedColumnsProps) => {
  const flattenColumnsWithTabTitle = (
    content: PricingTabbedColumnsProps["content"]
  ) => {
    const tabs = filterNull(content.tabsCollection?.items);

    return tabs.flatMap(({ title: tabTitle, columnsCollection }) => {
      return filterNull(columnsCollection?.items).map(column => ({
        ...column,
        tabTitle: tabTitle ?? ""
      }));
    });
  };

  const columnsWithTabTitle = flattenColumnsWithTabTitle(content);

  const groupedByTabTitle = columnsWithTabTitle.reduce((acc, column) => {
    const { tabTitle } = column;
    if (!(tabTitle in acc)) acc[tabTitle] = [];
    acc[tabTitle].push(column);
    return acc;
  }, {} as Record<string, PricingCMTabbedColumnsCollectionItem[]>);

  const uniqueTabTitles = Object.keys(groupedByTabTitle);

  const renderButtons = (index: number, className: string) => {
    return `<span class="${className}">${uniqueTabTitles[index]}</span>`;
  };

  const renderItems = (filterTabTitle?: string) => {
    return Object.entries(groupedByTabTitle).map(([tabTitle, columns]) => {
      if (filterTabTitle && tabTitle !== filterTabTitle) return null;

      return (
        <SwiperSlide key={tabTitle}>
          <div className="flex flex-col justify-center gap-3 text-center lg:flex-row">
            {columns.map(
              ({ title, subTitle, description, ctaTitle, ctaUrl, list }) => (
                <div
                  key={title}
                  className="mx-auto flex max-w-screen-xs basis-full flex-col gap-6 rounded-br-xl rounded-tl-xl bg-pr-yellow-200 p-6 outline outline-1 -outline-offset-1 outline-pr-dark-gray-300 lg:mx-0"
                >
                  <h3 className="text-3xl font-bold">{title}</h3>
                  <div>
                    {subTitle && (
                      <p className="font-bold uppercase text-ada-salmon-500">
                        {subTitle}
                      </p>
                    )}
                    <p className="text-balance">{description}</p>
                  </div>
                  <a
                    href={ctaUrl ?? ""}
                    className="mx-auto w-fit rounded-full border border-ada-salmon-500 px-5 py-2 text-ada-salmon-500 transition delay-75 hover:bg-ada-salmon-500 hover:text-pr-yellow-200"
                  >
                    {ctaTitle}
                  </a>
                  <ul className="leading-loose">
                    {list?.split("\n").map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              )
            )}
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="w-full bg-ada-salmon-500 pb-6 pt-px">
      <ProductContainer>
        <div className="navigation my-6 flex flex-wrap justify-center gap-3 lg:gap-6"></div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          autoHeight={true}
          pagination={{
            clickable: true,
            el: ".navigation",
            bulletClass: "swiper-pagination-bullet-salmon-outline",
            bulletActiveClass:
              "swiper-pagination-bullet-salmon-outline--active",
            renderBullet: (index, className) => renderButtons(index, className)
          }}
        >
          {renderItems()}
        </Swiper>
      </ProductContainer>
    </div>
  );
};

export { PricingTabbedColumns };

import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { SmartLink, ContentfulImage } from "@src/components";
import Quotes from "@/img/icons/quotes.svg";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type BlockQuoteProps = {
  text?: string | null;
  attribution: {
    media?: IContentfulImage | null;
    heading?: string | null;
    text?: string | null;
    ctaTitle?: string | null;
    ctaUrl?: string | null;
  };
};

export function BlockQuote({ text, attribution }: BlockQuoteProps) {
  const dimensions = {
    width: 280,
    height: 280
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div className="my-8 md:my-16">
      <div className="lg:relative lg:px-16 xl:-mx-10">
        <div className="flex h-[33px] w-[33px] items-center justify-center rounded-full bg-red-500 text-white lg:absolute lg:left-0 lg:top-0 lg:h-[46px] lg:w-[46px]">
          <Quotes className="h-3.5 w-3.5 fill-current lg:h-5 lg:w-5" />
        </div>

        <figure className="mt-4 pt-1 lg:mt-0">
          <blockquote className="type-beta text-gray-600">{text}</blockquote>
          <figcaption className="mt-4 flex gap-4 lg:mt-6">
            {attribution.media && (
              <div className="shrink-0">
                <figure className="aspect-square h-20 w-20 overflow-hidden rounded-full lg:h-[140px] lg:w-[140px]">
                  <ContentfulImage
                    image={attribution.media}
                    transforms={transforms}
                  />
                </figure>
              </div>
            )}

            <div className="flex flex-col justify-center text-sm text-blue-200">
              {attribution.heading && (
                <p className="md:type-epsilon">{attribution.heading}</p>
              )}
              {attribution.text && (
                <p className="md:text-lg">{attribution.text}</p>
              )}
              {attribution.ctaUrl && attribution.ctaTitle && (
                <div className="mt-2">
                  <SmartLink
                    href={attribution.ctaUrl}
                    className="md:type-epsilon link-underline-animated-sm text-red-500"
                  >
                    {attribution.ctaTitle}
                  </SmartLink>
                </div>
              )}
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  );
}

export default BlockQuote;

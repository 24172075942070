import * as React from "react";
import { HolidayCMImageWithText } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import { RichTextBlock } from "@src/components/RichTextBlock";

export type HolidayImageWithTextProps = {
  content: HolidayCMImageWithText;
};

const HolidayImageWithText = ({ content }: HolidayImageWithTextProps) => {
  const { image, text } = content;
  const richText = text?.json as Document;

  return (
    <div id={`image-with-text-${content.sys.id}`} className="mt-12">
      {image && (
        <img
          src={image.url ?? ""}
          alt={image.title ?? ""}
          className="mx-auto md:max-w-2xl"
        />
      )}
      {text && <RichTextBlock key={content.sys.id} text={richText} />}
    </div>
  );
};

export { HolidayImageWithText };

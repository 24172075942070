import {
  BlogPost,
  CtaLimitedType,
  CtaBlogSubscriptionType,
  CtaDemoRequestType,
  CtaFormType,
  GlobalProps
} from "@src/lib/services/server/contentful";
import { contentfulEntryUrl, filterNull } from "@src/lib/utils";
import type {
  BlogTopicQuery,
  BlogNavigationQuery,
  ProductNavigationQuery,
  ProductFooterQuery
} from "@src/lib/services/server/contentful/generated/graphqlSdk";
import Error from "next/error";
import "setimmediate";
import {
  ListingHeading,
  Grid,
  List,
  BlogLayout,
  Pagination
} from "@src/components";

export type BlogTopicPageProps = GlobalProps & {
  entry?: BlogTopicQuery;
  blogNavigation?: BlogNavigationQuery;
  pageNumber?: number;
};

export function BlogTopicPage({
  entry,
  pageNumber = 1,
  ...layoutProps
}: BlogTopicPageProps) {
  if (!entry) {
    return <Error statusCode={500} />;
  }

  const entryItem = entry.topicCollection
    ? entry.topicCollection.items[0]
    : null;

  if (!entryItem) {
    return null;
  }

  const featuredBlogPosts = filterNull(
    entryItem.featuredBlogPostsCollection?.items
  );
  const blogPosts = filterNull(entryItem.linkedFrom?.blogPostCollection?.items);
  const total = entryItem.linkedFrom?.blogPostCollection?.total as number;
  const limit = 5;
  const skip = (pageNumber - 1) * limit;

  const cta = entryItem.cta as
    | CtaLimitedType
    | CtaFormType
    | CtaBlogSubscriptionType
    | CtaDemoRequestType;

  return (
    <BlogLayout entry={entryItem} pageNumber={pageNumber} {...layoutProps}>
      <ListingHeading heading={entryItem.title} text={entryItem.description} />

      {featuredBlogPosts.length > 0 && (
        <Grid items={featuredBlogPosts} displayEyebrow={entryItem.title} />
      )}

      {blogPosts.length > 0 && (
        <List
          sectionHeading={entryItem.title ? `All ${entryItem.title}` : null}
          items={blogPosts}
          cta={cta}
          displayEyebrow={entryItem.title}
        />
      )}

      {total && total > 5 && (
        <Pagination
          url={contentfulEntryUrl(entryItem)}
          total={total}
          limit={limit}
          skip={skip}
        />
      )}
    </BlogLayout>
  );
}

export default BlogTopicPage;

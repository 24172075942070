import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText, ContentfulImage } from "@src/components";

export type ProductTableBlockProps = {
  heading?: string | null;
  text?: Document;
  backgroundStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductTableBlock({
  heading,
  text,
  backgroundStyle
}: ProductTableBlockProps) {
  return (
    <div className="my-10 md:my-16 lg:my-24">
      {backgroundStyle == "motif" && (
        <div className="relative">
          <svg
            viewBox="0 0 1650 567"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute inset-x-0 inset-y-28"
          >
            <path
              d="M1590 121C1624.23 121 1652 149.215 1652 184C1652 218.785 1624.23 247 1590 247C1555.77 247 1528 218.785 1528 184C1528 149.215 1555.77 121 1590 121Z"
              className="fill-[#FEEAE0]"
            />
            <path
              d="M62 1C27.7674 1 0 28.7676 0 63.0001C0 97.2327 27.7674 125 62 125V1Z"
              className="fill-[#CFE8E4]"
            />
            <path
              d="M125 0C90.2154 0 62 27.7674 62 62C62 96.2326 90.2154 124 125 124V0Z"
              className="fill-[#CFE8E4]"
            />
          </svg>
        </div>
      )}
      <ProductContainer>
        <div className="relative mx-auto max-w-[1040px] space-y-4 rounded-3xl bg-white p-12 shadow-2xl md:space-y-5 md:p-14">
          {heading && (
            <h2 className="type-pr-delta border-b-[1px] border-gray-200 pb-6 md:pb-7 lg:text-pr-7xl">
              {returnLineBreaks(heading)}
            </h2>
          )}
          {text && (
            <div>
              <div className="card-details card-details-columns">
                <RichText text={text} />
              </div>
            </div>
          )}
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductTableBlock;

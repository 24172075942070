import * as React from "react";
import type { HolidayCMHeadingWithIcon } from "@src/lib/services/server/contentful";
import Image from "next/image";

export type HolidayHeadingWithIconProps = {
  content: HolidayCMHeadingWithIcon;
};

const HolidayHeadingWithIcon = ({ content }: HolidayHeadingWithIconProps) => {
  const { icon, eyebrow, heading, description } = content;

  return (
    <>
      {icon && (
        <div
          id={`heading-with-icon-${content.sys.id}`}
          className="mt-4 flex flex-col gap-y-4 text-center md:mt-8 md:text-left"
        >
          <div className="flex flex-col gap-4 md:flex-row">
            <Image
              src={icon.url ?? ""}
              alt={icon.title ?? ""}
              width={100}
              height={100}
              priority={true}
              className="object-contain md:object-left"
            />
            <div className="flex flex-col justify-center gap-2">
              <span className="text-xl uppercase text-pr-dark-gray-400">
                {eyebrow}
              </span>
              <h2 className="text-2xl font-bold text-pr-dark-gray-600 md:text-4xl">
                {heading}
              </h2>
            </div>
          </div>
          <p className="text-xl text-pr-dark-gray-500">{description}</p>
        </div>
      )}
      {!icon && (
        <div
          id={`heading-with-icon-${content.sys.id}`}
          className="mt-4 flex flex-col gap-y-4 px-12 text-center md:mt-8"
        >
          <span className="text-xl uppercase text-pr-dark-gray-400">
            {eyebrow}
          </span>
          <h2 className="text-3xl font-bold capitalize text-ada-salmon-500 md:text-5xl">
            {heading}
          </h2>
          <p className="text-xl text-pr-dark-gray-500">{description}</p>
        </div>
      )}
    </>
  );
};

export { HolidayHeadingWithIcon };

import * as React from "react";

export type HolidayCtaProps = {
  children: React.ReactNode;
};

export function HolidayCta({ children }: HolidayCtaProps) {
  return (
    <div className="md:pr-20px-5 mx-auto my-10 flex w-full max-w-screen-4xl flex-col gap-6 pr-12 md:my-16 lg:px-8 2xl:px-24">
      {children}
    </div>
  );
}

export default HolidayCta;

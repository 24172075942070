import {
  Document,
  Inline,
  Block,
  Text,
  INLINES
} from "@contentful/rich-text-types";

function isNodeWithContent(
  node?: Document | Inline | Block | Text | null
): node is Document | Inline | Block {
  return node?.hasOwnProperty("content") || false;
}

export function contentfulRichTextLinks(
  node?: Document | Inline | Block | Text | null
): string[] {
  const links = [];

  if (node?.nodeType === INLINES.HYPERLINK) {
    links.push(node.data.uri as string);
  }

  if (isNodeWithContent(node)) {
    for (const childNode of node.content) {
      links.push(...contentfulRichTextLinks(childNode));
    }
  }

  return links;
}

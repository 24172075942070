import { useEffect, useState } from "react";
import type {
  ProductFooterQuery,
  ProductNavigation2Query,
  ProductNavigation3Query,
  ProductNavigationQuery
} from "@src/lib/services/server/contentful/generated/graphqlSdk";
import {
  Layout,
  ProductNavigation,
  ProductFooter,
  LayoutProps
} from "@src/components";

export type ProductLayoutProps = LayoutProps & {
  footer?: ProductFooterQuery;
  navigation?: ProductNavigationQuery;
  navigation2?: ProductNavigation2Query;
  navigation3?: ProductNavigation3Query;
  navigationStyle?: string | null;
};

export function ProductLayout({
  footer,
  navigation,
  navigation2,
  navigation3,
  navigationStyle = "yellow",
  children,
  ...layoutProps
}: ProductLayoutProps) {
  const [pageType, setPageType] = useState("Product");

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.className = "";
      html.classList.add("product-layout");
    }

    if (window.location.pathname === "/") {
      setPageType("Homepage");
    }
  });

  return (
    <Layout {...layoutProps}>
      <div className="flex min-h-screen flex-col">
        {navigation && (
          <ProductNavigation
            navigation={navigation}
            navigation2={navigation2}
            navigation3={navigation3}
            navigationStyle={navigationStyle}
            pageType={pageType}
          />
        )}
        <main className="grow">{children}</main>
        {footer && <ProductFooter footer={footer} />}
      </div>
    </Layout>
  );
}

export default ProductLayout;

export const keyMapping: { [key: string]: string } = {
  utm_source: "UTM_Source",
  utm_content: "UTM_Content",
  utm_campaign: "UTM_Campaign",
  utm_medium: "UTM_Medium",
  utm_term: "UTM_Term",
  adset_name: "FB_Ad_Set_ID",
  ad_name: "FB_Ad_ID",
  fbclid: "FB_Click_ID",
  gclid: "Google_Click_ID",
  gadgroupid: "Adwords_Adgroup_Id",
  gcampaignid: "Adwords_Campaign_Id",
  gkeywords: "Adwords_Keyword",
  gtargetid: "Adwords_Target_Id",
  landing_page: "Landing_Page",
  referrer_url: "Referrer_URL",
  ref: "Referrer_by",
  _ga: "ga_client_id"
};

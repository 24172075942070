/* eslint-disable tailwindcss/no-contradicting-classname */
/* eslint-disable tailwindcss/classnames-order */
import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import {
  ContentfulImage,
  ProductContainer,
  RichText,
  SmartLink
} from "@src/components";

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { TrendReportOneColumnText } from "../TrendReportOneColumnText";
import { PlyrVideo } from "../PlyrVideo";

// Define types
export type TrendReportTabbedContentProps = {
  id?: string | null;
  heading?: string | null;
  eyebrow?: string | null;
  html?: string | null;
  ctaStyle?: string | null;
  items?: TrendReportTabbedContentImageWithTextProps[];
};

export type TrendReportTabbedContentImageWithTextProps = {
  cta?: string | null;
  slide?: {
    video?: string | null;
    eyebrow?: string | null;
    heading?: string | null;
    image?: IContentfulImage | null;
    imagePosition?: string | null;
    imageMobilePosition?: string | null;
    text?:
      | {
          json?: Document;
        }
      | null
      | undefined;
    ctaTitle?: string | null; // no longer exists
    ctaUrl?: string | null; // no longer exists
  } | null;
};

// Refactored ImageWithText component
function ImageWithText({
  slide
}: Readonly<TrendReportTabbedContentImageWithTextProps>) {
  const orientation =
    slide?.imagePosition === "Left" ? "imageonleft" : "imageonright";
  const mobileOrientation =
    slide?.imageMobilePosition === "Bottom" ? "imageonbottom" : "imageontop";

  const fullWidthImageTransforms = {
    width: slide?.image?.width || 1648,
    height: slide?.image?.height || 1470
  };

  const imageColClassNames = {
    imageonleft: "order-1 md:mx-0 md:col-span-2 md:col-start-1 md:col-end-2",
    imageonright: "order-2 md:mx-0 md:col-span-2 md:col-start-2 md:col-end-3"
  };

  const textColClassNames = {
    imageonleft:
      "py-12 w-full md:col-span-2 md:col-start-2 md:col-end-3 md:max-w-[696px]",
    imageonright:
      "py-12 w-full md:col-span-2 md:col-start-1 md:col-end-2 md:max-w-[696px]",
    imageonbottom: "order-1",
    imageontop: "order-2"
  };

  const imageColClassName = imageColClassNames[orientation];
  const textColClassName = textColClassNames[orientation];
  const mobileTextColClassName = textColClassNames[mobileOrientation];

  return slide?.video ? (
    <div className="flex flex-col content-center items-center gap-6">
      <ProductContainer>
        <div className="contained mx-auto space-y-6 lg:space-y-8">
          <figure className="space-y-1 md:space-y-4">
            <div className="overflow-hidden rounded-2xl md:rounded-3xl">
              <PlyrVideo video={slide.video} />
            </div>
          </figure>
        </div>
      </ProductContainer>
      {slide.eyebrow && (
        <p className="type-pr-theta md:type-pr-eta text-pr-dark-gray-400">
          {slide.eyebrow}
        </p>
      )}
      <div
        className={`space-y-2 lg:space-y-4 ${
          slide.eyebrow ? "mt-1.5 lg:mt-3" : ""
        }`}
      >
        <h2 className="type-pr-delta lg:text-[2.75rem]">{slide.heading}</h2>
        {slide.text && (
          <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
            <RichText text={slide.text.json as Document} />
          </div>
        )}
        {slide.ctaTitle && (
          <SmartLink
            href={slide.ctaUrl ?? ""}
            className="pr-button pr-button-lg ease bg-ada-salmon-500 !bg-none text-white hover:bg-ada-green-400 hover:text-black lg:mx-0"
          >
            {slide.ctaTitle}
          </SmartLink>
        )}
      </div>
    </div>
  ) : (
    <div className="grid content-center items-center md:grid-flow-col-dense md:grid-cols-2">
      {slide?.image && (
        <div className={`col-span-full ${imageColClassName}`}>
          <figure className="relative w-full">
            <ContentfulImage
              image={slide.image}
              transforms={fullWidthImageTransforms}
            />
          </figure>
        </div>
      )}
      <div
        className={`col-span-full flex flex-col justify-center ${mobileTextColClassName} ${textColClassName}`}
      >
        {slide?.eyebrow && (
          <p className="type-pr-theta md:type-pr-eta text-pr-dark-gray-400">
            {slide.eyebrow}
          </p>
        )}
        <div
          className={`space-y-2 lg:space-y-4 ${
            slide?.eyebrow ? "mt-1.5 lg:mt-3" : ""
          }`}
        >
          <h2 className="type-pr-delta lg:text-[2.75rem]">{slide?.heading}</h2>
          {slide?.text && (
            <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
              <RichText text={slide.text.json as Document} />
            </div>
          )}
          {slide?.ctaTitle && (
            <SmartLink
              href={slide.ctaUrl ?? ""}
              className="pr-button pr-button-lg ease bg-ada-salmon-500 !bg-none text-white hover:bg-ada-green-400 hover:text-black lg:mx-0"
            >
              {slide.ctaTitle}
            </SmartLink>
          )}
        </div>
      </div>
    </div>
  );
}

// TrendReportTabbedContent component
export function TrendReportTabbedContent({
  id = "",
  heading,
  eyebrow,
  html,
  ctaStyle,
  items
}: Readonly<TrendReportTabbedContentProps>) {
  const paginationClassName = id ? `pagination-${id}` : "pagination";

  const defaultCtaStyle = "teal";
  const ctaClassNames: { [key: string]: string } = {
    red: "swiper-pagination-bullet-trend-red",
    green: "swiper-pagination-bullet-trend-green",
    yellow: "swiper-pagination-bullet-trend-yellow",
    teal: "swiper-pagination-bullet-trend-teal"
  };

  const ctaActiveClassNames: { [key: string]: string } = {
    red: "swiper-pagination-bullet-active-trend-red",
    green: "swiper-pagination-bullet-active-trend-green",
    yellow: "swiper-pagination-bullet-active-trend-yellow",
    teal: "swiper-pagination-bullet-active-trend-teal"
  };

  const ctaClassName = ctaClassNames[ctaStyle || defaultCtaStyle];
  const ctaActiveClassName = ctaActiveClassNames[ctaStyle || defaultCtaStyle];

  return (
    <ProductContainer>
      <div className="w-full">
        <TrendReportOneColumnText
          heading={heading}
          eyebrow={eyebrow}
          html={html}
        />
        <div
          className={`mt-8 flex flex-row flex-wrap items-center justify-center gap-4 pb-5 md:flex-row lg:gap-7 lg:pb-8 ${paginationClassName}`}
        />
        {items && (
          <Swiper
            pagination={{
              el: `.${paginationClassName}`,
              clickable: true,
              bulletActiveClass: ctaActiveClassName,
              bulletClass: ctaClassName,
              renderBullet: function (index, className) {
                return `<span class="${className}"> ${
                  items[index]?.cta as string
                } </span>`;
              }
            }}
            modules={[Pagination]}
            autoHeight={true}
            className="mySwiper"
          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <ImageWithText slide={item.slide} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </ProductContainer>
  );
}

export default TrendReportTabbedContent;

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import * as React from "react";
import {
  ProductContentModulesImageWithText,
  ContentModule,
  TrendReportContentModulesOneColumnText,
  TrendReportContentModulesColumnCards,
  TrendReportContentModulesTabbedContent,
  TrendReportContentModulesTwoColumnImageWithText,
  TrendReportContentModulesSpacer,
  ProductContentModulesImage
} from "@src/lib/services/server/contentful";

import {
  ProductImageWithTextBlock,
  TrendReportOneColumnText,
  TrendReportColumnCards,
  TrendReportTwoColumnImageWithText,
  TrendReportTabbedContent,
  ProductImageBlock
} from "@src/components";
import { Document } from "@contentful/rich-text-types";
import { filterNull } from "@src/lib/utils";
import { FadeInContainer } from "../FadeInContainer";
import TrendReportImageWithTextBlock from "../TrendReportImageWithTextBlock/TrendReportImageWithTextBlock";
import { TrendReportImageBlock } from "../TrendReportImageBlock";

export type TrendReportContentModulesProps = {
  contentModules: ContentModule[];
  hasBackground: boolean | null;
};

const renderOneColumnText = (
  contentModule: TrendReportContentModulesOneColumnText
) => {
  return (
    <FadeInContainer key={contentModule.sys.id}>
      <TrendReportOneColumnText
        heading={contentModule.heading}
        headingStyle={contentModule.headingStyle
          ?.toLowerCase()
          .split(" ")
          .join("")}
        headingTag={contentModule.headingTag?.toLowerCase().split(" ").join("")}
        eyebrow={contentModule.eyebrow}
        html={contentModule.html}
      />
    </FadeInContainer>
  );
};

const renderColumnCards = (
  contentModule: TrendReportContentModulesColumnCards
) => {
  const items = filterNull(contentModule.cardsCollection?.items);
  return (
    <FadeInContainer key={contentModule.sys.id}>
      <TrendReportColumnCards items={items} />
    </FadeInContainer>
  );
};

const renderTwoColumnImageWithText = (
  contentModule: TrendReportContentModulesTwoColumnImageWithText,
  hasBackground: boolean | null
) => {
  const items = filterNull(contentModule.imageWithTextCollection?.items);
  return (
    <FadeInContainer key={contentModule.sys.id}>
      <TrendReportTwoColumnImageWithText
        textPosition={contentModule.textPosition}
        items={items}
        hasBackground={hasBackground}
      />
    </FadeInContainer>
  );
};

const renderTabbedContent = (
  contentModule: TrendReportContentModulesTabbedContent
) => {
  const items = filterNull(contentModule.slidesCollection?.items);
  return (
    <FadeInContainer key={contentModule.sys.id}>
      <TrendReportTabbedContent
        id={contentModule.sys.id}
        heading={contentModule.heading}
        eyebrow={contentModule.eyebrow}
        html={contentModule.html}
        ctaStyle={contentModule.ctaStyle?.toLowerCase().split(" ").join("")}
        items={items}
      />
    </FadeInContainer>
  );
};

const renderImageWithText = (
  contentModule: ProductContentModulesImageWithText
) => {
  const image = contentModule.image;
  const primaryIcon = contentModule.primaryIcon;
  const secondaryIcon = contentModule.secondaryIcon;
  const items = filterNull(contentModule.servicesCollection?.items);

  return (
    <FadeInContainer key={contentModule.sys.id}>
      <TrendReportImageWithTextBlock
        eyebrow={contentModule.eyebrow}
        heading={contentModule.heading}
        text={contentModule.text?.json as Document | undefined}
        image={image}
        video={contentModule.video}
        primaryIcon={primaryIcon}
        secondaryIcon={secondaryIcon}
        secondaryText={
          contentModule.secondaryText?.json as Document | undefined
        }
        ctaTitle={contentModule.ctaTitle}
        ctaUrl={contentModule.ctaUrl}
        ctaStyle={contentModule.ctaStyle?.toLowerCase().split(" ").join("")}
        ctaId={contentModule.ctaId}
        orientation={contentModule.orientation
          ?.toLowerCase()
          .split(" ")
          .join("")}
        imageMobilePosition={contentModule.imageMobilePosition}
        servicesLabel={contentModule.servicesLabel}
        items={items}
      />
    </FadeInContainer>
  );
};

const renderImage = (contentModule: ProductContentModulesImage) => {
  const image = contentModule.image;

  return (
    <FadeInContainer key={contentModule.sys.id}>
      <TrendReportImageBlock
        image={image}
        caption={contentModule.caption?.json as Document | undefined}
        orientation={contentModule.orientation
          ?.toLowerCase()
          .split(" ")
          .join("")}
      />
    </FadeInContainer>
  );
};

function renderSpacer(
  contentModule: TrendReportContentModulesSpacer
): React.ReactNode {
  const spacer = contentModule.width ? `${contentModule.width}px` : "100px";
  return (
    <div
      key={`spacer-${contentModule.sys.id}`}
      className={`w-full`}
      style={{ height: spacer }}
    ></div>
  );
}

function renderWrapper(
  contentModule: ContentModule,
  next: ContentModule,
  index: number,
  hasBackground: boolean | null
): React.ReactNode {
  // Set conditional if next content module is a spacer
  const number =
    next != undefined &&
    (next.__typename == "TrendReportSpacer" ||
      contentModule.__typename == "TrendReportSpacer")
      ? 0
      : 100;
  const spacer = `${number}px`;
  const spacerMobile = `${number / 2}px`;
  return [
    renderContentModule(contentModule, hasBackground),
    <div
      key={`wrapper-${contentModule.__typename}-${index}`}
      className={`w-full`}
    >
      <div className="hidden md:block" style={{ height: spacer }}></div>
      <div className="md:hidden" style={{ height: spacerMobile }}></div>
    </div>
  ];
}

function renderContentModule(
  contentModule: ContentModule,
  hasBackground: boolean | null
): React.ReactNode {
  switch (contentModule.__typename) {
    case "TrendReport1ColumnText":
      return renderOneColumnText(contentModule);
    case "TrendReportColumnCards":
      return renderColumnCards(contentModule);
    case "TrendReport2ColumnImageWithText":
      return renderTwoColumnImageWithText(contentModule, hasBackground);
    case "TrendReportTabbedContent":
      return renderTabbedContent(contentModule);
    case "ProductCmImageWithText":
      return renderImageWithText(contentModule);
    case "TrendReportSpacer":
      return renderSpacer(contentModule);
    case "ProductCmImage":
      return renderImage(contentModule);
  }
}

export function TrendReportContentModules({
  contentModules,
  hasBackground
}: TrendReportContentModulesProps) {
  return (
    <div>
      {contentModules.map((contentModule, index) =>
        renderWrapper(
          contentModule,
          contentModules[index + 1],
          index,
          hasBackground
        )
      )}
    </div>
  );
}

export default TrendReportContentModules;

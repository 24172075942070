import * as React from "react";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImageCarouselGroup } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { ProductContainer, RichText, ContentfulImage } from "@src/components";
import Arrow from "@/img/icons/arrow.svg";
import "swiper/css";
import "swiper/css/pagination";

export type ProductImageCarouselBlockProps = {
  id?: string | null;
  items: ImageCarouselGroup[];
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductImageCarouselBlock({
  id = "",
  items
}: ProductImageCarouselBlockProps) {
  const paginationClassName: string = `pagination-${id as string}`;
  const prevClassName: string = `prev-${id as string}`;
  const nextClassName: string = `next-${id as string}`;

  const dimensions = {
    width: 1602,
    height: 990
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  const renderItems = (items: ImageCarouselGroup[]) => {
    return items.map((item, index) => {
      const image = item.image;
      const caption = item.caption?.json as Document | undefined;
      if (!image) {
        return null;
      }

      return (
        <SwiperSlide key={index}>
          <div className="space-y-6 lg:space-y-8">
            <figure className="space-y-1 md:space-y-4">
              <ContentfulImage
                image={image}
                transforms={transforms}
                className="rounded-2xl md:rounded-3xl"
                priority={true}
              />
              {caption && caption.content.length > 0 && (
                <figcaption className="type-pr-eta text-pr-dark-gray-400">
                  <RichText text={caption} />
                </figcaption>
              )}
            </figure>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="my-10 md:my-16 lg:my-24">
      <ProductContainer>
        <div className="mx-auto max-w-[800px]">
          <div className="relative">
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              spaceBetween={16}
              slidesPerView={1}
              navigation={{
                prevEl: `.${prevClassName}`,
                nextEl: `.${nextClassName}`
              }}
              pagination={{
                clickable: true,
                bulletActiveClass: "swiper-pagination-bullet-active-product",
                bulletClass: "swiper-pagination-bullet-product",
                el: `.${paginationClassName}`
              }}
            >
              {renderItems(items)}
            </Swiper>

            <div
              className={`mt-8 flex flex-wrap items-center justify-center gap-2.5 px-24 py-4 lg:px-32 lg:py-5 ${paginationClassName}`}
            ></div>
            <div className="absolute bottom-0 right-0 z-[2] flex gap-px">
              <button
                className={`flex items-center justify-center rounded-l-full bg-pr-red-500 px-2.5 py-3.5 text-white transition disabled:bg-pr-red-100 lg:px-4 lg:py-5 ${prevClassName}`}
              >
                <Arrow className="h-3 w-5 rotate-180 fill-current" />
              </button>
              <button
                className={`flex items-center justify-center rounded-r-full bg-pr-red-500 px-2.5 py-3.5 text-white transition disabled:bg-pr-red-100 lg:px-4 lg:py-5 ${nextClassName}`}
              >
                <Arrow className="h-3 w-5 fill-current" />
              </button>
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductImageCarouselBlock;

/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import Image, { ImageProps } from "next/image";
import { IContentfulImage } from "@src/lib/types";
import {
  contentfulImageUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { ImageOptimizerCache } from "next/dist/server/image-optimizer";

export type ContentfulImageProps = Omit<ImageProps, "src" | "alt"> & {
  image?: IContentfulImage | null;
  transforms?: ContentfulImageUrlTransforms;
  layout?: "fill" | "responsive" | undefined;
  priority?: boolean;
  className?: string;
};

const defaultTransforms: ContentfulImageUrlTransforms = {
  fit: "fill" as const,
  focus: "center" as const,
  format: "webp" as const,
  quality: 50
  //   width: dimensions.width,
  //   height: dimensions.height,
};

const calculatedHeight = (
  originalHeight: number,
  originalWidth: number,
  newWidth: number
) => {
  return Math.round((originalHeight / originalWidth) * newWidth);
};

const calculatedTransforms = (
  viewport: number,
  image: IContentfulImage,
  transforms?: ContentfulImageUrlTransforms
) => {
  let width: number = viewport;
  let height: number = viewport;

  if (transforms) {
    width =
      (transforms.width as number) <= viewport
        ? (transforms.width as number)
        : viewport;
    height =
      (transforms.width as number) <= viewport
        ? (transforms.height as number)
        : calculatedHeight(
            transforms.height as number,
            transforms.width as number,
            width
          );
  } else {
    width =
      (image.width as number) <= viewport ? (image.width as number) : viewport;
    height =
      (image.width as number) <= viewport
        ? (image.height as number)
        : calculatedHeight(
            image.height as number,
            image.width as number,
            width
          );
  }

  return {
    ...defaultTransforms,
    width: width,
    height: height
  };
};

const srcSetTransforms = (
  image: IContentfulImage,
  transforms?: ContentfulImageUrlTransforms
) => {
  const srcAt480 = contentfulImageUrl(
    image,
    calculatedTransforms(480, image, transforms)
  );
  const srcAt640 = contentfulImageUrl(
    image,
    calculatedTransforms(640, image, transforms)
  );
  const srcAt1200 = contentfulImageUrl(
    image,
    calculatedTransforms(1200, image, transforms)
  );
  const srcAt1920 = contentfulImageUrl(
    image,
    calculatedTransforms(1920, image, transforms)
  );
  return `${srcAt480} 480w, ${srcAt640} 640w, ${srcAt1200} 1200w, ${srcAt1920} 1920w,`;
};

export function ContentfulImage({
  image,
  transforms,
  layout = "responsive",
  priority = false,
  className
}: ContentfulImageProps) {
  // console.log("image: ", image);
  // const src = image ? contentfulImageUrl(image, transforms) : "";
  // const srcSet = image ? srcSetTransforms(image, transforms) : "";
  // const width = transforms?.width ? transforms.width : undefined;
  // const height = transforms?.height ? transforms.height : undefined;

  const [src, setSrc] = useState(
    priority && image ? "" : image ? contentfulImageUrl(image, transforms) : ""
  );
  const [srcSet, setSrcSet] = useState(
    priority && image ? "" : image ? srcSetTransforms(image, transforms) : ""
  );
  const [width, setWidth] = useState<string | number | undefined>(
    transforms?.width
  );
  const [height, setHeight] = useState<string | number | undefined>(
    transforms?.height
  );

  useEffect(() => {
    if (priority) {
      if (!image) {
        return;
      }
      setSrc("");
      setSrcSet("");
      const newSrc = `${contentfulImageUrl(image, transforms)}`;
      const newSrcSet = `${srcSetTransforms(image, transforms)}`;

      const timer = setTimeout(() => {
        setSrc(newSrc);
        setSrcSet(newSrcSet);
        setWidth(transforms?.width);
        setHeight(transforms?.height);
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [image, transforms, priority]);

  if (!image) {
    return null;
  }

  // console.log("src: ", src)
  // console.log("width and height ", width, height);
  // console.log("classname: ", className);
  // return (
  //   <Image
  //     src={src}
  //     alt={image?.description || "BentoBox"}
  //     width={layout !== "fill" ? width : undefined}
  //     height={layout !== "fill" ? height : undefined}
  //     layout={layout}
  //     quality={50}
  //     priority={priority}
  //     className={className}
  //   />
  // );

  switch (layout) {
    case "fill":
      return (
        <span
          style={{
            boxSizing: "border-box",
            display: "block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: "1",
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            inset: "0px"
          }}
        >
          <img
            alt={image.description || "BentoBox"}
            sizes="100vw"
            className={`${className || ""} ${priority ? "opacity-0" : ""} ${
              priority && srcSet && src ? "!opacity-100" : ""
            }`}
            decoding={priority ? "sync" : "async"}
            loading={priority ? "eager" : "lazy"}
            style={{
              position: "absolute",
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%"
            }}
            srcSet={srcSet}
            src={src}
          />
        </span>
      );
    case "responsive":
      return (
        <span
          style={{
            display: "block",
            position: "relative",
            aspectRatio: `${width || 1}/${height || 1}`
          }}
        >
          <img
            sizes="100vw"
            alt={image.description || "BentoBox"}
            className={`${className || ""} ${priority ? "opacity-0" : ""} ${
              priority && srcSet && src ? "!opacity-100" : ""
            }`}
            decoding={priority ? "sync" : "async"}
            loading={priority ? "eager" : "lazy"}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
              boxSizing: "border-box",
              padding: "0",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0",
              height: "0",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%"
            }}
            src={src}
            srcSet={srcSet}
          />
        </span>
      );
  }
}

export default ContentfulImage;

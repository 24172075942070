import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { SmartLink, ContentfulImage } from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type CardHeroProps = {
  url: string;
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  meta?: string;
};

export function CardHero({
  url,
  media,
  eyebrow,
  heading,
  meta
}: CardHeroProps) {
  const dimensions = {
    width: 743,
    height: 446
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div className="grid auto-rows-min grid-cols-8 py-5">
      <div className="col-span-5 mr-4 md:col-span-6 md:mr-6">
        <p className="type-eta text-red-600">{eyebrow}</p>
        <SmartLink href={url} className="group mt-3 block">
          <h3 className="type-delta link-underline-animated-sm">{heading}</h3>
        </SmartLink>
        {meta && <p className="type-zeta mt-2 text-blue-200">{meta}</p>}
      </div>

      <SmartLink href={url} className="col-span-3 md:col-span-2 md:-ml-2">
        <figure className="aspect-[743/446]">
          <ContentfulImage
            image={media}
            transforms={transforms}
            priority={true}
          />
        </figure>
      </SmartLink>
    </div>
  );
}

export default CardHero;

import * as React from "react";

import type {
  HolidayCMColumnCards,
  HolidayCMHeadingWithIcon,
  HolidayCMImageCarousel,
  HolidayCMImageWithText,
  HolidayCMLink,
  HolidayCMTabbedContent,
  HolidayPageContentModule,
  ImageCarouselGroup
} from "@src/lib/services/server/contentful";

import {
  isHolidayColumnCards,
  isHolidayHeadingWithIcon,
  isHolidayImageCarousel,
  isHolidayImageWithText,
  isHolidayLink,
  isHolidayTabbedContent
} from "@src/utils/typeGuards";

import {
  HolidayColumnCards,
  HolidayHeadingWithIcon,
  HolidayImageCarousel,
  HolidayImageWithText,
  HolidayLink,
  HolidayTabbedContent
} from "@src/components";

export type HolidayTimelineModulesProps = {
  id: string;
  index: number;
  contentModules: HolidayPageContentModule[];
};

const renderTabbedContent = (contentModule: HolidayCMTabbedContent) => {
  return (
    <HolidayTabbedContent key={contentModule.sys.id} content={contentModule} />
  );
};

const renderHeadingWithIcon = (contentModule: HolidayCMHeadingWithIcon) => {
  return (
    <HolidayHeadingWithIcon
      key={contentModule.sys.id}
      content={contentModule}
    />
  );
};

const renderImageWithText = (contentModule: HolidayCMImageWithText) => {
  return (
    <HolidayImageWithText key={contentModule.sys.id} content={contentModule} />
  );
};

const renderImageCarousel = (contentModule: HolidayCMImageCarousel) => {
  return (
    <HolidayImageCarousel
      key={contentModule.sys.id}
      items={
        contentModule.imageCarouselGroupsCollection
          ?.items as ImageCarouselGroup[]
      }
    />
  );
};

const renderColumnCards = (contentModule: HolidayCMColumnCards) => {
  return (
    <HolidayColumnCards key={contentModule.sys.id} content={contentModule} />
  );
};

const renderLink = (contentModule: HolidayCMLink) => {
  return <HolidayLink key={contentModule.sys.id} content={contentModule} />;
};

const renderContentModule = (
  contentModule: HolidayPageContentModule
): React.ReactNode => {
  if (isHolidayHeadingWithIcon(contentModule))
    return renderHeadingWithIcon(contentModule);

  if (isHolidayImageCarousel(contentModule))
    return renderImageCarousel(contentModule);

  if (isHolidayImageWithText(contentModule))
    return renderImageWithText(contentModule);

  if (isHolidayTabbedContent(contentModule))
    return renderTabbedContent(contentModule);

  if (isHolidayColumnCards(contentModule))
    return renderColumnCards(contentModule);

  if (isHolidayLink(contentModule)) return renderLink(contentModule);

  return null;
};

const HolidayTimelineModules = ({
  id,
  index,
  contentModules
}: HolidayTimelineModulesProps) => {
  const backgroundClassNames: { [key: number]: string } = {
    0: "bg-ada-yellow-500/5",
    1: "bg-ada-yellow-500/10",
    2: "bg-ada-yellow-500/15",
    3: "bg-ada-yellow-500/20"
  };

  const backgroundClassName = backgroundClassNames[index];

  return (
    <div
      id={id}
      className={`${backgroundClassName} flex min-h-[50vh] items-center border-y border-ada-yellow-500/25 px-0 py-8 pr-12 md:pr-20 lg:px-24`}
    >
      <div
        id={`${id}__content-modules`}
        className="mx-auto flex w-full max-w-screen-4xl flex-col px-5 lg:px-8 2xl:px-24"
      >
        {contentModules.map(contentModule =>
          renderContentModule(contentModule)
        )}
      </div>
    </div>
  );
};

export { HolidayTimelineModules };

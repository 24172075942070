import * as React from "react";

const cache = new Map<string, true>();

export type StylesheetProps = {
  href: string;
  prefetch?: boolean;
};

export function Stylesheet({ href, prefetch }: StylesheetProps) {
  React.useEffect(() => {
    const rel = prefetch ? "prefetch" : "stylesheet";
    const key = `${rel}:${href}`;
    if (!cache.get(key)) {
      cache.set(key, true);
      const link = document.createElement("link");
      link.href = href;
      link.rel = rel;
      document.head.appendChild(link);
    }
  }, [href, prefetch]);

  return null;
}

export default Stylesheet;

import {
  HolidayPageContentModule,
  HolidayCMHeadingWithIcon,
  HolidayCMImageCarousel,
  HolidayCMImageWithText,
  HolidayCMTabbedContent,
  HolidayCMColumnCards,
  HolidayCMLink
} from "@src/lib/services/server/contentful";

export function isHolidayHeadingWithIcon(
  contentModule: HolidayPageContentModule
): contentModule is HolidayCMHeadingWithIcon {
  return contentModule.__typename === "HolidayHeadingWithIcon";
}

export function isHolidayImageCarousel(
  contentModule: HolidayPageContentModule
): contentModule is HolidayCMImageCarousel {
  return contentModule.__typename === "HolidayImageCarousel";
}

export function isHolidayImageWithText(
  contentModule: HolidayPageContentModule
): contentModule is HolidayCMImageWithText {
  return contentModule.__typename === "HolidayImageWithText";
}

export function isHolidayTabbedContent(
  contentModule: HolidayPageContentModule
): contentModule is HolidayCMTabbedContent {
  return contentModule.__typename === "HolidayTabbedContent";
}

export function isHolidayColumnCards(
  contentModule: HolidayPageContentModule
): contentModule is HolidayCMColumnCards {
  return contentModule.__typename === "HolidayColumnCards";
}

export function isHolidayLink(
  contentModule: HolidayPageContentModule
): contentModule is HolidayCMLink {
  return contentModule.__typename === "HolidayLink";
}

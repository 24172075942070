export type FetchClientOptions = RequestInit & {
  baseUrl: string;
  url: string;
  params?:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

export class FetchError extends Error {
  response: Response;

  constructor(response: Response) {
    super("Fetch error");
    this.response = response;
  }
}

export const fetchClient = async <T>({
  baseUrl,
  url,
  method,
  headers,
  params,
  data
}: FetchClientOptions): Promise<T> => {
  const queryString = new URLSearchParams(params).toString();
  const querySeparator = params ? "?" : "";
  const response = await fetch(
    `${baseUrl}${url}${querySeparator}${queryString}`,
    {
      method,
      headers,
      ...(data ? { body: JSON.stringify(data) } : {})
    }
  );
  if (!response.ok) {
    throw new FetchError(response);
  }
  return response.json() as unknown as Promise<T>;
};

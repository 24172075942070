import { ParsedUrlQuery } from "querystring";
import { CalendlyUtm } from "@src/lib/types";
import { waitUntilCalendlyIsReady, calendlyUtmParams } from "@src/lib/utils";

/**
 * Map of query param / cookie name  => Calendly utm prop
 */
const calendlyUtmMap: Record<string, keyof CalendlyUtm> = {
  // BENTO-80: intentionally track the Google Click ID in Calendly's UTM Source parameter
  gclid: "utmSource",
  utm_content: "utmContent",
  utm_campaign: "utmCampaign",
  utm_medium: "utmMedium",
  utm_term: "utmTerm"
};

export async function calendlyPopupWidget(
  slug: string,
  query?: ParsedUrlQuery
): Promise<void> {
  const url = `https://calendly.com/d/zyy-w65-q3w/${slug}`;

  const utm = calendlyUtmParams(query);

  const { initPopupWidget } = await waitUntilCalendlyIsReady();

  initPopupWidget({ url, utm });
}

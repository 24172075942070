import * as React from "react";
import { ProductContainer, RichText } from "@src/components";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import { InlineWidget } from "react-calendly";
import { calendlyUtmParams } from "@src/lib/utils";
import { useRouter } from "next/router";

export type ProductGetStartedThankYouProps = {
  heading?: string | null;
  subtext?: Document | null;
  image?: IContentfulImage | null;
  calendlyHeading?: Document | null;
  calendlySlug?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductRtGetStartedThankYou({
  heading,
  subtext,
  image,
  calendlyHeading,
  calendlySlug
}: ProductGetStartedThankYouProps) {
  const router = useRouter();
  const query = router.query;

  const utm = calendlyUtmParams(query);

  return (
    <div className="bg-pr-yellow-200 py-12 md:py-16 lg:pb-72 lg:pt-36">
      <ProductContainer>
        <div className="px-1 md:px-2 lg:px-8">
          <div className="flex flex-col items-center gap-10 md:gap-5 lg:flex-row lg:justify-between">
            <div className="flex flex-col items-center justify-center gap-10 text-center">
              {heading && (
                <h2 className="text-pr-4xl leading-pr-relaxed tracking-pr-wider lg:text-[2.75rem]">
                  {returnLineBreaks(heading)}
                </h2>
              )}
              {image && (
                <figure className={`relative h-[127px] w-[187px]`}>
                  <img
                    src={image.url || undefined}
                    alt={image.description || undefined}
                  ></img>
                </figure>
              )}
              {subtext && (
                <div className="max-w-[330px] space-y-5 leading-pr-looser [&_a]:text-pr-teal-500">
                  <RichText text={subtext} />
                </div>
              )}
            </div>
            <div className="w-full">
              {calendlyHeading && (
                <div className="prose prose-pr mx-auto mb-10 pt-10 text-center  md:mb-0 lg:mb-10 lg:pt-0 xl:mb-0 [&_h3]:text-[2.75rem] [&_h3]:font-normal [&_h3]:leading-pr-relaxed [&_h3]:tracking-pr-wider 4xl:[&_p]:mb-0">
                  <RichText text={calendlyHeading} />
                </div>
              )}
              {calendlySlug && (
                <div className="mx-auto h-full w-full">
                  <InlineWidget
                    url={`https://calendly.com/d/zyy-w65-q3w/${calendlySlug}`}
                    utm={utm}
                    styles={{
                      height: "705px",
                      minWidth: "320px"
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductRtGetStartedThankYou;

import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { SmartLink, ContentfulImage } from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type CardInterrupterProps = {
  url: string;
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  meta?: string;
};

export function CardInterrupter({
  url,
  media,
  eyebrow,
  heading,
  meta
}: CardInterrupterProps) {
  const dimensions = {
    width: 743,
    height: 446
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div className="grid auto-rows-min grid-cols-8 py-5 md:px-2.5 md:pt-0 lg:px-5 lg:pt-5">
      <div className="col-span-5 mr-4 md:col-span-full md:row-start-2 md:mr-0 md:mt-4 lg:col-span-5 lg:row-start-auto lg:mt-0">
        <p className="type-eta text-red-600">{eyebrow}</p>
        <SmartLink href={url} className="group mt-3 block md:mt-2">
          <h3 className="type-delta md:type-epsilon link-underline-animated-sm">
            {heading}
          </h3>
        </SmartLink>
        {meta && <p className="type-zeta mt-2 text-blue-200 md:mt-4">{meta}</p>}
      </div>

      <SmartLink
        href={url}
        className="col-span-3 md:col-span-full md:row-start-1 lg:col-span-3 lg:row-start-auto lg:ml-4"
      >
        <figure className="aspect-[743/446]">
          <ContentfulImage image={media} transforms={transforms} />
        </figure>
      </SmartLink>
    </div>
  );
}

export default CardInterrupter;

import React, { useState, RefObject, useEffect } from "react";
import { FeaturedBlogPostsCollectionItem } from "@src/lib/services/server/contentful";
// import { useHoverIntent } from "react-use-hoverintent";
import { SmartLink, Grid, BlogContainer } from "@src/components";

export type SubnavigationItemProps = {
  url: string;
  title?: string | null;
  subnodes?: FeaturedBlogPostsCollectionItem[] | null;
  className?: string;
  liClassName?: string;
  forceClosed?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

// @TODO: ARIA attrs
// @TODO: implement Transition
// @TODO: active state derived from current route
// @TODO: clean implementation of hoverintent
// @TODO: clean implementation of focus and mouse
// @TODO: reimplement popover with popover.button
// const handleFocus = (e: React.SyntheticEvent<EventTarget>) => {
//   const currentTarget = e.currentTarget as HTMLElement;
//   requestAnimationFrame(() => {
//     if (currentTarget.contains(document.activeElement) && !isFocused) {
//       setIsFocused(true);
//     }
//   });
// };
// const handleBlur = (e: React.SyntheticEvent<EventTarget>) => {
//   const currentTarget = e.currentTarget as HTMLElement;
//   requestAnimationFrame(() => {
//     if (!currentTarget.contains(document.activeElement) && isFocused) {
//       setIsFocused(false);
//     }
//   });
// };

export function SubnavigationItem({
  url,
  title,
  subnodes,
  className = "type-epsilon link-underline-animated-sm",
  liClassName,
  onClick,
  forceClosed = false
}: SubnavigationItemProps) {
  // const router = useRouter();
  const [isFocused, setIsFocused] = useState(false);
  // const [isHovered, ref] = useHoverIntent();
  // console.log("isHovered? ", isHovered)

  // useEffect(() => {
  //   const handleRouteChangeComplete = () => {
  //     setIsFocused(false);
  //   };
  //   router.events.on("routeChangeComplete", handleRouteChangeComplete);
  //   return () => {
  //     router.events.off("routeChangeComplete", handleRouteChangeComplete);
  //   };
  // }, [router]);

  // const handleMouseEnter = () => setIsFocused(true);
  // const handleMouseLeave = () => setIsFocused(false);

  if (subnodes) {
    return (
      <li
        className={`list-none ${liClassName ? liClassName : ""}`}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        // ref={ref as RefObject<HTMLLIElement>}
      >
        <SmartLink
          href={url}
          className={`${className} ${isFocused ? "lg:bg-red-100/70" : ""}`}
          onClick={onClick}
        >
          {title}
        </SmartLink>
        {/* <Popover>
          <>
            <SmartLink
              href={url}
              className={`${className} ${isFocused ? "lg:bg-red-100/70" : ""}`}
            >
              {title}
            </SmartLink>

            <Transition
              show={isFocused && !forceClosed}
              enter="transition duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel className="absolute left-0 top-28 z-10 hidden w-full bg-white pb-20 shadow-xl lg:block">
                <Grid
                  sectionHeading={title}
                  displayEyebrow={title}
                  items={subnodes}
                  paddingBottom={"pb-11"}
                  shortContent={true}
                />

                <BlogContainer>
                  <SmartLink
                    href={url}
                    className="button-solid-red w-full sm:w-auto"
                  >
                    View All {title}
                  </SmartLink>
                </BlogContainer>
              </Popover.Panel>
            </Transition>
          </>
        </Popover> */}
      </li>
    );
  } else {
    return (
      <li className={`list-none ${liClassName ? liClassName : ""}`}>
        <SmartLink href={url} className={className} onClick={onClick}>
          {title}
        </SmartLink>
      </li>
    );
  }
}

export default SubnavigationItem;

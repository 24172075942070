import * as React from "react";
import {
  ProductCtaButtonType,
  ProductCtaDemoRequestType,
  ProductCtaFormType
} from "@src/lib/services/server/contentful";
import { ProductCta, ProductCtaForm } from "@src/components";
import { Document } from "@contentful/rich-text-types";

export type ProductCtaBlockProps = {
  cta?: ProductCtaButtonType | ProductCtaDemoRequestType | ProductCtaFormType;
};

// @TODO: wire up ProductCtaForm component, starting with ProductCtaDemoRequestType

export function ProductCtaBlock({ cta }: ProductCtaBlockProps) {
  const renderCta = (
    cta: ProductCtaButtonType | ProductCtaDemoRequestType | ProductCtaFormType
  ) => {
    switch (cta.__typename) {
      case "ProductCtaButton":
        return (
          <ProductCta
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            image={cta.image}
            imageOrientation={cta.imageOrientation
              ?.toLowerCase()
              .split(" ")
              .join("")}
            ctaTitle={cta.ctaTitle}
            ctaUrl={cta.ctaUrl}
            ctaId={cta.ctaId}
            backgroundStyle={cta.backgroundStyle
              ?.toLowerCase()
              .split(" ")
              .join("")}
          />
        );
      case "ProductCtaDemoRequest":
        return (
          <ProductCtaForm
            heading={cta.heading}
            headingStyle={cta.headingStyle?.toLowerCase().split(" ").join("")}
            text={cta.text?.json as Document | undefined}
            textStyle={cta.textStyle?.toLowerCase().split(" ").join("")}
            formKey={cta.__typename.toLowerCase()}
            backgroundStyle={cta.backgroundStyle
              ?.toLowerCase()
              .split(" ")
              .join("")}
            orientation={cta.orientation?.toLowerCase().split(" ").join("")}
            displayLogo={cta.displayLogo !== null ? cta.displayLogo : true}
          />
        );
      case "ProductCtaForm":
        return (
          <ProductCtaForm
            heading={cta.heading}
            headingTag={cta.headingTag}
            headingStyle={cta.headingStyle?.toLowerCase().split(" ").join("")}
            text={cta.text?.json as Document | undefined}
            textStyle={cta.textStyle?.toLowerCase().split(" ").join("")}
            image={cta.image}
            formKey={cta.__typename.toLowerCase()}
            source={cta.form}
            formId={cta.marketoFormId?.trim()}
            formTheme={cta.formTheme?.toLowerCase().split(" ").join("")}
            backgroundStyle={cta.backgroundStyle
              ?.toLowerCase()
              .split(" ")
              .join("")}
            orientation={cta.orientation?.toLowerCase().split(" ").join("")}
            displayLogo={cta.displayLogo !== null ? cta.displayLogo : true}
            formHeading={cta.formHeading}
            finePrint={cta.finePrint?.json as Document | undefined}
          />
        );
    }
  };

  return <div>{cta && <>{renderCta(cta)}</>}</div>;
}

export default ProductCtaBlock;

import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { SmartLink, ContentfulImage } from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type CardGridProps = {
  url: string;
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  meta?: string;
  text?: string | null;
  responsive?: boolean;
  shortContent?: boolean;
  linkTag?: boolean;
};
export function CardGrid({
  url,
  media,
  eyebrow,
  heading,
  meta,
  text,
  responsive = false,
  shortContent = false,
  linkTag = false
}: CardGridProps) {
  const dimensions = {
    width: 743,
    height: 446
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div
      className={`grid auto-rows-min py-5 md:p-0 ${
        responsive ? "grid-flow-col-dense grid-cols-8 md:grid-cols-none" : ""
      }`}
    >
      <SmartLink
        href={url}
        className={`${
          responsive
            ? "col-span-3 col-start-6 md:col-span-full md:col-start-1"
            : "row-start-1"
        }`}
        linkTag={linkTag}
      >
        <figure className="aspect-[743/446]">
          <ContentfulImage image={media} transforms={transforms} />
        </figure>
      </SmartLink>

      <div
        className={`${
          responsive
            ? "col-span-5 col-start-1 mr-4 md:col-span-full md:mr-0 md:mt-4"
            : "row-start-2 mt-4"
        }`}
      >
        <p className="type-eta text-red-600">{eyebrow}</p>
        <SmartLink href={url} className="group mt-3 block" linkTag={linkTag}>
          <h3 className="type-delta link-underline-animated-sm">{heading}</h3>
        </SmartLink>
        {meta && !shortContent && (
          <p className="type-zeta mt-2 text-blue-200">{meta}</p>
        )}
        {text && !shortContent && (
          <p
            className={`mt-2 text-gray-400 ${
              responsive ? "hidden md:block" : ""
            } `}
          >
            {text}
          </p>
        )}
      </div>
    </div>
  );
}

export default CardGrid;

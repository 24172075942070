import getConfig from "next/config";
import { NextConfig } from "@src/lib/types";

export type ContentfulEntryWithUrl = {
  __typename?: string | null;
  slug?: string | null;
};

const { publicRuntimeConfig } = getConfig() as NextConfig;

export function contentfulEntryUrl(
  entry: ContentfulEntryWithUrl,
  absoluteUrl: boolean = false
): string {
  let url: string = "";
  switch (entry.__typename) {
    case "Homepage":
      url = "/blog/";
      break;
    case "BlogPost":
      if (entry.slug) {
        url = `/blog/${entry.slug}/`;
      }
      break;
    case "Topic":
      if (entry.slug) {
        url = `/blog/topic/${entry.slug}/`;
      }
      break;
    case "ProductHomepage":
      url = "/";
      break;
    case "CaseStudies":
      url = `/case-studies/`;
      break;
    case "WhyBento":
      url = `/why-bentobox/`;
      break;
    case "Pricing":
      url = `/pricing/`;
      break;
    case "Service":
      if (entry.slug) {
        url = `/products/${entry.slug}/`;
      }
      break;
    case "Pillar":
      if (entry.slug) {
        url = `/pillars/${entry.slug}/`;
      }
      break;
    case "Solution":
      if (entry.slug) {
        url = `/solutions/${entry.slug}/`;
      }
      break;
    case "CaseStudy":
      if (entry.slug) {
        url = `/case-studies/${entry.slug}/`;
      }
      break;
    case "ProductPage":
      if (entry.slug) {
        url = `/${entry.slug}/`;
      }
      break;
    case "Partners":
      url = `/partners/`;
      break;
  }

  return url && absoluteUrl ? `${publicRuntimeConfig.site.baseUrl}${url}` : url;
}

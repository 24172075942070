import * as React from "react";
import { openModal, useAppDispatch } from "@src/lib/store";
import { useRouter } from "next/router";
import "@src/lib/types";
import { calendlyPopupWidget } from "@src/lib/utils";

type UrlHashAction = {
  match: string | RegExp;
  handler(...args: string[]): void;
};

export function useUrlHashActions() {
  const router = useRouter();
  const query = router.query;
  const dispatch = useAppDispatch();

  /**
   * A map of URL hash actions and handlers
   */
  const actionMap = React.useMemo<UrlHashAction[]>(
    () => [
      {
        match: /^calendly-(.+)$/,
        handler(slug: string) {
          void calendlyPopupWidget(slug, query);
        }
      },
      {
        match: /^modal-(.+)$/,
        handler(slug: string) {
          dispatch(openModal(slug));
        }
      }
    ],
    [dispatch, query]
  );

  /**
   * Find an action that matches the given URL hash in
   * the action map and execute the action handler.
   */
  const handleUrlHashAction = React.useCallback(
    (hash?: string) => {
      const args: string[] = [];
      const action = actionMap.find(({ match }) => {
        if (hash === match) {
          return true;
        }
        if (match instanceof RegExp) {
          const matches = hash?.match(match);
          if (matches) {
            args.push(...matches.slice(1));
            return true;
          }
        }
        return false;
      });
      if (action) {
        action.handler(...args);
      }
    },
    [actionMap]
  );

  React.useEffect(() => {
    const onHashChangeComplete = (url: string) =>
      handleUrlHashAction(url.split("#")[1]);

    router.events.on("hashChangeComplete", onHashChangeComplete);

    return () => {
      router.events.off("hashChangeComplete", onHashChangeComplete);
    };
  }, [router, handleUrlHashAction]);

  const [isInitialized, setIsInitialized] = React.useState(false);

  // check if there's a hash on pageload
  React.useEffect(() => {
    if (!isInitialized) {
      const hash = router.asPath.split("#")[1];
      handleUrlHashAction(hash);
      setIsInitialized(true);
    }
  }, [handleUrlHashAction, isInitialized, router.asPath]);
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "..";

export interface ModalState {
  open: boolean;
  slug?: string;
}

const initialState: ModalState = {
  open: false
};

export const createModalSlice = (initialState: ModalState) =>
  createSlice({
    name: "modal",
    initialState,
    reducers: {
      openModal: (state, action: PayloadAction<string>) => {
        state.open = true;
        state.slug = action.payload;
      },
      closeModal: state => {
        state.open = false;
      }
    }
  });

export const defaultModalSlice = createModalSlice(initialState);

export const { openModal, closeModal } = defaultModalSlice.actions;

export const selectModalOpen = (state: RootState) => state.modal.open;
export const selectModalSlug = (state: RootState) => state.modal.slug;

export const defaultModalReducer = defaultModalSlice.reducer;

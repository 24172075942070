import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { SmartLink, ContentfulImage } from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type CardListProps = {
  url: string;
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  meta?: string;
  text?: string | null;
};

export function CardList({
  url,
  media,
  eyebrow,
  heading,
  meta,
  text
}: CardListProps) {
  const dimensions = {
    width: Math.round(743 / 1.5),
    height: Math.round(446 / 1.5)
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div className="grid grid-cols-8 py-5 lg:py-[54px]">
      <div className="col-span-5 mr-4 md:mr-8">
        <p className="type-eta text-red-600">{eyebrow}</p>
        <SmartLink href={url} className="group mt-3 block">
          <h3 className="type-delta link-underline-animated-sm">{heading}</h3>
        </SmartLink>
        {meta && <p className="type-zeta mt-2 text-blue-200">{meta}</p>}
        {text && <p className="mt-2 hidden text-gray-400 md:block">{text}</p>}
      </div>

      <SmartLink href={url} className="col-span-3">
        <figure className="aspect-[743/446]">
          <ContentfulImage image={media} transforms={transforms} />
        </figure>
      </SmartLink>
    </div>
  );
}

export default CardList;

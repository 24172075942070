/* eslint-disable tailwindcss/no-contradicting-classname */

import * as React from "react";

import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import {
  HolidayCMTabbedContent,
  HolidayCMTabbedContentSlideItem
} from "@src/lib/services/server/contentful";

import {
  ContentfulImage,
  ProductContainer,
  TrendReportOneColumnText,
  RichText
} from "@src/components";

import { PlyrVideo } from "../PlyrVideo";

import { filterNull } from "@src/lib/utils";

import "swiper/css/pagination";
import "swiper/css";

export type HolidayTabbedContentProps = {
  content: HolidayCMTabbedContent;
  image?: IContentfulImage | null;
};

const ImageWithText = ({
  slide
}: Readonly<HolidayCMTabbedContentSlideItem>) => {
  const orientation =
    slide?.imagePosition === "Left" ? "imageonleft" : "imageonright";
  const mobileOrientation =
    slide?.imageMobilePosition === "Bottom" ? "imageonbottom" : "imageontop";

  const fullWidthImageTransforms = {
    width: slide?.image?.width || 1648,
    height: slide?.image?.height || 1470
  };

  const imageColClassNames = {
    imageonleft: "order-1 md:mx-0 md:col-span-2 md:col-start-1 md:col-end-2",
    imageonright: "order-2 md:mx-0 md:col-span-2 md:col-start-2 md:col-end-3"
  };

  const textColClassNames = {
    imageonleft:
      "py-12 w-full md:col-span-2 md:col-start-2 md:col-end-3 md:max-w-[696px]",
    imageonright:
      "py-12 w-full md:col-span-2 md:col-start-1 md:col-end-2 md:max-w-[696px]",
    imageonbottom: "order-1",
    imageontop: "order-2"
  };

  const imageColClassName = imageColClassNames[orientation];
  const textColClassName = textColClassNames[orientation];
  const mobileTextColClassName = textColClassNames[mobileOrientation];

  return slide?.video ? (
    <div className="flex flex-col content-center items-center gap-6">
      <ProductContainer>
        <div className="contained mx-auto space-y-6 lg:space-y-8">
          <figure className="space-y-1 md:space-y-4">
            <div className="overflow-hidden rounded-2xl md:rounded-3xl">
              <PlyrVideo video={slide.video} />
            </div>
          </figure>
        </div>
      </ProductContainer>
      {slide.eyebrow && (
        <p className="type-pr-theta md:type-pr-eta text-pr-dark-gray-400">
          {slide.eyebrow}
        </p>
      )}
      <div
        className={`space-y-2 lg:space-y-4 ${
          slide.eyebrow ? "mt-1.5 lg:mt-3" : ""
        }`}
      >
        <h2 className="type-pr-delta lg:text-[2.75rem]">{slide.heading}</h2>
        {slide.text && (
          <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
            <RichText text={slide.text.json as Document} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="grid content-center items-center md:grid-flow-col-dense md:grid-cols-2">
      {slide?.image && (
        <div className={`col-span-full ${imageColClassName}`}>
          <figure className="relative w-full">
            <ContentfulImage
              image={slide.image}
              transforms={fullWidthImageTransforms}
            />
          </figure>
        </div>
      )}
      <div
        className={`col-span-full flex flex-col justify-center ${mobileTextColClassName} ${textColClassName}`}
      >
        {slide?.eyebrow && (
          <p className="type-pr-theta md:type-pr-eta text-pr-dark-gray-400">
            {slide.eyebrow}
          </p>
        )}
        <div
          className={`space-y-2 lg:space-y-4 ${
            slide?.eyebrow ? "mt-1.5 lg:mt-3" : ""
          }`}
        >
          <h2 className="type-pr-delta lg:text-[2.75rem]">{slide?.heading}</h2>
          {slide?.text && (
            <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
              <RichText text={slide.text.json as Document} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const HolidayTabbedContent = ({ content }: HolidayTabbedContentProps) => {
  const { heading, eyebrow, html, ctaStyle, slidesCollection, sys } = content;
  const items = filterNull(slidesCollection?.items);
  const id = sys.id;

  const paginationClassName = id ? `pagination-${id}` : "pagination";

  const defaultCtaStyle: string = "teal";

  const ctaClassNames: { [key: string]: string } = {
    red: "swiper-pagination-bullet-trend-red",
    green: "swiper-pagination-bullet-trend-green",
    yellow: "swiper-pagination-bullet-trend-yellow",
    teal: "swiper-pagination-bullet-trend-teal"
  };

  const ctaActiveClassNames: { [key: string]: string } = {
    red: "swiper-pagination-bullet-active-trend-red",
    green: "swiper-pagination-bullet-active-trend-green",
    yellow: "swiper-pagination-bullet-active-trend-yellow",
    teal: "swiper-pagination-bullet-active-trend-teal"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];
  const ctaActiveClassName =
    ctaActiveClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <ProductContainer>
      <div className="w-full">
        <TrendReportOneColumnText
          heading={heading}
          eyebrow={eyebrow}
          html={html}
        />
        <div
          className={`mt-8 flex flex-row flex-wrap items-center justify-center gap-4 pb-5 md:flex-row lg:gap-7 lg:pb-8 ${paginationClassName}`}
        ></div>
        <Swiper
          pagination={{
            el: `.${paginationClassName}`,
            clickable: true,
            bulletActiveClass: ctaActiveClassName,
            bulletClass: ctaClassName,
            renderBullet: function (index, className) {
              return `<span class="${className}"> ${
                items[index]?.cta as string
              } </span>`;
            }
          }}
          modules={[Pagination]}
          autoHeight={true}
          className="mySwiper"
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <ImageWithText slide={item.slide} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </ProductContainer>
  );
};

export { HolidayTabbedContent };

import * as React from "react";
import { Select, Option } from "react-a11y-select";
import { Location, Service, Budget } from "@src/lib/services/server/contentful";

export type SelectTagProps = {
  placeholder: string;
  blankOptionLabel?: string;
  value?: string;
  items: Location[] | Service[] | Budget[];
  onChange?: (value: string) => void;
};

export function SelectTag({
  placeholder,
  blankOptionLabel,
  value,
  items,
  onChange
}: SelectTagProps) {
  blankOptionLabel = blankOptionLabel || placeholder;
  return (
    <Select
      onChange={onChange}
      placeholderText={placeholder}
      label={placeholder}
    >
      <Option value="" checked={!value}>
        {blankOptionLabel}
      </Option>
      {items.map((item, index) => (
        <Option
          key={index}
          value={item.slug as string}
          checked={value === item.slug}
        >
          {item.title}
        </Option>
      ))}
    </Select>
  );
}

export default SelectTag;

// style={{
//   width: "100%",
//   height: "50px",
//   borderColor: "transparent",
//   color: "#F15541",
// }}

import { useEffect } from "react";
import type { BlogNavigationQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";
import {
  Layout,
  Subnavigation,
  ProductNavigation,
  ProductFooter,
  LayoutProps
} from "@src/components";
import Logo from "@/img/icons/logo.svg";
import { GlobalProps } from "@src/lib/services/server/contentful";

export type BlogLayoutProps = LayoutProps &
  GlobalProps & {
    blogNavigation?: BlogNavigationQuery;
    children: React.ReactNode;
  };

export function BlogLayout({
  entry,
  navigation,
  navigation2,
  navigation3,
  blogNavigation,
  footer,
  children,
  ...layoutProps
}: BlogLayoutProps) {
  // const navigationItem = navigation?.navigationCollection
  //   ? navigation.navigationCollection.items[0]
  //   : null;

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.className = "";
      html.classList.add("blog-layout");
    }
  });

  return (
    <Layout entry={entry} {...layoutProps}>
      {navigation && (
        <ProductNavigation
          navigation={navigation}
          navigation2={navigation2}
          navigation3={navigation3}
          navigationStyle="yellow"
          pageType={"Blog"}
        />
      )}
      {blogNavigation && (
        <Subnavigation entry={entry} navigation={blogNavigation} />
      )}
      <main>{children}</main>
      {footer && <ProductFooter footer={footer} />}
    </Layout>
  );
}

export default BlogLayout;

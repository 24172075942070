import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText } from "@src/components";

export type ProductRichTextBlockProps = {
  text?: Document;
};

export function ProductRichTextBlock({ text }: ProductRichTextBlockProps) {
  return (
    <div className="my-10 md:my-16 lg:my-24">
      <ProductContainer>
        <div className="mx-auto max-w-[800px]">
          {text && (
            <div className="prose prose-pr lg:prose-pr-lg">
              <RichText text={text} />
            </div>
          )}
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductRichTextBlock;

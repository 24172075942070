import * as React from "react";

export type BlogContainerProps = {
  children: React.ReactNode;
};

export function BlogContainer({ children }: BlogContainerProps) {
  return (
    <div className="mx-auto w-full max-w-screen-2xl px-5 lg:px-16">
      {children}
    </div>
  );
}

export default BlogContainer;

import * as React from "react";
import { BlogContainer } from "@src/components";

export type ListingHeadingProps = {
  heading?: string | null;
  text?: string | null;
  borderTop?: boolean;
};

export function ListingHeading({
  heading,
  text,
  borderTop
}: ListingHeadingProps) {
  return (
    <section>
      <BlogContainer>
        <div
          className={`py-10 md:py-14 lg:pb-28 lg:pt-16 ${
            borderTop ? "border-t border-red-500" : ""
          }`}
        >
          <div className="space-y-4 text-center">
            <h1 className="type-alpha">{heading}</h1>
            {text && (
              <p className="mx-auto max-w-prose text-gray-400 md:text-xl">
                {text}
              </p>
            )}
          </div>
        </div>
      </BlogContainer>
    </section>
  );
}

export default ListingHeading;

export type ContentfulEvent = {
  sys: {
    id: string;
    type?: string;
    environment: {
      sys: {
        type: string;
        id: string;
      };
    };
    contentType?: {
      sys: {
        type: string;
        id: string;
      };
    };
  };
  fields?: {
    slug?: {
      "en-US": string;
    };
    urlPath?: {
      "en-US": string;
    };
  };
};

export enum ContentfulContentTypes {
  BLOG_AUTHOR = "author",
  BLOG_LANDING_PAGE = "homepage",
  BLOG_NAVIGATION = "navigation",
  BLOG_POST = "blogPost",
  BLOG_TOPIC = "topic",
  CASE_STUDY = "caseStudy",
  CASE_STUDIES = "caseStudies",
  HOMEPAGE = "productHomepage",
  HOLIDAY = "holidayPage",
  PRODUCT_FOOTER = "productFooter",
  PRODUCT_NAVIGATION = "productNavigation",
  PRODUCT_NAVIGATION_LINK = "productNavigationLink",
  PRODUCT_NAVIGATION_GROUP = "productNavigationGroup",
  MODALS = "modals",
  MODAL_FORMS = "modalForms",
  PILLAR = "pillar",
  PARTNER = "partner",
  PARTNERS = "partners",
  PRICING = "pricing",
  PRODUCT_PAGE = "productPage",
  SERVICE = "service",
  SOLUTION = "solution",
  WHY_BENTO = "whyBento",
  TREND_REPORT = "trendReport"
}

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import {
  defaultModalReducer,
  createModalSlice,
  ModalState
} from "./slices/modalSlice";

export type CreateStoreParameters = {
  modalInitialState?: ModalState;
};

export const createStore = ({
  modalInitialState
}: CreateStoreParameters = {}) => {
  const modalReducer = modalInitialState
    ? createModalSlice(modalInitialState).reducer
    : defaultModalReducer;
  return configureStore({
    reducer: {
      modal: modalReducer
    }
  });
};

export const defaultStore = createStore();

export type AppDispatch = typeof defaultStore.dispatch;
export type RootState = ReturnType<typeof defaultStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from "./slices/modalSlice";

/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import * as React from "react";
import { Client } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import {
  ProductContainer,
  RichText,
  SmartLink,
  ContentfulImage
} from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type ProductClientsLinksBlockProps = {
  heading?: string | null;
  text?: Document | null;
  mobileHeading?: string | null;
  showLinksOnMobile?: boolean | null;
  items: Client[];
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductClientsLinksBlock({
  heading,
  mobileHeading,
  showLinksOnMobile = true,
  text,
  items
}: ProductClientsLinksBlockProps) {
  // const dimensions = {
  //   width: 324,
  //   height: 112,
  // };

  // const transforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: dimensions.width,
  //   height: dimensions.height,
  // };

  const renderItems = (items: Client[]) => {
    return items.map((item, index) => {
      if (item.ctaUrl) {
        const ctaUrl = item.ctaUrl;

        return (
          <div className="w-1/3 p-1.5 md:w-1/6 md:p-[14px]" key={index}>
            <SmartLink
              href={ctaUrl}
              className="flex h-14 overflow-hidden rounded bg-white p-1.5 md:h-[104px] md:rounded-xl md:p-3"
            >
              <figure className="relative h-full w-full">
                <ContentfulImage
                  image={item.logo}
                  layout="fill"
                  className="object-contain"
                />
              </figure>
            </SmartLink>
          </div>
        );
      } else {
        return (
          <div className="w-1/3 p-1.5 md:w-1/6 md:p-[14px]" key={index}>
            <div className="flex h-14 overflow-hidden rounded bg-white p-1.5 md:h-[104px] md:rounded-xl md:p-3">
              <figure className="relative h-full w-full">
                <ContentfulImage
                  image={item.logo}
                  layout="fill"
                  className="object-contain"
                />
              </figure>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="my-20 md:my-16 lg:my-24">
      <ProductContainer>
        <div
          className={`mx-auto max-w-[1164px] ${
            heading || text ? "space-y-4 lg:space-y-8" : ""
          }`}
        >
          <div className="space-y-5 text-center">
            {heading && (
              <h2
                className={`${
                  mobileHeading ? "hidden lg:block" : "block"
                } text-[30px] font-bold lg:text-[40px]`}
              >
                {returnLineBreaks(heading)}
              </h2>
            )}

            {mobileHeading && (
              <h2 className={`text-[30px] font-bold lg:hidden`}>
                {returnLineBreaks(mobileHeading)}
              </h2>
            )}
            {text && (
              <div className="type-pr-theta text-pr-dark-gray-400">
                <RichText text={text} />
              </div>
            )}
          </div>
          <div
            className={`${
              showLinksOnMobile ? " flex " : "hidden lg:flex "
            } -mx-1.5 flex-wrap justify-center md:-mx-[14px]`}
          >
            {renderItems(items)}
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductClientsLinksBlock;

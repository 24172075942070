export function isExternalLink(link: string): boolean {
  // catch urls with domains that start wit getbento or www.getbento
  if (/(\/\/getbento)/gi.test(link)) {
    return false;
  } else if (/(\/\/www.getbento)/gi.test(link)) {
    return false;
  } else {
    // handle urls that didn't pass
    return /^(http(s)?:\/\/)/i.test(link);
  }
}

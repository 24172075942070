import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { SmartLink, ContentfulImage, HtmlHeader } from "@src/components";
import { imageOptimizer } from "next/dist/server/image-optimizer";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type CardFeaturedProps = {
  url?: string;
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  meta?: string;
  hideDate?: boolean | null;
  text?: string | null;
  largeText?: boolean;
  priority?: boolean;
  headerLevel?: 1 | 2 | 3 | 4 | 5 | 6;
};

export function CardFeatured({
  url,
  media,
  eyebrow,
  heading,
  meta,
  text,
  hideDate,
  largeText = false,
  priority = false,
  headerLevel
}: CardFeaturedProps) {
  const dimensions = {
    width: Math.round(1486 / 1.5),
    height: Math.round(892 / 1.5)
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div className="items-center md:grid md:grid-cols-5">
      <div className="md:col-span-3 md:pr-2.5 lg:pr-5">
        {url ? (
          <SmartLink href={url}>
            <figure className="aspect-[1486/892]">
              <ContentfulImage
                image={media}
                transforms={transforms}
                priority={priority}
              />
            </figure>
          </SmartLink>
        ) : (
          <figure className="aspect-[1486/892]">
            <ContentfulImage
              image={media}
              transforms={transforms}
              priority={priority}
            />
          </figure>
        )}
      </div>
      <div className="mt-4 flex flex-col justify-center md:col-span-2 md:mt-0 md:pl-2.5 lg:pl-5">
        <p className="type-eta text-red-600">{eyebrow}</p>
        {url ? (
          <SmartLink href={url} className="group mt-3 block md:mt-1">
            <HtmlHeader
              level={headerLevel || 3}
              className="type-alpha link-underline-animated-lg"
            >
              {heading}
            </HtmlHeader>
          </SmartLink>
        ) : (
          <HtmlHeader
            level={headerLevel || 2}
            className="type-alpha mt-3 md:mt-1"
          >
            {heading}
          </HtmlHeader>
        )}
        {meta && !hideDate && (
          <p className="type-zeta mt-2 text-blue-200 md:mt-4">{meta}</p>
        )}
        {text && (
          <p
            className={`text-gray-400 ${
              largeText ? "type-delta mt-8 md:mt-4" : "mt-2 max-w-prose"
            }`}
          >
            {text}
          </p>
        )}
      </div>
    </div>
  );
}

export default CardFeatured;

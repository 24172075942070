import * as React from "react";
import { bentoCoreElements } from "@src/components/BentoCore/bentoCoreElements";

export type BentoCoreGraphicProps = {
  handleClick: (id: string) => void;
  activePath: string | null;
};

const BentoCoreGraphic: React.FC<BentoCoreGraphicProps> = ({
  handleClick,
  activePath
}) => {
  const getStyle = (id: string) => {
    return activePath === id ? "bentocore-module--active" : "";
  };

  return (
    <svg
      id="bentocore"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 540 540"
      width="100vw"
      aria-labelledby="BentoBox Marketing & Commerce Platform"
      className="min-h-fit w-full"
    >
      {bentoCoreElements
        .filter(element => element.coreTitle)
        .map(element => (
          <g
            key={element.id}
            onClick={() => handleClick(element.id)}
            aria-labelledby={element.id}
            className="bentocore-center"
          >
            <g className="bentocore-center__circles">
              {element.corePaths?.map((corePath, index) => (
                <path key={index} d={corePath} />
              ))}
            </g>

            <g className="bentocore-center__title">
              <path
                d={element.coreTitle}
                className="fill-white"
                aria-labelledby={element.id}
              />
            </g>
          </g>
        ))}

      {bentoCoreElements.map(element => (
        <g
          key={element.id}
          onClick={() => handleClick(element.id)}
          className="bentocore-module"
          aria-labelledby={element.id}
        >
          <g className="bentocore-module__petal">
            <path d={element.petalPath} className={getStyle(element.id)} />
          </g>

          <g className="bentocore-module__icon">
            {element.iconPaths?.map((iconPath, index) => (
              <path key={index} d={iconPath} className={getStyle(element.id)} />
            ))}

            <path d={element.title} className={getStyle(element.id)} />
          </g>
        </g>
      ))}
    </svg>
  );
};

export { BentoCoreGraphic };

import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import {
  CtaBlogSubscriptionType,
  CtaDemoRequestType,
  CtaButtonType,
  CtaInterstitialTextType,
  CtaGatedContentType,
  CtaFormType
} from "@src/lib/services/server/contentful";
import { Cta, CtaForm } from "@src/components";
import { contentfulEntryUrl } from "@src/lib/utils";

export type CtaBlockProps = {
  cta?:
    | CtaBlogSubscriptionType
    | CtaDemoRequestType
    | CtaButtonType
    | CtaInterstitialTextType
    | CtaGatedContentType
    | CtaFormType;
};

export function CtaBlock({ cta }: CtaBlockProps) {
  const renderCta = (
    cta:
      | CtaBlogSubscriptionType
      | CtaDemoRequestType
      | CtaButtonType
      | CtaInterstitialTextType
      | CtaGatedContentType
      | CtaFormType
  ) => {
    switch (cta.__typename) {
      case "CtaButton":
        return (
          <Cta
            media={cta.media}
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            ctaTitle={cta.ctaTitle}
            ctaUrl={cta.ctaUrl ? contentfulEntryUrl(cta.ctaUrl) : null}
            ctaExternalUrl={cta.ctaExternalUrl}
            theme={cta.theme?.toLowerCase()}
            headingStyle={cta.headingStyle?.toLowerCase()}
            ctaId={cta.ctaId}
          />
        );
      case "CtaInterstitialText":
        return (
          <Cta
            eyebrow={cta.eyebrow}
            text={cta.text?.json as Document | undefined}
            theme={
              cta.theme
                ? `${cta.theme.toLowerCase()}${cta.__typename.toLocaleLowerCase()}`
                : "red"
            }
          />
        );
      case "CtaDemoRequest":
        return (
          <CtaForm
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            responsive={true}
            formKey={cta.__typename.toLowerCase()}
            headingStyle={cta.headingStyle?.toLowerCase()}
          />
        );
      case "CtaForm":
        return (
          <CtaForm
            formId={cta.marketoFormId?.trim()}
            formTheme={cta.formTheme?.toLowerCase().split(" ").join("")}
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            responsive={true}
            formKey={cta.__typename.toLowerCase()}
            headingStyle={cta.headingStyle?.toLowerCase()}
            finePrint={cta.finePrint?.json as Document | undefined}
          />
        );
      case "CtaBlogSubscription":
        return (
          <CtaForm
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            responsive={true}
            formKey={cta.__typename.toLowerCase()}
            headingStyle={cta.headingStyle?.toLowerCase()}
          />
        );
      case "CtaGatedContent":
        return (
          <CtaForm
            media={cta.media}
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            ctaTitle={cta.ctaTitle}
            formKey={cta.__typename.toLowerCase()}
            source={cta.form}
            formId={cta.marketoFormId?.trim()}
            formTheme={cta.formTheme?.toLowerCase().split(" ").join("")}
            theme={`gray${cta.__typename.toLocaleLowerCase()}`}
            headingStyle={cta.headingStyle?.toLowerCase()}
            ctaId={cta.ctaId}
            finePrint={cta.finePrint?.json as Document | undefined}
          />
        );
    }
  };

  // @TODO: this max-width is based off of prose's max-width:
  return (
    <div className="my-8 md:my-16">
      <div className="max-w-[718px]">{cta && <>{renderCta(cta)}</>}</div>
    </div>
  );
}

export default CtaBlock;

import * as React from "react";
import { Card, CaseStudy } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import {
  ProductContainer,
  SmartLink,
  RichText,
  ProductCardGrid
} from "@src/components";
import { InlinePricingGrid } from "../InlinePricingGrid";

export type ProductCaseStudyWithCardBlockProps = {
  title?: string | null;
  card: Card;
  caseStudy: CaseStudy;
  backgroundStyle?: string | null;
  cardsStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductCaseStudyWithCardBlock({
  card,
  caseStudy,
  backgroundStyle,
  title,
  cardsStyle = null
}: ProductCaseStudyWithCardBlockProps) {
  const defaultEyeBrowStyle: string = "red";
  const eyebrowClasses: { [key: string]: string } = {
    red: "bg-pr-red-500",
    teal: "bg-pr-teal-600",
    black: "bg-pr-dark-gray-500"
  };

  const eyebrowStyle = card.eyebrowStyle
    ?.toLowerCase()
    .split(" ")
    .join("") as string;
  const priceDetails = card.priceDetails?.json as Document | undefined;
  const description = card.description?.json as Document | undefined;
  const details = card.details?.json as Document | undefined;

  const eyebrowClass =
    eyebrowClasses[eyebrowStyle ? eyebrowStyle : defaultEyeBrowStyle];

  return (
    <div className="overflow-hidden bg-pr-red-100 py-8 md:py-16">
      {backgroundStyle == "motif" && (
        <div className="relative">
          <svg
            viewBox="0 0 1651 685"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute -top-16 left-0"
          >
            <path
              d="M1651.51 548C1575.84 548 1514.51 486.666 1514.51 411L1651.51 411L1651.51 548Z"
              className="fill-[#F58A70]"
            />
            <path
              d="M1445 274H1376.5V137H1445C1482.82 137 1513.5 167.667 1513.5 205.474C1513.5 243.333 1482.82 274 1445 274Z"
              className="fill-[#F58A70]"
            />
            <path
              d="M1513.51 -1.19769e-05C1589.17 -5.36199e-06 1650.51 61.334 1650.51 137L1513.51 137L1513.51 -1.19769e-05Z"
              className="fill-[#F58A70]"
            />
            <path
              d="M1376.5 137C1300.83 137 1239.5 75.666 1239.5 1.44643e-05L1376.5 1.44643e-05V137Z"
              className="fill-pr-red-200"
            />
            <path
              d="M1513.51 411C1589.63 411 1651.33 349.666 1651.33 274L1513.51 274L1513.51 411Z"
              className="fill-pr-red-200"
            />
            <path
              d="M1651.33 548C1575.21 548 1513.51 609.334 1513.51 685H1651.33V548Z"
              className="fill-pr-red-200"
            />
            <path
              d="M1580.97 274C1543.15 274 1512.47 243.322 1512.47 205.5C1512.47 167.678 1543.15 137 1580.97 137C1618.79 137 1649.47 167.678 1649.47 205.5C1649.47 243.322 1618.79 274 1580.97 274Z"
              className="fill-[#F37059]"
            />
            <path
              d="M67.5 548L136 548L136 685L67.5 685C29.6785 685 -1 654.333 -0.999999 616.526C-0.999995 578.667 29.6785 548 67.5 548Z"
              className="fill-[#F37059]"
            />
            <path
              d="M67.4999 274C29.6784 274 -1 243.322 -1 205.5C-1 167.678 29.6784 137 67.4999 137C105.321 137 136 167.678 136 205.5C136 243.322 105.321 274 67.4999 274Z"
              className="fill-pr-red-200"
            />
            <path
              d="M137 411C61.334 411 -5.36199e-06 349.666 -1.19769e-05 274L137 274L137 411Z"
              className="fill-[#F58A70]"
            />
            <path
              d="M135.964 548C212.086 548 273.789 486.666 273.789 411L135.964 411L135.964 548Z"
              className="fill-pr-red-200"
            />
            <path d="M137 0H0V137H137V0Z" className="fill-[#F58A70]" />
          </svg>
        </div>
      )}

      {!cardsStyle && (
        <ProductContainer>
          <div className="relative my-10 flex flex-col items-center justify-center gap-8 md:flex-row md:gap-12 lg:gap-24">
            <div className="w-full md:w-1/2">
              <ProductCardGrid entry={caseStudy} />
            </div>

            <div className="w-full md:w-1/2">
              <div className="rounded-2xl bg-white drop-shadow-lg md:rounded-3xl">
                {card.eyebrow && (
                  <div
                    className={`flex h-10 items-center justify-center rounded-t-2xl px-4 py-2 text-center text-white md:h-11 md:rounded-t-3xl ${eyebrowClass}`}
                  >
                    <span className="md:type-pr-zeta text-pr-lg">
                      {card.eyebrow}
                    </span>
                  </div>
                )}
                <div className="px-9 pb-12 pt-8">
                  <div className="grid grid-cols-2 items-center gap-x-2 gap-y-4 divide-x md:divide-x-0 md:divide-y lg:divide-x lg:divide-y-0">
                    <div className="space-y-6 pr-8 md:col-span-full md:pr-0 lg:col-span-1 lg:pr-8">
                      <div className="space-y-3">
                        {card.heading && (
                          <h3 className="type-pr-delta">
                            {returnLineBreaks(card.heading)}
                          </h3>
                        )}
                        {card.previousPrice && (
                          <div>
                            <span className="type-pr-zeta strikethrough-card text-pr-lg text-pr-dark-gray-400">
                              ${card.previousPrice}
                            </span>
                          </div>
                        )}
                        {priceDetails && (
                          <div className="card-details card-details-price ">
                            <RichText text={priceDetails} />
                          </div>
                        )}
                      </div>
                      {description && (
                        <div className="card-details">
                          <RichText text={description} />
                        </div>
                      )}
                    </div>
                    <div className="py-4 pl-8 md:col-span-full md:pl-0 lg:col-span-1 lg:pl-8">
                      {details && (
                        <div className="card-details card-details-price">
                          <RichText text={details} />
                        </div>
                      )}
                      {card.secondaryCtaUrl && card.secondaryCtaTitle && (
                        <SmartLink
                          href={card.secondaryCtaUrl}
                          className={
                            "type-pr-zeta link-underline-animated-sm text-pr-lg text-pr-red-600"
                          }
                        >
                          {card.secondaryCtaTitle}
                        </SmartLink>
                      )}
                    </div>
                  </div>
                  {card.primaryCtaUrl && card.primaryCtaTitle && (
                    <div className="mt-4">
                      <SmartLink
                        href={card.primaryCtaUrl}
                        className={"pr-button pr-button-lg pr-button-black"}
                      >
                        {card.primaryCtaTitle}
                      </SmartLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ProductContainer>
      )}

      {cardsStyle && cardsStyle == "Inline" && (
        <ProductContainer>
          <div className="relative flex flex-col items-center justify-center gap-8 md:gap-12">
            <h2 className="text-pr-10xl font-bold">{title}</h2>
            <InlinePricingGrid caseStudy={caseStudy} card={card} />
          </div>
        </ProductContainer>
      )}
    </div>
  );
}

export default ProductCaseStudyWithCardBlock;

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable tailwindcss/classnames-order */
import * as React from "react";
import { Document, BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import reactStringReplace from "react-string-replace";
import {
  contentfulImageUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import type { AssetsByIdQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";
import {
  documentToReactComponents,
  Options
} from "@contentful/rich-text-react-renderer";
import { SmartLink } from "@src/components";
import Checkmark from "@/img/icons/checkmark.svg";
import Whitecheckmark from "@/img/icons/whitecheckmark.svg";
import Dash from "@/img/icons/dash.svg";

type LineBreakVisibility = "sm" | "md" | "lg" | "xl";

type LinkBreakProps = {
  visibility?: LineBreakVisibility;
};

function getVisibilityClass(
  visibility?: LineBreakVisibility
): string | undefined {
  switch (visibility) {
    case "sm":
      return "sm:block";
    case "md":
      return "md:block";
    case "lg":
      return "lg:block";
    case "xl":
      return "xl:block";
  }
}

function LinkBreak({ visibility }: LinkBreakProps) {
  const visibilityClass = getVisibilityClass(visibility);
  const className = visibilityClass ? `hidden ${visibilityClass}` : undefined;
  return (
    <>
      {" "}
      <br className={className} />
    </>
  );
}

export type RichTextProps = Partial<Options> & {
  text: Document;
  richTextAssets?: AssetsByIdQuery;
  lineBreakVisibility?: LineBreakVisibility;
  className?: string;
};

export function RichText({
  text,
  richTextAssets,
  lineBreakVisibility,
  className,
  ...options
}: RichTextProps) {
  const assets = richTextAssets?.assetCollection?.items;
  const dimensions = {
    width: Math.round(1828 / 1.5)
  };
  const Wrapper = className ? "div" : React.Fragment;
  const wrapperProps = className ? { className } : {};

  const finalOptions: Options = {
    renderNode: {
      [BLOCKS.TABLE]: (node, children) => {
        return (
          // @TODO: add overflow here if needed
          <div>
            <table className="">
              <tbody>{children}</tbody>
            </table>
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        return (
          // @TODO: add overflow here if needed
          <div>
            hello
            {node.data.target.sys.id}
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const asset = assets?.find(
          asset => asset?.sys.id == node.data.target.sys.id
        );

        if (!asset) {
          return null;
        }

        const transforms: ContentfulImageUrlTransforms = {
          fit: "fill" as const,
          focus: "center" as const,
          format: "webp" as const,
          quality: 50,
          width:
            asset.width && asset.width > dimensions.width
              ? dimensions.width
              : asset.width || undefined
        };

        const src = `${contentfulImageUrl(asset, transforms)}`;

        return (
          <div
            className="mx-auto"
            style={{
              aspectRatio: `${asset.width || 1}/${asset.height || 1}`
            }}
          >
            <img alt={asset.description || ""} src={src} loading="lazy" />
          </div>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return <SmartLink href={node.data.uri}>{children}</SmartLink>;
      },
      ...options.renderNode
    },
    renderMark: {
      [MARKS.SUBSCRIPT]: text => {
        return <sub>{text}</sub>;
      },
      ...options.renderMark
    },
    renderText: (text: string) => {
      return text
        .split("\n")
        .reduce(
          (
            children: (string | false | Element | JSX.Element)[],
            textSegment: string | false | Element,
            index: number
          ) => {
            if (
              typeof textSegment == "string" &&
              textSegment.includes("[checkbox]")
            ) {
              const checkbox = (
                <span
                  className="relative mx-2 -mb-1 inline-flex size-6 p-3"
                  key={index}
                >
                  <Checkmark className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 stroke-white" />
                </span>
              ) as unknown as Element;

              textSegment = reactStringReplace(
                textSegment,
                "[checkbox]",
                (match, i) => checkbox
              ) as unknown as Element;
            }

            if (
              typeof textSegment == "string" &&
              textSegment.includes("[redcheckbox]")
            ) {
              const redcheckbox = (
                <span
                  className="relative inline-flex size-5 rounded-full bg-pr-red-500"
                  key={index}
                >
                  <Whitecheckmark className=" absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 stroke-white" />
                </span>
              ) as unknown as Element;

              textSegment = reactStringReplace(
                textSegment,
                "[redcheckbox]",
                (match, i) => redcheckbox
              ) as unknown as Element;
            }

            if (
              typeof textSegment == "string" &&
              textSegment.includes("[graycheckbox]")
            ) {
              const redcheckbox = (
                <span
                  className="relative inline-flex size-5 rounded-full bg-pr-dark-gray-300"
                  key={index}
                >
                  <Whitecheckmark className=" absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 stroke-white" />
                </span>
              ) as unknown as Element;

              textSegment = reactStringReplace(
                textSegment,
                "[graycheckbox]",
                (match, i) => redcheckbox
              ) as unknown as Element;
            }

            if (
              typeof textSegment == "string" &&
              textSegment.includes("[dash]")
            ) {
              const redcheckbox = (
                <span className="relative mx-3 inline-flex" key={index}>
                  <Dash className=" stroke-gray-300" />
                </span>
              ) as unknown as Element;

              textSegment = reactStringReplace(
                textSegment,
                "[dash]",
                (match, i) => redcheckbox
              ) as unknown as Element;
            }

            return [
              ...children,
              index > 0 && (
                <LinkBreak visibility={lineBreakVisibility} key={index} />
              ),
              textSegment
            ];
          },
          []
        );
    },
    ...(options.renderText ? { renderText: options.renderText } : {})
  };
  return (
    <Wrapper {...wrapperProps}>
      {documentToReactComponents(text, finalOptions)}
    </Wrapper>
  );
}

export default RichText;

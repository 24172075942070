import * as React from "react";
import { ProductContainer, ContentfulImage } from "@src/components";
import { IContentfulImage } from "@src/lib/types";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type TrendReportColumnCardsProps = {
  id?: string | null;
  mobileColumns?: string | null;
  items?: TrendReportColumnCardProps[];
};

export type TrendReportColumnCardProps = {
  heading?: string | null;
  headingStyle?: string | null;
  image?: IContentfulImage | null;
  html?: string | null;
  numCols?: number | null;
};

function TrendReportColumnCard({
  heading,
  headingStyle,
  image,
  html,
  numCols
}: TrendReportColumnCardProps) {
  const defaultHeadingStyle: string = "black";

  const headingClassNames: { [key: string]: string } = {
    red: "text-pr-red-500",
    green: "text-pr-green-500",
    yellow: "text-pr-yellow-500",
    teal: "text-pr-teal-500",
    black: "text-pr-dark-gray-500"
  };

  const headingClassName =
    headingClassNames[
      headingStyle
        ? headingStyle.toLowerCase().split(" ").join("")
        : defaultHeadingStyle
    ];

  return (
    <div
      className={`basis-1/2 space-y-10 lg:mx-0 lg:basis-1/3 ${
        numCols && numCols > 3 ? " lg:space-y-8 " : " lg:space-y-12 "
      } `}
    >
      {image && (
        <figure className="relative flex justify-center">
          <img src={image.url || ""} alt={image.description || ""} />
        </figure>
      )}
      <div className="space-y-1 text-center md:space-y-8">
        {heading && (
          <h3 className={`type-pr-delta font-bold ${headingClassName}`}>
            {heading}
          </h3>
        )}
        {html && (
          <div
            className="text-pr-base leading-pr-looser text-pr-dark-gray-500 lg:text-pr-xl"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        )}
      </div>
    </div>
  );
}

export function TrendReportColumnCards({
  mobileColumns,
  items
}: TrendReportColumnCardsProps) {
  const defaultMobileColumns: string = "1column";

  const backgroundClassNames: { [key: string]: string } = {
    "2column": "flex-row flex-wrap items-end",
    "1column": "flex-col mx-auto"
  };

  const mobileColumnsStyle =
    backgroundClassNames[mobileColumns ? mobileColumns : defaultMobileColumns];

  return (
    <ProductContainer>
      {items && (
        <div
          className={`flex ${mobileColumnsStyle} justify-center space-y-10 lg:flex lg:flex-row lg:flex-nowrap lg:items-end lg:justify-between lg:gap-14`}
        >
          {items.map((item, index) => (
            <TrendReportColumnCard
              key={index}
              {...item}
              numCols={items.length}
            />
          ))}
        </div>
      )}
    </ProductContainer>
  );
}

export default TrendReportColumnCards;

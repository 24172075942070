/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import * as React from "react";
import { Service } from "@src/lib/services/server/contentful";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { ProductContainer, ContentfulImage, SmartLink } from "@src/components";
import { IContentfulImage } from "@src/lib/types";
import Chevron from "@/img/icons/chevron.svg";

export type ProductHeroCaseStudyProps = {
  eyebrow?: string | null;
  heading?: string | null;
  text?: string | null;
  image?: IContentfulImage | null;
  count?: number | null;
  year?: string | null;
  location?: string | null;
  type?: string | null;
  heroStyle?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
  items: Service[];
};

export function ProductHeroCaseStudy({
  eyebrow,
  heading,
  text,
  image,
  count,
  year,
  location,
  type,
  heroStyle,
  ctaTitle,
  ctaUrl,
  ctaStyle,
  items
}: ProductHeroCaseStudyProps) {
  const dimensions = {
    width: image?.width || 2120,
    height: image?.height || 1376
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  const renderItems = (items: Service[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          href={contentfulEntryUrl(item)}
          className="pr-button pr-button-sm pr-button-white"
          key={index}
        >
          {item.previewTitle || item.title}
        </SmartLink>
      );
    });
  };

  const returnLineBreaks = (text: string) => {
    if (text.includes("\n")) {
      return text.split("\n").map((innerText: string, i: number) => {
        return (
          <span key={i} className="block">
            {innerText}
          </span>
        );
      });
    } else {
      return text;
    }
  };

  const defaultCtaStyle: string = "red";

  const ctaClassNames: { [key: string]: string } = {
    red: "pr-button-red",
    green: "bg-pr-green-500 text-white",
    yellow: "bg-pr-yellow-500 text-white",
    teal: "bg-pr-teal-500 text-white",
    black: "pr-button-black"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <div className="overflow-hidden bg-pr-yellow-200 pb-8 pt-4 md:pb-24">
      <ProductContainer fullWidth={true}>
        <div className="relative">
          {heroStyle == "motif" && (
            <svg
              viewBox="0 0 1650 567"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute inset-0 -inset-x-7 h-full w-[calc(100%+3.5rem)] md:-inset-x-8 md:w-[calc(100%+4rem)] 2xl:-inset-x-24 2xl:w-[calc(100%+12rem)] 4xl:-inset-x-[6.5rem] 4xl:w-[calc(100%+13rem)]"
            >
              <path
                d="M242.546 142.627L242.546 283.493C168.954 283.493 109.302 220.428 109.302 142.627L242.546 142.627V142.627Z"
                className="fill-pr-dark-gray-700 opacity-[.35]"
              />
              <path
                d="M1398.55 426.122L1398.55 285.256C1472.14 285.256 1531.79 348.321 1531.79 426.122L1398.55 426.122V426.122Z"
                className="fill-pr-green-500 opacity-[.35]"
              />
              <path
                d="M242.664 426.134L242.664 567C168.459 567 108.309 503.935 108.309 426.134L242.664 426.134V426.134Z"
                className="fill-pr-red-400 opacity-[.35]"
              />
              <path
                d="M242.68 426.124L108.325 426.124C108.325 347.674 168.475 284.084 242.68 284.084L242.68 426.124Z"
                className="fill-pr-teal-400 opacity-[.35]"
              />
              <path
                d="M1398.41 142.625L1532.77 142.625C1532.77 221.075 1472.62 284.665 1398.41 284.665L1398.41 142.625Z"
                className="fill-pr-teal-400 opacity-[.35]"
              />
              <path
                d="M109.309 142.617L109.309 0.577142C182.901 0.577146 242.554 64.1675 242.554 142.617L109.309 142.617Z"
                className="fill-pr-green-500 opacity-[.35]"
              />
              <path
                d="M-9.38207e-05 283.507L-9.98619e-05 425.547L133.244 283.507L-9.38207e-05 283.507Z"
                className="fill-pr-green-500 opacity-[.35]"
              />
              <path
                d="M1651.21 -0.000160586L1513 -0.000173004L1651.21 136.938L1651.21 -0.000160586Z"
                className="fill-pr-dark-gray-700 opacity-[.35]"
              />
            </svg>
          )}

          <div className="relative pl-6 pr-[26px] md:pl-[10%] md:pr-[10.5%]">
            <figure className="relative mx-auto w-full">
              <ContentfulImage
                image={image}
                transforms={transforms}
                priority={true}
              />
            </figure>
          </div>
        </div>

        <div className="mx-auto mt-4 w-full max-w-[1168px]">
          <div className="grid lg:grid-cols-4 lg:gap-x-10 xl:gap-x-20">
            {eyebrow && (
              <div className="lg:col-span-full">
                <p className="inline-flex items-center gap-2 text-pr-dark-gray-400">
                  <span className="type-pr-eta md:type-pr-theta">
                    {eyebrow}
                  </span>
                  <Chevron className="h-3 w-2.5 -rotate-90 stroke-current" />
                </p>
              </div>
            )}

            <div className="mt-2 space-y-2 md:space-y-4 lg:col-span-3">
              {heading && (
                <h1 className="type-pr-beta">{returnLineBreaks(heading)}</h1>
              )}
              {text && (
                <div className="type-pr-theta md:type-pr-eta text-pr-dark-gray-400">
                  <p>{returnLineBreaks(text)}</p>
                </div>
              )}
            </div>

            <div className="mt-3.5 md:mt-14 lg:col-span-1 lg:mt-0">
              <div className="type-pr-theta space-y-1 text-pr-dark-gray-400 md:space-y-2">
                {/* @TODO: wire any of these up that are intended to be links */}
                {type && (
                  <dl className="flex gap-1">
                    <dt className="font-bold">Cuisine type:</dt>
                    <dd className="text-pr-red-600">{type}</dd>
                  </dl>
                )}

                {count && (
                  <dl className="flex gap-1">
                    <dt className="font-bold">Locations:</dt>
                    <dd className="text-pr-red-600">{count}</dd>
                  </dl>
                )}

                {year && (
                  <dl className="flex gap-1">
                    <dt className="font-bold">Founded:</dt>
                    <dd className="text-pr-red-600">{year}</dd>
                  </dl>
                )}

                {location && (
                  <dl className="flex gap-1">
                    <dt className="font-bold">City:</dt>
                    <dd className="text-pr-red-600">{location}</dd>
                  </dl>
                )}
              </div>
            </div>

            {items.length > 0 && (
              <div className="mt-8 space-y-3 md:mt-14 lg:col-span-full">
                <p className="font-bold text-pr-dark-gray-400 lg:text-pr-lg">
                  Products Used:
                </p>
                <div className="flex flex-wrap gap-1">{renderItems(items)}</div>
              </div>
            )}

            {ctaUrl && ctaTitle && (
              <div className="mt-8">
                <SmartLink
                  href={ctaUrl}
                  className={`pr-button pr-button-lg ${ctaClassName}`}
                >
                  {ctaTitle}
                </SmartLink>
              </div>
            )}
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductHeroCaseStudy;

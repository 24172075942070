import { HolidayPageTimelineIcons } from "@src/lib/services/server/contentful";
import * as React from "react";

export type HolidayTimelineProps = {
  activeSection: number;
  timelineIcons: HolidayPageTimelineIcons;
};

const HolidayTimeline: React.FC<HolidayTimelineProps> = ({
  activeSection,
  timelineIcons
}) => {
  const activeIconStyle = "bg-pr-teal-600";
  const completeIconStyle = "bg-ada-blue-200";
  const iconStyle = "bg-pr-light-gray-100";

  const getIconClassName = (index: number) => {
    if (activeSection - 1 === index) return activeIconStyle;
    if (activeSection > index) return completeIconStyle;
    return iconStyle;
  };

  const activeImageStyle = "brightness-0 invert contrast-100";
  const completeImageStyle = "brightness-100 invert-0 contrast-100";
  const nextImageStyle = "brightness-0 invert contrast-50";

  const getImageClassName = (index: number) => {
    if (activeSection - 1 === index) return activeImageStyle;
    if (activeSection > index) return completeImageStyle;
    return nextImageStyle;
  };

  return (
    <div className="fixed right-3 top-1/2 z-10 flex -translate-y-1/2 flex-col gap-y-4 rounded-full md:right-5 md:gap-y-2">
      {timelineIcons.items.map((icon, index) => (
        <a
          key={icon?.title}
          href={`#${(icon?.title ?? "")
            .toLowerCase()
            .replace(/[\s-]+/g, "-")
            .replace(/-?icon$/, "")}`}
          className={`z-10 flex size-11 rounded-full p-1 transition-all duration-300 ease-in md:size-14 ${getIconClassName(
            index
          )}`}
        >
          <img
            src={icon?.url ?? ""}
            alt={icon?.title ?? ""}
            width={100}
            height={100}
            className={`transition-all duration-300 ease-in ${getImageClassName(
              index
            )}`}
          />
        </a>
      ))}
    </div>
  );
};

export { HolidayTimeline };

import React, { forwardRef, HTMLProps } from "react";
import { HolidayTimelineModules } from "@src/components";
import { useHolidayContent } from "@src/context/HolidayContentContext";
import { HolidayPageContentModule } from "@src/lib/services/server/contentful";

export type HolidayTimelineSectionProps = HTMLProps<HTMLDivElement> & {
  index: number;
};

const HolidayTimelineSection = forwardRef<
  HTMLDivElement,
  HolidayTimelineSectionProps
>(({ id, index }, ref) => {
  const holidayContent = useHolidayContent();

  const sectionData = [
    { id: "pre-plan", content: holidayContent.prePlan?.items },
    { id: "build-out", content: holidayContent.buildOut?.items },
    { id: "generate-revenue", content: holidayContent.generateRevenue?.items },
    { id: "enjoy", content: holidayContent.enjoy?.items }
  ];

  const currentSection = sectionData[index];

  return (
    <section id={id} ref={ref} className="hide-section">
      <HolidayTimelineModules
        key={currentSection.id}
        id={currentSection.id}
        index={index}
        contentModules={currentSection.content as HolidayPageContentModule[]}
      />
    </section>
  );
});

HolidayTimelineSection.displayName = "HolidayTimelineSection";

export { HolidayTimelineSection };

import * as React from "react";
import { SmartLink, BlogContainer } from "@src/components";
import Arrow from "@/img/icons/arrow.svg";
import { useRouter } from "next/router";

type PaginationProps = {
  url: string;
  total: number;
  limit: number;
  skip: number;
  useQueryString?: boolean;
  border?: boolean;
};

type PageInfo = {
  page: number;
  url: string;
  pathname: string;
  query: { [key: string]: string };
};

const getPageInfo = (
  url: string,
  page: number,
  useQueryString?: boolean
): PageInfo => {
  if (useQueryString) {
    const pathname = url.includes("?") ? url.split("?", 2)[0] : url;
    const queryString = url.includes("?") ? url.split("?", 2)[1] : "";
    const searchParams = new URLSearchParams(queryString);
    searchParams.delete("page");
    if (page > 1) {
      searchParams.set("page", String(page));
    }
    const query: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      query[key] = value;
    });
    const newQueryString = searchParams.toString();
    return {
      page,
      url: `${pathname}${newQueryString && "?"}${newQueryString}`,
      pathname,
      query
    };
  }
  const newUrl = `${url}page/${page}`;
  return {
    page,
    url: newUrl,
    pathname: newUrl,
    query: {}
  };
};

export function Pagination({
  url,
  total,
  limit,
  skip,
  useQueryString,
  border = true
}: PaginationProps) {
  const router = useRouter();
  const buttonClassNames: { [key: string]: string } = {
    enabled:
      "bg-red-600 transition text-white hover:border-red-700 hover:bg-red-700",
    disabled: "bg-gray-100 text-gray-300 pointer-events-none"
  };

  const numberClassNames: { [key: string]: string } = {
    enabled: "text-red-500 transition hover:text-red-700 focus:text-red-700",
    disabled: "font-bold"
  };

  const pageCount: number = Math.ceil(total / limit);
  const currentPage: number = skip / limit + 1;
  const isFirstPage: boolean = currentPage === 1;
  const isLastPage: boolean = currentPage === pageCount;

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>, pageInfo: PageInfo) => {
      if (useQueryString) {
        event.preventDefault();

        void router.push(
          {
            pathname: pageInfo.pathname,
            query: pageInfo.query
          },
          pageInfo.url,
          { shallow: true }
        );
      }
    },
    [router, useQueryString]
  );

  const generatePageRange = (
    currentPage: number,
    pageCount: number,
    delta: number = 2
  ) => {
    const range = Array(pageCount)
      .fill(0)
      .map((_, index) => index + 1);

    return range.reduce((pages: number[], page: number) => {
      if (page === 1 || page === pageCount) {
        return [...pages, page];
      }

      if (page - delta <= currentPage && page + delta >= currentPage) {
        return [...pages, page];
      }

      if (pages[pages.length - 1] !== -1) {
        return [...pages, -1];
      }

      return pages;
    }, []);
  };

  const renderPages = () => {
    const pageRange = generatePageRange(currentPage, pageCount);
    return pageRange.map((page: number, index: number) => {
      if (page !== -1) {
        const pageInfo = getPageInfo(url, page, useQueryString);
        return (
          <li key={index}>
            <SmartLink
              href={pageInfo.url}
              className={`${
                page === currentPage
                  ? numberClassNames.disabled
                  : numberClassNames.enabled
              }`}
              onClick={event => onClick(event, pageInfo)}
            >
              {page}
            </SmartLink>
          </li>
        );
      } else {
        return (
          <li key={index}>
            <span className="text-red-500">...</span>
          </li>
        );
      }
    });
  };

  const renderPrevious = () => {
    const pageInfo = getPageInfo(url, currentPage - 1, useQueryString);
    const prevPageUrl: string = isFirstPage ? url : pageInfo.url;
    return (
      <div>
        <SmartLink
          href={prevPageUrl}
          className={`flex items-center gap-3 rounded-full px-6 py-4 ${
            isFirstPage ? buttonClassNames.disabled : buttonClassNames.enabled
          }`}
          onClick={event => onClick(event, pageInfo)}
        >
          <Arrow className="h-3 w-5 rotate-180 fill-current" />
          <span className="hidden lg:block">Previous</span>
        </SmartLink>
      </div>
    );
  };

  const renderNext = () => {
    const pageInfo = isLastPage
      ? getPageInfo(url, pageCount, useQueryString)
      : getPageInfo(url, currentPage + 1, useQueryString);
    const nextPageUrl = pageInfo.url;
    return (
      <div>
        <SmartLink
          href={nextPageUrl}
          className={`flex items-center gap-3 rounded-full px-6 py-4 ${
            isLastPage ? buttonClassNames.disabled : buttonClassNames.enabled
          }`}
          onClick={event => onClick(event, pageInfo)}
        >
          <span className="hidden lg:block">Next</span>
          <Arrow className="h-3 w-5 fill-current" />
        </SmartLink>
      </div>
    );
  };

  return (
    <section>
      <BlogContainer>
        <div
          className={`py-10 md:py-16 ${
            border ? "border-t border-gray-200" : ""
          }`}
        >
          <nav className="flex items-center justify-center space-x-4 text-xl sm:space-x-8 lg:text-[20px]">
            {renderPrevious()}
            <ul className="flex items-center space-x-4 sm:space-x-8">
              {renderPages()}
            </ul>
            {renderNext()}
          </nav>
        </div>
      </BlogContainer>
    </section>
  );
}

export default Pagination;

import * as React from "react";
import { ProductContainer, SmartLink } from "@src/components";

export type ProductHeroPricingProps = {
  heading?: string | null;
  text?: string | null;
  heroStyle?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return <span key={i}>{text}</span>;
    });
  } else {
    return text;
  }
};

export function ProductHeroPricing({
  heading,
  text,
  heroStyle,
  ctaTitle,
  ctaUrl,
  ctaStyle
}: ProductHeroPricingProps) {
  const defaultCtaStyle: string = "red";

  const ctaClassNames: { [key: string]: string } = {
    red: "pr-button-red",
    green: "bg-pr-green-500 text-white",
    yellow: "bg-pr-yellow-500 text-white",
    teal: "bg-pr-teal-500 text-white",
    black: "pr-button-black"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <div className="mb-6 overflow-hidden pt-6 md:mb-20 md:pt-20">
      {heroStyle == "motif" && (
        <div className="relative">
          <svg
            viewBox="0 0 1650 567"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-0 top-0"
          >
            <path
              d="M1528 29C1596.49 29 1652 85.4094 1652 155H1528V29Z"
              className="fill-[#FCD2C9]"
            />
            <path
              d="M1528 279C1562.78 279 1591 251.233 1591 217C1591 182.767 1562.78 155 1528 155L1528 279Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M125 91.9999V155H0V91.9999C0 57.2152 27.9808 29 62.4764 29C97.019 29 125 57.1679 125 91.9999Z"
              className="fill-[#FCD2C9] opacity-[.35]"
            />
          </svg>
        </div>
      )}
      <ProductContainer>
        <div className="flex flex-col items-center gap-4 text-center md:gap-8">
          <div className="relative space-y-8">
            <div className="max-w-[902px] space-y-4 md:space-y-8">
              {heading && (
                <h1 className="type-pr-alpha">{returnLineBreaks(heading)}</h1>
              )}
              {text && (
                <div className="type-pr-zeta lg:text-pr-4xl">
                  <p>{returnLineBreaks(text)}</p>
                </div>
              )}
            </div>
            {ctaUrl && ctaTitle && (
              <SmartLink
                href={ctaUrl}
                className={`pr-button pr-button-lg ${ctaClassName}`}
              >
                {ctaTitle}
              </SmartLink>
            )}
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductHeroPricing;

import * as React from "react";
import type { AssetsByIdQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { Document } from "@contentful/rich-text-types";
import { RichText } from "@src/components";

export type RichTextBlockProps = {
  text: Document;
  richTextAssets?: AssetsByIdQuery;
};

export function RichTextBlock({ text, richTextAssets }: RichTextBlockProps) {
  return (
    <div className="my-8 md:my-16">
      <div className="prose mx-auto md:prose-lg">
        <RichText text={text} richTextAssets={richTextAssets} />
      </div>
    </div>
  );
}

export default RichTextBlock;

import * as React from "react";

type ConditionalWrapProps = {
  children: React.ReactNode;
  condition: boolean;
  wrap: (children: React.ReactNode) => React.ReactElement;
};

export function ConditionalWrap({
  condition,
  wrap,
  children
}: ConditionalWrapProps): React.ReactElement {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return condition ? wrap(children) : <>{children}</>;
}

export default ConditionalWrap;

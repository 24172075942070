import * as React from "react";
import TwitterIcon from "@/img/icons/twitter.svg";
import FacebookIcon from "@/img/icons/facebook.svg";
import LinkedinIcon from "@/img/icons/linkedin.svg";
import TelegramIcon from "@/img/icons/telegram.svg";

import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton
} from "react-share";

export type SocialSharingProps = {
  entryTitle?: string | null;
  entryUrl: string;
};

export function SocialSharing({ entryTitle, entryUrl }: SocialSharingProps) {
  return (
    <div>
      {entryTitle && (
        <ul className="flex flex-wrap justify-center gap-7 md:justify-start md:gap-2.5">
          <li>
            <TwitterShareButton
              url={entryUrl}
              title={entryTitle}
              resetButtonStyle={false}
              className="p-1 text-red-500 transition hover:text-red-700 focus:text-red-700"
            >
              <TwitterIcon className="h-6 w-6 fill-current md:h-4 md:w-4" />
            </TwitterShareButton>
          </li>
          <li>
            <FacebookShareButton
              url={entryUrl}
              resetButtonStyle={false}
              className="p-1 text-red-500 transition hover:text-red-700 focus:text-red-700"
            >
              <FacebookIcon className="h-6 w-6 fill-current md:h-4 md:w-4" />
            </FacebookShareButton>
          </li>
          <li>
            <LinkedinShareButton
              url={entryUrl}
              resetButtonStyle={false}
              title={entryTitle}
              className="p-1 text-red-500 transition hover:text-red-700 focus:text-red-700"
            >
              <LinkedinIcon className="h-6 w-6 fill-current md:h-4 md:w-4" />
            </LinkedinShareButton>
          </li>
          <li>
            <TelegramShareButton
              url={entryUrl}
              resetButtonStyle={false}
              title={entryTitle}
              className="p-1 text-red-500 transition hover:text-red-700 focus:text-red-700"
            >
              <TelegramIcon className="h-6 w-6 fill-current md:h-4 md:w-4" />
            </TelegramShareButton>
          </li>
        </ul>
      )}
    </div>
  );
}

export default SocialSharing;

/* eslint-disable @typescript-eslint/no-explicit-any */
export * from "./config";
export * from "./contentful";

export type IContentfulImage = {
  url?: string | null;
  description?: string | null;
  height?: number | null;
  width?: number | null;
};

export type RichTextJson = {
  nodeType: string;
  content: RichTextJsonContent;
};

export type RichTextJsonContent = [
  {
    nodeType: string;
    data?: {
      target: {
        sys: {
          id: string;
          type: string;
          linkType: string;
        };
      };
    };
  }
];

export interface MktoForm {
  getId(): number;
}

declare global {
  interface Window {
    dataLayer?: any;
    MktoForms2?: {
      _formIds?: string[];
      loadForm(
        baseUrl: string,
        munchkinId: string,
        formId: string,
        callback?: (form: MktoForm) => void
      ): void;
      lightbox(
        formId: string,
        options?: {
          onSuccess?: VoidFunction;
          closeBtn?: boolean;
        }
      ): void;
    };
  }

  const mktoForms2BaseStyle: Element | ProcessingInstruction | null;
  const mktoForms2ThemeStyle: Element | ProcessingInstruction | null;
}

export interface Calendly {
  initPopupWidget: (options: CalendlyPopupWidgetOptions) => void;
  initInlineWidget: (options: CalendlyPopupWidgetOptions) => void;
}

export interface CalendlyUtm {
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  utmTerm: string;
}

export interface CalendlyPopupWidgetOptions {
  url: string;
  utm?: Partial<CalendlyUtm>;
}

declare global {
  interface Window {
    Calendly?: Calendly;
  }
}

import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import { contentfulEntryUrl, filterNull, formatDate } from "@src/lib/utils";
import {
  BlogPost,
  CtaLimitedType,
  CtaBlogSubscriptionType,
  CtaDemoRequestType,
  CtaFormType
} from "@src/lib/services/server/contentful";
import {
  CardGrid,
  Cta,
  CtaForm,
  SectionHeading,
  BlogContainer
} from "@src/components";

export type GridProps = {
  sectionHeading?: string | null;
  displayEyebrow?: string | null;
  items: BlogPost[];
  cta?:
    | CtaLimitedType
    | CtaBlogSubscriptionType
    | CtaDemoRequestType
    | CtaFormType;
  responsive?: boolean;
  borderTop?: boolean;
  paddingBottom?: "pb-11" | "pb-5 md:pb-14 lg:pb-28";
  shortContent?: boolean;
  linkTag?: boolean;
};

export function Grid({
  sectionHeading,
  displayEyebrow,
  items,
  cta,
  responsive = false,
  borderTop = false,
  paddingBottom = "pb-5 md:pb-14 lg:pb-28",
  shortContent = false,
  linkTag = false
}: GridProps) {
  const renderCta = (
    cta:
      | CtaLimitedType
      | CtaBlogSubscriptionType
      | CtaDemoRequestType
      | CtaFormType
  ) => {
    switch (cta.__typename) {
      case "CtaDemoRequest":
        return (
          <CtaForm
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            formKey={cta.__typename.toLowerCase()}
            centeredEyebrow={true}
            headingStyle={cta.headingStyle?.toLowerCase()}
          />
        );
      case "CtaBlogSubscription":
        return (
          <CtaForm
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            formKey={cta.__typename.toLowerCase()}
            centeredEyebrow={true}
            headingStyle={cta.headingStyle?.toLowerCase()}
          />
        );
      case "CtaForm":
        return (
          <CtaForm
            formId={cta.marketoFormId?.trim()}
            formTheme={cta.formTheme?.toLowerCase().split(" ").join("")}
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            formKey={cta.__typename.toLowerCase()}
            centeredEyebrow={true}
            headingStyle={cta.headingStyle?.toLowerCase()}
            finePrint={cta.finePrint?.json as Document | undefined}
          />
        );
      // defaults to CtaLimitedType:
      default:
        return (
          <Cta
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            ctaTitle={cta.ctaTitle}
            ctaUrl={cta.ctaUrl ? contentfulEntryUrl(cta.ctaUrl) : null}
            ctaExternalUrl={cta.ctaExternalUrl}
            theme={cta.theme?.toLowerCase()}
            centeredEyebrow={true}
            headingStyle={cta.headingStyle?.toLowerCase()}
          />
        );
    }
  };

  const renderItems = (items: BlogPost[]) => {
    return items.map((item, index) => {
      const topics = item.topicsCollection?.items;
      const eyebrow = topics ? topics[0]?.title : null;

      if (cta && index === 1) {
        return (
          <React.Fragment key={item.sys.id}>
            <CardGrid
              responsive={true}
              shortContent={shortContent}
              url={contentfulEntryUrl(item)}
              media={item.previewImage || item.leadImage}
              eyebrow={displayEyebrow || eyebrow}
              heading={item.previewTitle || item.title}
              meta={formatDate(item.date)}
              text={
                item.previewDescription
                  ? item.previewDescription
                  : item.description
              }
              linkTag={linkTag}
            />
            <div className="-mx-5 md:mx-0">{renderCta(cta)}</div>
          </React.Fragment>
        );
      } else if (responsive && index > 0) {
        return (
          <CardGrid
            key={item.sys.id}
            responsive={true}
            shortContent={shortContent}
            url={contentfulEntryUrl(item)}
            media={item.previewImage || item.leadImage}
            eyebrow={displayEyebrow || eyebrow}
            heading={item.previewTitle || item.title}
            meta={formatDate(item.date)}
            text={
              item.previewDescription
                ? item.previewDescription
                : item.description
            }
            linkTag={linkTag}
          />
        );
      } else {
        return (
          <CardGrid
            key={item.sys.id}
            shortContent={shortContent}
            url={contentfulEntryUrl(item)}
            media={item.previewImage || item.leadImage}
            eyebrow={displayEyebrow || eyebrow}
            heading={item.previewTitle || item.title}
            meta={formatDate(item.date)}
            text={
              item.previewDescription
                ? item.previewDescription
                : item.description
            }
            linkTag={linkTag}
          />
        );
      }
    });
  };

  return (
    <section>
      <BlogContainer>
        <div className={paddingBottom}>
          {sectionHeading && (
            <SectionHeading heading={sectionHeading} borderTop={borderTop} />
          )}
          <div className="-mt-5 divide-y divide-gray-200 md:-mt-0 md:grid md:grid-cols-3 md:gap-x-5 md:gap-y-10 md:divide-y-0 md:border-0 lg:gap-x-10 lg:gap-y-20">
            {renderItems(items)}
          </div>
        </div>
      </BlogContainer>
    </section>
  );
}

export default Grid;

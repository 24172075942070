import * as React from "react";
import { Service } from "@src/lib/services/server/contentful";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { ProductContainer, ContentfulImage, SmartLink } from "@src/components";
import { IContentfulImage } from "@src/lib/types";

export type ProductHeroPillarProps = {
  heading?: string | null;
  text?: string | null;
  image?: IContentfulImage | null;
  icon?: IContentfulImage | null;
  heroStyle?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
  items: Service[];
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductHeroPillar({
  heading,
  text,
  image,
  icon,
  heroStyle,
  ctaTitle,
  ctaUrl,
  ctaStyle,
  items
}: ProductHeroPillarProps) {
  const imageDimensions = {
    width: 894,
    height: 1194
  };

  const imageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: imageDimensions.width,
    height: imageDimensions.height
  };

  const defaultHeroStyle: string = "redmotif";

  // const iconDimensions = {
  //   width: 140,
  //   height: 140,
  // };

  // const iconTransforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: iconDimensions.width,
  //   height: iconDimensions.height,
  // };

  const defaultCtaStyle: string = "red";

  const ctaClassNames: { [key: string]: string } = {
    red: "pr-button-red",
    green: "bg-pr-green-500 text-white",
    yellow: "bg-pr-yellow-500 text-white",
    teal: "bg-pr-teal-500 text-white",
    black: "pr-button-black"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  const renderItems = (items: Service[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          href={contentfulEntryUrl(item)}
          className="pr-button pr-button-sm pr-button-white"
          key={index}
        >
          {item.previewTitle || item.title}
        </SmartLink>
      );
    });
  };

  const renderImage = (
    image: IContentfulImage | null | undefined,
    heroStyle: string | null | undefined
  ) => {
    switch (heroStyle) {
      case "redmotif":
        return (
          <div className="lg:pl-[25%] lg:pr-[4%]">
            <div className="flex items-end">
              <div className="relative w-[22%]">
                <svg
                  viewBox="0 0 194 192"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute bottom-0 left-0 w-[166%]"
                >
                  <path
                    d="M.586.266c106.595 0 193 85.51 193 191h-193v-191Z"
                    className="fill-[#F8AE9F]"
                  />
                </svg>
              </div>
              <div className="w-[78%]">
                <figure className="relative aspect-[390/604]">
                  <ContentfulImage
                    image={image}
                    transforms={imageTransforms}
                    layout="fill"
                    className="redmotif object-cover"
                    priority={true}
                  />
                </figure>
              </div>
            </div>
          </div>
        );
      case "greenmotif":
        return (
          <div className="flex">
            <div className="w-4/5">
              <figure className="relative aspect-square">
                <ContentfulImage
                  image={image}
                  transforms={imageTransforms}
                  layout="fill"
                  className="greenmotif object-cover"
                  priority={true}
                />
              </figure>
            </div>
            <div className="relative w-1/5">
              <svg
                viewBox="0 0 190 190"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute right-0 top-0 w-[120%]"
              >
                <path
                  d="M94.846 190c-52.29-.172-94.68-42.702-94.68-94.992V.23l189.361.623v94.777c0 52.29-42.39 94.541-94.68 94.369Z"
                  className="fill-pr-green-500 opacity-[.45]"
                />
              </svg>
            </div>
          </div>
        );
      case "yellowmotif":
        return (
          <div className="lg:px-[10%]">
            <div className="relative">
              <figure className="relative aspect-square">
                <ContentfulImage
                  image={image}
                  transforms={imageTransforms}
                  layout="fill"
                  className="yellowmotif object-cover"
                  priority={true}
                />
              </figure>
              <svg
                viewBox="0 0 279 279"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 left-0 w-1/2"
              >
                <path
                  d="M278.271 0v278.212L.058 0h278.213Z"
                  className="fill-[#FEE3A9]"
                />
              </svg>
            </div>
          </div>
        );
      case "tealmotif":
        return (
          <div className="flex items-end gap-5">
            <svg
              viewBox="0 0 201 201"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-1/3"
            >
              <path
                d="M200.664 200.425V.687L.632 1.337c0 110.311 89.72 199.446 200.032 199.088Z"
                className="fill-pr-teal-500 opacity-[.45]"
              />
            </svg>
            <div className="w-2/3">
              <figure className="relative aspect-[894/1194]">
                <ContentfulImage
                  image={image}
                  transforms={imageTransforms}
                  layout="fill"
                  className="tealmotif object-cover"
                  priority={true}
                />
              </figure>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="bg-pr-yellow-200 py-8 md:pt-24">
      {/* svg for red motif clip path */}
      <svg width="0" height="0">
        <defs>
          <clipPath id="redmotif" clipPathUnits="objectBoundingBox">
            <path
              transform="scale(0.00255102, 0.001655629)"
              d="M195.787 0.466254C87.8805 0.723819 0.40479 88.4085 0.404797 196.316L0.404803 302.466C0.404812 468.999 135.406 603.678 301.938 603.28L391.17 603.067L391.17 195.383C391.17 87.476 303.694 0.208689 195.787 0.466254Z"
            />
          </clipPath>
        </defs>
      </svg>

      {/* svg for green motif clip path */}
      <svg width="0" height="0">
        <defs>
          <clipPath id="greenmotif" clipPathUnits="objectBoundingBox">
            <path
              transform="scale(0.001801802, 0.001801802)"
              d="M0.66407 278.219C0.664066 124.707 125.11 0.260742 278.623 0.260742V0.260742C432.135 0.260742 556.581 124.707 556.581 278.219L556.581 556.178L278.623 556.178C125.11 556.178 0.664075 431.732 0.66407 278.219V278.219Z"
            />
          </clipPath>
        </defs>
      </svg>

      {/* svg for yellow motif clip path */}
      <svg width="0" height="0">
        <defs>
          <clipPath id="yellowmotif" clipPathUnits="objectBoundingBox">
            <path
              transform="scale(0.001760563, 0.001760563)"
              d="M568 0L568 567.836L0.164062 5.87642e-05L568 0Z"
            />
          </clipPath>
        </defs>
      </svg>

      {/* svg for teal motif clip path */}
      <svg width="0" height="0">
        <defs>
          <clipPath id="tealmotif" clipPathUnits="objectBoundingBox">
            <path
              transform="scale(0.002232143, 0.001672241)"
              d="M149.282.427C313.805.9 447.177 134.654 447.177 299.177c0 164.524-133.372 297.514-297.895 297.042L.177 595.792V0l149.105.427Z"
            />
          </clipPath>
        </defs>
      </svg>

      <ProductContainer>
        <div className="grid md:grid-cols-2">
          <div className="space-y-4 md:space-y-12 md:pl-[5%] md:pr-[12%] lg:space-y-14">
            <div className="space-y-6 lg:space-y-7">
              {icon && (
                <figure className="relative h-10 w-10 lg:h-16 lg:w-16">
                  <ContentfulImage
                    image={icon}
                    layout="fill"
                    className="object-contain"
                    priority={true}
                  />
                </figure>
              )}
              <h1 className="type-pr-alpha">{heading}</h1>
              <div className="md:hidden">{renderImage(image, heroStyle)}</div>
              {text && (
                <div className="type-pr-zeta lg:text-pr-4xl">
                  <p>{returnLineBreaks(text)}</p>
                </div>
              )}
            </div>

            <div>
              {items.length > 0 && (
                <div className="flex flex-wrap gap-2.5">
                  {renderItems(items)}
                </div>
              )}
            </div>

            {ctaUrl && ctaTitle && (
              <SmartLink
                href={ctaUrl}
                className={`pr-button pr-button-lg ${ctaClassName}`}
              >
                {ctaTitle}
              </SmartLink>
            )}
          </div>
          <div className="hidden md:block">{renderImage(image, heroStyle)}</div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductHeroPillar;

import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import {
  contentfulEntryUrl,
  formatDate,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { BlogPost } from "@src/lib/services/server/contentful";
import {
  SmartLink,
  CardHero,
  BlogContainer,
  ContentfulImage
} from "@src/components";

export type HeroLandingProps = {
  url: string;
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  meta?: string;
  text?: string | null;
  items: BlogPost[];
  borderTop?: boolean;
};

export function HeroLanding({
  url,
  media,
  eyebrow,
  heading,
  meta,
  text,
  items,
  borderTop = false
}: HeroLandingProps) {
  const dimensions = {
    width: Math.round(1486 / 1.5),
    height: Math.round(892 / 1.5)
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  const renderItems = (items: BlogPost[]) => {
    return items.map((item, index) => {
      if (index <= 3) {
        const topics = item.topicsCollection?.items;
        const eyebrow = topics ? topics[0]?.title : null;

        return (
          <CardHero
            key={item.sys.id}
            url={contentfulEntryUrl(item)}
            media={item.previewImage || item.leadImage}
            eyebrow={eyebrow}
            heading={item.previewTitle || item.title}
            meta={formatDate(item.date)}
          />
        );
      }
    });
  };

  return (
    <section>
      <BlogContainer>
        <div
          className={`divide-y divide-gray-200 pb-5 pt-6 md:grid md:grid-cols-5 md:divide-x md:divide-y-0 md:pb-14 md:pt-9 lg:pb-28 ${
            borderTop ? "border-t border-red-500" : ""
          }`}
        >
          <div className="pb-5 md:col-span-3 md:pb-0 md:pr-2.5 lg:pr-5">
            <SmartLink href={url}>
              <figure className="aspect-[1486/892]">
                <ContentfulImage
                  image={media}
                  transforms={transforms}
                  priority={true}
                />
              </figure>
            </SmartLink>

            <div className="mt-4 md:mt-5 md:text-center">
              <p className="type-eta text-red-600">{eyebrow}</p>
              <SmartLink href={url} className="group mt-3 block md:mt-1">
                <h2 className="type-alpha link-underline-animated-lg">
                  {heading}
                </h2>
              </SmartLink>
              {meta && (
                <p className="type-zeta mt-2 text-blue-200 md:mt-4">{meta}</p>
              )}
              <p className="mt-2 max-w-prose text-gray-400 md:mx-auto">
                {text}
              </p>
            </div>
          </div>
          <div className="divide-y divide-gray-200 md:col-span-2 md:pl-2.5 lg:pl-5">
            {renderItems(items)}
          </div>
        </div>
      </BlogContainer>
    </section>
  );
}

export default HeroLanding;

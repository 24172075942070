/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
/* eslint-disable tailwindcss/no-custom-classname */
import * as React from "react";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Disclosure, Transition } from "@headlessui/react";
import { Card, ComparisonTable } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, SmartLink, RichText } from "@src/components";
import "swiper/css";
import "swiper/css/pagination";

export type ProductTransparentCardsBlockProps = {
  id?: string | null;
  heading?: string | null;
  text?: Document;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  comparisonTableCtaTitle?: string | null;
  table: ComparisonTable;
  items: Card[];
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductTransparentCardsBlock({
  id = "",
  heading,
  text,
  ctaTitle,
  ctaUrl,
  comparisonTableCtaTitle,
  table,
  items
}: ProductTransparentCardsBlockProps) {
  const paginationClassName: string = `pagination-${id as string}`;
  const tableHeading = table ? table.heading : null;
  const tableText = table ? (table.text?.json as Document | undefined) : null;
  const tableOrientation =
    table && table.orientation
      ? table.orientation.toLowerCase().split(" ").join("")
      : null;

  const renderItems = (items: Card[]) => {
    return items.map((item, index) => {
      const priceDetails = item.priceDetails?.json as Document | undefined;
      const description = item.description?.json as Document | undefined;
      const details = item.details?.json as Document | undefined;
      return (
        <SwiperSlide
          key={index}
          className={`flex !h-auto border-r-[1px] border-[#0000004f] px-6 py-5 last:border-0 lg:col-span-1 lg:!w-full lg:px-12
            ${
              index == 0
                ? "w-[296px!important] pl-5 md:w-[346px!important] lg:!w-full lg:pl-12"
                : ""
            }
            ${
              index == items.length - 1
                ? "w-[296px!important] pr-5 md:w-[346px!important] lg:!w-full lg:pr-12"
                : ""
            }
            ${
              index !== 0 && index != items.length - 1
                ? "w-[276px!important] md:w-[314px!important] lg:!w-full"
                : ""
            }
            ${(index + 1) % 3 === 0 ? " !border-0 " : ""}
          `}
        >
          <div className={`flex w-full flex-col ${item.eyebrow ? "" : "mt-5"}`}>
            {item.eyebrow && (
              <div className={`flex h-5 items-center py-2 text-pr-teal-600`}>
                <span className="text-pr-sm font-bold uppercase">
                  {item.eyebrow}
                </span>
              </div>
            )}

            <div className="flex grow flex-col space-y-4 py-2 lg:space-y-5 lg:pt-2">
              {item.heading && (
                <h3 className="type-pr-delta text-pr-5xl leading-6">
                  {returnLineBreaks(item.heading)}
                </h3>
              )}

              <div className="">
                {item.previousPrice && (
                  <div className="type-pr-zeta flex flex-row gap-3 text-[17px] text-pr-dark-gray-500">
                    <span className="strikethrough-card">
                      ${item.previousPrice}
                    </span>
                    {item.percentOff && <div>{item.percentOff}</div>}
                  </div>
                )}

                {priceDetails && (
                  <div className="card-details card-details-price">
                    <RichText text={priceDetails} />
                  </div>
                )}
              </div>

              {item.primaryCtaUrl && item.primaryCtaTitle && (
                <div className="">
                  <SmartLink
                    href={item.primaryCtaUrl}
                    className={"pr-button pr-button-lg pr-button-teal"}
                  >
                    {item.primaryCtaTitle}
                  </SmartLink>
                </div>
              )}

              {description && (
                <div>
                  <div className="text-pr-gray-500 text-[15px] leading-pr-loose">
                    <RichText text={description} />
                  </div>
                </div>
              )}

              {details && (
                <div
                  className={`pt-4 lg:pt-5 ${
                    description ? "border-t-[1px] border-[#0000004f]" : ""
                  } `}
                >
                  <div className="card-details-details text-pr-gray-500 text-[15px]">
                    <RichText text={details} />
                  </div>
                </div>
              )}

              {item.secondaryCtaUrl && item.secondaryCtaTitle && (
                <div className="">
                  <SmartLink
                    href={item.secondaryCtaUrl}
                    className={
                      "type-pr-zeta link-underline-animated-sm text-pr-lg text-pr-red-500"
                    }
                  >
                    {item.secondaryCtaTitle}
                  </SmartLink>
                </div>
              )}
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="bg-pr-yellow-200 pb-8 pt-[60px] md:py-16">
      <ProductContainer>
        <div className="space-y-6 lg:space-y-12">
          <div className="mx-auto max-w-[812px] space-y-4 text-center">
            {heading && (
              <h2 className="type-pr-beta">{returnLineBreaks(heading)}</h2>
            )}
            {text && (
              <div className="type-pr-eta">
                <RichText text={text} />
              </div>
            )}
          </div>

          <div className="-mx-5 divide-x-2 lg:mx-6 xl:mx-12">
            <Swiper
              modules={[Pagination, A11y]}
              spaceBetween={16}
              slidesPerView={"auto"}
              pagination={{
                clickable: true,
                bulletActiveClass: "swiper-pagination-bullet-active-product",
                bulletClass: "swiper-pagination-bullet-product",
                el: `.${paginationClassName}`
              }}
              centerInsufficientSlides={true}
              breakpoints={{
                1280: {
                  allowTouchMove: false
                }
              }}
              className="swiper-cards-grid"
            >
              {renderItems(items)}
            </Swiper>
            <div
              className={`mt-4 flex flex-wrap items-center justify-center gap-2.5 px-24 py-4 xl:hidden ${paginationClassName}`}
            ></div>
          </div>
        </div>

        {ctaUrl && ctaTitle && (
          <div className="mt-10 flex justify-center">
            <SmartLink
              href={ctaUrl}
              className={"pr-button pr-button-lg pr-button-teal"}
            >
              {ctaTitle}
            </SmartLink>
          </div>
        )}

        {comparisonTableCtaTitle && tableText && (
          <div className="mt-10 lg:mt-20">
            <Disclosure>
              <div className="flex justify-center">
                <Disclosure.Button className="pr-button pr-button-lg pr-button-teal">
                  {comparisonTableCtaTitle}
                </Disclosure.Button>
              </div>
              <Transition className="mt-20 overflow-hidden px-5 py-8 md:py-16 lg:px-8">
                <Disclosure.Panel>
                  <div className="drop-shadow-lg">
                    {tableHeading && (
                      <h2 className="rounded-t-3xl bg-pr-dark-gray-500 py-5 text-center text-xl text-white opacity-[83%]">
                        {returnLineBreaks(tableHeading)}
                      </h2>
                    )}
                    {tableText && (
                      <div
                        className={`comparison-table block overflow-x-auto rounded-b-3xl ${
                          tableOrientation == "center"
                            ? "comparison-table-centered"
                            : ""
                        }`}
                      >
                        <RichText text={tableText} />
                      </div>
                    )}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </Disclosure>
          </div>
        )}
      </ProductContainer>
    </div>
  );
}

export default ProductTransparentCardsBlock;

import * as React from "react";

export type ButtonProps = {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  ariaControls?: string;
  ariaLabel?: string;
  ariaExpanded?: boolean;
};

export function Button({
  className,
  children,
  onClick,
  ariaControls,
  ariaLabel,
  ariaExpanded
}: ButtonProps) {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-controls={ariaControls}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
    >
      {children}
    </button>
  );
}

export default Button;

import React, { useRef } from "react";
import Plyr, { APITypes } from "plyr-react";
import "plyr-react/dist/plyr.css";
import getVideoId from "get-video-id";
import { Document } from "@contentful/rich-text-types";
import { RichText } from "@src/components";
import { PlyrVideo } from "../PlyrVideo";

export type VideoBlockProps = {
  video?: string | null;
  caption?: Document;
};

export function VideoBlock({ video, caption }: VideoBlockProps) {
  return (
    <div className="my-8 md:my-16">
      <div className="xl:-mx-10">
        <figure>
          <PlyrVideo video={video} />

          {caption && caption.content.length > 0 && (
            <figcaption className="mt-3.5 text-2xs text-gray-400 md:text-sm">
              <RichText text={caption} />
            </figcaption>
          )}
        </figure>
      </div>
    </div>
  );
}

export default VideoBlock;

import * as React from "react";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText, ContentfulImage } from "@src/components";

export type TrendReportImageBlockProps = {
  image?: IContentfulImage | null;
  caption?: Document;
  orientation?: string | null;
};

export function TrendReportImageBlock({
  image,
  caption,
  orientation = "contained"
}: TrendReportImageBlockProps) {
  if (!image) {
    return null;
  }

  const dimensions = {
    width: image.width || 1602,
    height: image.height || 990
  };

  const transforms: ContentfulImageUrlTransforms = {
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <ProductContainer>
      <div
        className={`mx-auto space-y-6 lg:space-y-8 ${
          orientation == "contained" ? "" : "max-w-[1168px]"
        }`}
      >
        <figure className="relative space-y-1 md:space-y-4">
          <ContentfulImage image={image} transforms={transforms} />
          {caption && caption.content.length > 0 && (
            <figcaption className="type-pr-eta text-pr-dark-gray-400">
              <RichText text={caption} />
            </figcaption>
          )}
        </figure>
      </div>
    </ProductContainer>
  );
}

export default TrendReportImageBlock;

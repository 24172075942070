import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import { contentfulEntryUrl, formatDate } from "@src/lib/utils";
import {
  BlogPost,
  CtaLimitedType,
  CtaBlogSubscriptionType,
  CtaDemoRequestType,
  CtaFormType
} from "@src/lib/services/server/contentful";
import {
  CardList,
  Cta,
  CtaForm,
  SectionHeading,
  BlogContainer
} from "@src/components";

export type ListProps = {
  sectionHeading?: string | null;
  displayEyebrow?: string | null;
  items: BlogPost[];
  cta?:
    | CtaLimitedType
    | CtaBlogSubscriptionType
    | CtaDemoRequestType
    | CtaFormType;
};

export function List({
  sectionHeading,
  displayEyebrow,
  items,
  cta
}: ListProps) {
  const renderCta = (
    cta:
      | CtaLimitedType
      | CtaBlogSubscriptionType
      | CtaDemoRequestType
      | CtaFormType
  ) => {
    switch (cta.__typename) {
      case "CtaDemoRequest":
        return (
          <CtaForm
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            formKey={cta.__typename.toLowerCase()}
            centeredEyebrow={true}
            headingStyle={cta.headingStyle?.toLowerCase()}
          />
        );
      case "CtaBlogSubscription":
        return (
          <CtaForm
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            formKey={cta.__typename.toLowerCase()}
            centeredEyebrow={true}
            headingStyle={cta.headingStyle?.toLowerCase()}
          />
        );
      case "CtaForm":
        return (
          <CtaForm
            formId={cta.marketoFormId?.trim()}
            formTheme={cta.formTheme?.toLowerCase().split(" ").join("")}
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            text={cta.text?.json as Document | undefined}
            formKey={cta.__typename.toLowerCase()}
            centeredEyebrow={true}
            headingStyle={cta.headingStyle?.toLowerCase()}
            finePrint={cta.finePrint?.json as Document | undefined}
          />
        );
      // defaults to CtaLimitedType:
      default:
        return (
          <Cta
            eyebrow={cta.eyebrow}
            heading={cta.heading}
            ctaTitle={cta.ctaTitle}
            ctaUrl={cta.ctaUrl ? contentfulEntryUrl(cta.ctaUrl) : null}
            ctaExternalUrl={cta.ctaExternalUrl}
            theme={cta.theme?.toLowerCase()}
            centeredEyebrow={true}
            headingStyle={cta.headingStyle?.toLowerCase()}
          />
        );
    }
  };

  const renderItems = (items: BlogPost[]) => {
    return items.map((item, index) => {
      const topics = item.topicsCollection?.items;
      const eyebrow = topics ? topics[0]?.title : null;

      return (
        <CardList
          key={item.sys.id}
          url={contentfulEntryUrl(item)}
          media={item.previewImage || item.leadImage}
          eyebrow={displayEyebrow || eyebrow}
          heading={item.previewTitle || item.title}
          meta={formatDate(item.date)}
          text={
            item.previewDescription ? item.previewDescription : item.description
          }
        />
      );
    });
  };

  return (
    <section>
      <BlogContainer>
        <div className="pb-5 md:pb-14 lg:pb-28">
          {sectionHeading && (
            <SectionHeading heading={sectionHeading} borderTop={true} />
          )}
          <div className="md:grid md:grid-cols-3 md:gap-x-5 md:gap-y-10 lg:gap-x-10 lg:gap-y-20">
            <div className="-mt-5 divide-y divide-gray-200 md:col-span-2 md:mt-0 md:border-t">
              {renderItems(items)}
            </div>
            <div className="md:col-span-1">{cta && <>{renderCta(cta)}</>}</div>
          </div>
        </div>
      </BlogContainer>
    </section>
  );
}

export default List;

import * as React from "react";
import {
  ContentfulImage,
  LoadingIndicator,
  MarketoForm,
  ProductContainer,
  RichText
} from "@src/components";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type ProductGatedContentProps = {
  image?: IContentfulImage | null;
  form?: {
    marketoFormId?: string | null;
    formHeading?: string | null;
    finePrint?: {
      json: Document | null;
    } | null;
  } | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductGatedContent({ image, form }: ProductGatedContentProps) {
  const [isLoaded, setIsLoaded] = React.useState(false);

  const dimensions = {
    width: Math.round(1486 / 1.5),
    height: Math.round(892 / 1.5)
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div className="pb-10 lg:pb-40">
      <ProductContainer>
        <div className="flex flex-col items-center space-y-10 rounded-3xl bg-pr-yellow-200 px-8 py-10 md:grid md:grid-cols-2 md:justify-between md:gap-10 md:space-y-0 md:px-16 md:py-12">
          <div className="col-span-1 space-y-5">
            {form?.formHeading && (
              <h3 className={`type-pr-gamma`}>
                {returnLineBreaks(form.formHeading)}
              </h3>
            )}
            <MarketoForm
              formId={form?.marketoFormId}
              formTheme={"gatedcontentform"}
              setIsLoaded={setIsLoaded}
            />
            {!isLoaded && (
              <div className="flex items-center justify-center opacity-75">
                <LoadingIndicator size="lg" />
              </div>
            )}
            {isLoaded && form?.finePrint && (
              <div className="fine-print fine-print-get-started text-left text-pr-sm text-[#787779]">
                <RichText text={form.finePrint.json as Document} />
              </div>
            )}
          </div>
          {image && (
            <figure className="relative col-span-1 h-full w-full">
              <ContentfulImage
                image={image}
                transforms={transforms}
                priority={true}
              />
            </figure>
          )}
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductGatedContent;

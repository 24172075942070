import * as React from "react";
import {
  CaseStudy,
  Partner,
  Location,
  PartnerService,
  Card
} from "@src/lib/services/server/contentful";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { SmartLink, ContentfulImage, RichText } from "@src/components";
import { filterNull } from "@src/lib/utils";
import { Document } from "@contentful/rich-text-types";

export type InlinePricingGridProps = {
  caseStudy: CaseStudy | Partner;
  card: Card;
};

const dimensions = {
  width: 2120,
  height: 1376
};

const transforms: ContentfulImageUrlTransforms = {
  fit: "fill" as const,
  focus: "center" as const,
  format: "webp" as const,
  quality: 50,
  width: dimensions.width,
  height: dimensions.height
};

// max is max length to look for and len is length of string to be shown
// @TODO: truncate on full word?
// const truncate = (str: string, max: number, len: number) => {
//   return str.length > max ? str.substring(0, len) + "..." : str;
// };

const renderItems = (items: Location[] | PartnerService[]) => {
  return items.map((item, index) => {
    return (
      <span key={index} className="inline-block">
        {item.title}
        {index < items.length - 1 ? "," : ""}
      </span>
    );
  });
};

export function InlinePricingGrid({ caseStudy, card }: InlinePricingGridProps) {
  const priceDetails = card.priceDetails?.json as Document | undefined;
  const description = card.description?.json as Document | undefined;
  const details = card.details?.json as Document | undefined;

  return (
    <div className="relative flex flex-col items-center justify-center gap-8 md:gap-6 lg:flex-row lg:gap-12">
      <div>
        {caseStudy.leadImage && (
          <figure className="relative aspect-[2120/1376] w-[506px] rounded-xl bg-white p-3.5">
            <ContentfulImage
              image={
                caseStudy.__typename == "CaseStudy"
                  ? caseStudy.previewImage || caseStudy.leadImage
                  : caseStudy.leadImage
              }
              transforms={transforms}
              className="object-cover"
              priority={true}
            />
          </figure>
        )}
      </div>
      <div className="space-y-10">
        <div className="text-pr-xl">
          <div className="pb-4">{caseStudy.description}</div>
          <SmartLink
            href={
              caseStudy.__typename == "CaseStudy"
                ? contentfulEntryUrl(caseStudy)
                : (caseStudy.ctaUrl as string)
            }
            className="link link-underline text-pr-red-500"
          >
            Learn More {">"}
          </SmartLink>
        </div>
        <div className="flex flex-col gap-4 md:flex-row lg:gap-6">
          <div className="flex flex-col space-y-2 md:w-1/3 md:border-r-[1px] md:border-[#0000004f] lg:w-3/5">
            {card.heading && (
              <h3 className="text-[30px] font-medium">{card.heading}</h3>
            )}
            {card.previousPrice && (
              <div className="type-pr-zeta flex flex-row gap-3 text-[17px] text-pr-dark-gray-500">
                <span className="strikethrough-card">
                  ${card.previousPrice}
                </span>
                {card.percentOff && <div>{card.percentOff}</div>}
              </div>
            )}
            {priceDetails && (
              <div className="card-details card-details-price">
                <RichText text={priceDetails} />
              </div>
            )}
            {card.primaryCtaUrl && card.primaryCtaTitle && (
              <div className="pt-4 lg:pt-6">
                <SmartLink
                  href={card.primaryCtaUrl}
                  className={"pr-button pr-button-lg pr-button-teal"}
                >
                  {card.primaryCtaTitle}
                </SmartLink>
              </div>
            )}
          </div>
          <div className="space-y-4 pt-2 lg:space-y-12">
            <p>
              {description && (
                <div className="text-[15px]">
                  <RichText text={description} />
                </div>
              )}
            </p>
            {details && (
              <div className="card-details-details space-y-2 text-[15px]">
                <RichText text={details} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InlinePricingGrid;

function returnLineBreaks(heading: string): React.ReactNode {
  throw new Error("Function not implemented.");
}

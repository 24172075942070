import { parseCookies } from "nookies";
import { ParsedUrlQuery } from "querystring";
import { CalendlyUtm } from "@src/lib/types";

/**
 * Map of query param / cookie name  => Calendly utm prop
 */
const calendlyUtmMap: Record<string, keyof CalendlyUtm> = {
  // BENTO-80: intentionally track the Google Click ID in Calendly's UTM Source parameter
  gclid: "utmSource",
  utm_content: "utmContent",
  utm_campaign: "utmCampaign",
  utm_medium: "utmMedium",
  utm_term: "utmTerm"
};

export function calendlyUtmParams(query?: ParsedUrlQuery) {
  const utm: Partial<CalendlyUtm> = {};

  const cookies = parseCookies();

  // grab utm params from query string and/or cookies
  for (const key in calendlyUtmMap) {
    const prop = calendlyUtmMap[key];

    if (cookies[key]) {
      utm[prop] = cookies[key];
    }

    if (query?.[key]) {
      utm[prop] = query[key] as string;
    }
  }

  return utm;
}

import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { CardFeatured, BlogContainer } from "@src/components";

export type HeroEntryProps = {
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  meta?: string;
  hideDate?: boolean | null;
  text?: string | null;
  byline?: {
    media?: IContentfulImage | null;
    heading?: string | null;
    text?: string | null;
  };
};

export function HeroEntry({
  media,
  eyebrow,
  heading,
  meta,
  hideDate,
  text,
  byline
}: HeroEntryProps) {
  return (
    <section>
      <BlogContainer>
        <div className="pt-6 md:pt-9">
          <CardFeatured
            media={media}
            eyebrow={eyebrow}
            heading={heading}
            meta={meta}
            hideDate={hideDate}
            text={text}
            largeText={true}
            priority={true}
            headerLevel={1}
          />
        </div>
      </BlogContainer>
    </section>
  );
}

export default HeroEntry;

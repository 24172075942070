import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText } from "@src/components";
import { IContentfulImage } from "@src/lib/types";

export type ProductCtaImageWithTextProps = {
  image?: IContentfulImage | null;
  text?: Document;
};

const ProductCtaImageWithText = ({
  image,
  text
}: ProductCtaImageWithTextProps) => {
  return (
    <div>
      <ProductContainer>
        <div className="mx-auto flex max-w-[700px] flex-col items-center justify-center border-y-2 border-[#ECECEE] py-[32px] lg:flex-row lg:py-6">
          {image && (
            <div className="border-[#ECECEE] pb-[32px] lg:border-r-2 lg:py-4 lg:pr-[30px]">
              <figure
                className="relative"
                style={{
                  width: `${image.width || 150}px`,
                  height: `${image.height || 150}px`
                }}
              >
                <img src={image.url || ""} alt={image.description || ""}></img>
              </figure>
            </div>
          )}
          {text && (
            <div className="max-w-[650px] space-y-2.5 text-center text-base lg:pl-[30px] lg:text-left [&_a]:text-pr-teal-500 [&_a]:underline [&_a]:underline-offset-4 [&_b]:text-xl [&_b]:text-pr-teal-500 [&_br]:hidden">
              <RichText text={text} />
            </div>
          )}
        </div>
      </ProductContainer>
    </div>
  );
};

export { ProductCtaImageWithText };

import * as React from "react";

export type SectionHeadingProps = {
  heading?: string | null;
  borderTop?: boolean;
};

export function SectionHeading({
  heading,
  borderTop = false
}: SectionHeadingProps) {
  return (
    <div
      className={`py-5 md:py-7 ${borderTop ? "border-t border-red-500" : ""}`}
    >
      <h2 className="type-gamma text-red-500">{heading}</h2>
    </div>
  );
}

export default SectionHeading;

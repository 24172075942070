import { HolidayCMLink } from "@src/lib/services/server/contentful";
import * as React from "react";

export type HolidayLinkProps = {
  content: HolidayCMLink;
};

const HolidayLink = ({ content }: HolidayLinkProps) => {
  return (
    <div className="mb-4 text-center md:mb-8">
      <a
        href={content.linkUrl ?? ""}
        className="pr-button pr-button-lg pr-button ease mx-auto bg-ada-salmon-500 text-white hover:bg-ada-teal-400 lg:mx-0"
      >
        {content.linkTitle}
      </a>
    </div>
  );
};

export { HolidayLink };

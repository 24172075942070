/* eslint-disable tailwindcss/classnames-order */
import * as React from "react";
import { Logo, Table } from "@src/lib/services/server/contentful";
import { ContentfulImage, ProductContainer, RichText } from "@src/components";
import { useEffect, useRef, useState } from "react";
import { Document } from "@contentful/rich-text-types";

export type ProductComparisonChartBlockProps = {
  items: Table[];
  logos?: Logo[];
};

export function ProductComparisonChartBlock({
  items,
  logos
}: ProductComparisonChartBlockProps) {
  const [isSticky, setIsSticky] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);

  const stickyHeader = () => {
    const pageYOffset = window.scrollY;

    const sectionOffsetTop = chartRef.current?.offsetTop
      ? chartRef.current.offsetTop
      : chartRef.current?.offsetTop;
    const sectionHeight = chartRef.current?.offsetHeight;

    if (
      sectionOffsetTop &&
      sectionHeight &&
      pageYOffset >= sectionOffsetTop &&
      pageYOffset < sectionOffsetTop + (sectionHeight - 200)
    ) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickyHeader);
  }, []);

  const renderItems = (items: Table[]) => {
    return items.map((item, index) => {
      return (
        <div
          key={index}
          className="flex w-fit flex-row items-center border border-gray-200 border-b-pr-dark-gray-500 bg-pr-yellow-200 last:border-b-gray-200 lg:w-full"
        >
          {item.heading && (
            <h2 className="w-[200px] min-w-[200px] bg-pr-yellow-200 px-10 text-center font-bold">
              {item.heading}
            </h2>
          )}
          {item.text && (
            <div className={`comparison-chart block w-full`}>
              <RichText text={item.text.json as Document} />
            </div>
          )}
        </div>
      );
    });
  };

  const renderLogos = (logos: Logo[]) => {
    return logos.map((item, index) => {
      if (index > 0) {
        return (
          <div
            key={index}
            className="flex h-[45px] w-[115px] min-w-[115px] max-w-[115px] items-center justify-center border-r border-gray-200 bg-gray-100"
          >
            <figure className="relative size-full">
              <ContentfulImage
                image={item}
                layout="fill"
                className="object-contain"
              />
            </figure>
          </div>
        );
      }
    });
  };

  return (
    <div className="my-10 md:my-16 lg:my-24 lg:pb-[75px]">
      <ProductContainer>
        <div
          ref={chartRef}
          className="relative h-lvh overflow-x-auto lg:h-full"
        >
          {/* Logo Header */}
          <div
            className={`z-10 flex w-full min-w-[1092px] flex-row items-end justify-between bg-white ${
              isSticky
                ? "sticky lg:fixed lg:inset-x-0 lg:top-[92px] lg:mx-auto lg:max-w-screen-4xl lg:px-8 2xl:px-24"
                : "sticky top-0 lg:relative"
            }`}
          >
            <div className="pb-2 text-3xl font-bold uppercase tracking-widest text-pr-red-500">
              Platform
            </div>
            {logos && (
              <div className="flex flex-row items-end divide-x divide-gray-200">
                <div className="flex h-[60px] w-[115px] min-w-[115px] max-w-[115px] items-center justify-center border border-b-0 border-gray-200 bg-red-500">
                  <figure className="relative size-full">
                    <ContentfulImage
                      image={logos[0]}
                      layout="fill"
                      className="object-contain"
                    />
                  </figure>
                </div>
                <div className="flex w-full flex-row items-end border border-b-0 border-l-0 border-gray-200">
                  {renderLogos(logos)}
                </div>
              </div>
            )}
          </div>

          {/* Tables */}
          {renderItems(items)}
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductComparisonChartBlock;

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
/* eslint-disable tailwindcss/no-custom-classname */
import * as React from "react";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Disclosure, Transition } from "@headlessui/react";
import { Card, ComparisonTable } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, SmartLink, RichText } from "@src/components";
import "swiper/css";
import "swiper/css/pagination";
import { data } from "msw/lib/types/context";

export type ProductCardsBlockProps = {
  id?: string | null;
  heading?: string | null;
  text?: Document;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  comparisonTableCtaTitle?: string | null;
  table: ComparisonTable;
  items: Card[];
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductCardsBlock({
  id = "",
  heading,
  text,
  ctaTitle,
  ctaUrl,
  comparisonTableCtaTitle,
  table,
  items
}: ProductCardsBlockProps) {
  const paginationClassName: string = `pagination-${id as string}`;
  const tableHeading = table ? table.heading : null;
  const tableText = table ? (table.text?.json as Document | undefined) : null;
  const tableOrientation =
    table && table.orientation
      ? table.orientation.toLowerCase().split(" ").join("")
      : null;

  const defaultEyeBrowStyle: string = "red";
  const eyebrowClasses: { [key: string]: string } = {
    red: "bg-pr-red-500 text-white",
    teal: "bg-pr-teal-600 text-white",
    black: "bg-pr-dark-gray-500 text-white",
    yellow: "bg-[#FEC241] text-pr-dark-gray-500"
  };
  const [descriptionHeight, setDescriptionHeight] = React.useState("0px");
  const elementsRef = React.useRef(
    items.map(() => React.createRef<HTMLDivElement>())
  );

  React.useEffect(() => {
    let largestHeight = 0;
    elementsRef.current.map(ref => {
      if (
        ref.current?.getBoundingClientRect().height &&
        ref.current?.getBoundingClientRect().height > largestHeight
      ) {
        largestHeight = Math.round(ref.current?.getBoundingClientRect().height);
      }
    });
    setDescriptionHeight(largestHeight.toString() + "px");
  }, []);

  const renderItems = (items: Card[]) => {
    return items.map((item, index) => {
      const eyebrowStyle = item.eyebrowStyle
        ?.toLowerCase()
        .split(" ")
        .join("") as string;
      const priceDetails = item.priceDetails?.json as Document | undefined;
      const description = item.description?.json as Document | undefined;
      const details = item.details?.json as Document | undefined;

      const eyebrowClass =
        eyebrowClasses[eyebrowStyle ? eyebrowStyle : defaultEyeBrowStyle];

      return (
        <SwiperSlide
          key={index}
          className={`flex !h-auto py-5 xl:!mx-0 xl:!w-1/4 xl:px-2 4xl:px-6
            ${index == 0 ? " w-[346px!important] pl-5 lg:pl-8" : ""}
            ${
              index == items.length - 1
                ? " w-[346px!important] pr-5 lg:pr-8"
                : ""
            }
            ${
              index !== 0 && index != items.length - 1
                ? "w-[314px!important]"
                : ""
            }
          `}
        >
          <div
            className={`flex w-full flex-col rounded-2xl bg-white drop-shadow-lg md:rounded-3xl ${
              item.eyebrow ? "" : "mt-10"
            }`}
          >
            {item.eyebrow && (
              <div
                className={`flex h-10 items-center justify-center rounded-t-2xl px-4 py-2 text-center md:h-10 md:rounded-t-3xl ${eyebrowClass}`}
              >
                <span className="text-pr-sm font-bold uppercase">
                  {item.eyebrow}
                </span>
              </div>
            )}

            <div className="flex flex-col space-y-4 py-3 lg:space-y-5 lg:pb-14 lg:pt-5">
              {item.heading && (
                <h3 className="type-pr-delta px-7 text-pr-5xl">
                  {returnLineBreaks(item.heading)}
                </h3>
              )}
              <div className="px-7">
                {item.previousPrice && (
                  <div className="type-pr-zeta flex h-6 flex-row gap-3 text-[17px] text-pr-dark-gray-500">
                    <span className="strikethrough-card">
                      ${item.previousPrice}
                    </span>
                    {item.percentOff && <div>{item.percentOff}</div>}
                  </div>
                )}
                {!item.previousPrice && <div className="h-6"></div>}
                {priceDetails && (
                  <div className="card-details card-details-price">
                    <RichText text={priceDetails} />
                  </div>
                )}
              </div>
              {item.primaryCtaUrl && item.primaryCtaTitle && (
                <div className="px-7">
                  <SmartLink
                    href={item.primaryCtaUrl}
                    className={"pr-button pr-button-lg pr-button-teal"}
                  >
                    {item.primaryCtaTitle}
                  </SmartLink>
                </div>
              )}
              {description && (
                <div className="text-pr-gray-500 px-7 pb-5 text-[15px] leading-pr-loose">
                  <div
                    ref={elementsRef.current[index]}
                    style={{ minHeight: descriptionHeight }}
                  >
                    <RichText text={description} />
                  </div>
                </div>
              )}

              {details && (
                <div
                  className={`!mt-0 pt-4 lg:pt-5 ${
                    description ? "border-t-[1px] border-[#0000004f]" : ""
                  } `}
                >
                  <div className="card-details-details text-pr-gray-500 px-7 text-[15px]">
                    <RichText text={details} />
                  </div>
                </div>
              )}

              {item.secondaryCtaUrl && item.secondaryCtaTitle && (
                <div className="px-7 ">
                  <SmartLink
                    href={item.secondaryCtaUrl}
                    className={
                      "type-pr-zeta link-underline-animated-sm text-pr-lg text-pr-red-600"
                    }
                  >
                    {item.secondaryCtaTitle}
                  </SmartLink>
                </div>
              )}
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="bg-pr-yellow-200 pb-8 pt-[60px] md:py-16">
      <ProductContainer>
        <div className="space-y-4">
          <div className="mx-auto max-w-[812px] space-y-4 text-center">
            {heading && (
              <h2 className="type-pr-beta">{returnLineBreaks(heading)}</h2>
            )}
            {text && (
              <div className="type-pr-eta">
                <RichText text={text} />
              </div>
            )}
          </div>

          <div className="-mx-5 lg:-mx-8">
            <Swiper
              modules={[Pagination, A11y]}
              spaceBetween={16}
              slidesPerView={"auto"}
              pagination={{
                clickable: true,
                bulletActiveClass: "swiper-pagination-bullet-active-product",
                bulletClass: "swiper-pagination-bullet-product",
                el: `.${paginationClassName}`
              }}
              centerInsufficientSlides={true}
              breakpoints={{
                1280: {
                  allowTouchMove: false
                }
              }}
              className="swiper-cards"
            >
              {renderItems(items)}
            </Swiper>
            <div
              className={`mt-4 flex flex-wrap items-center justify-center gap-2.5 px-24 py-4 xl:hidden ${paginationClassName}`}
            ></div>
          </div>
        </div>

        {ctaUrl && ctaTitle && (
          <div className="mt-10 flex justify-center">
            <SmartLink
              href={ctaUrl}
              className={"pr-button pr-button-lg pr-button-teal"}
            >
              {ctaTitle}
            </SmartLink>
          </div>
        )}

        {comparisonTableCtaTitle && tableText && (
          <div className="mt-10 lg:mt-20">
            <Disclosure>
              {({ open }) => (
                <>
                  <div className="flex justify-center">
                    <Disclosure.Button
                      className={`pr-button pr-button-lg pr-button-teal ${
                        open ? "hidden" : ""
                      }`}
                    >
                      {comparisonTableCtaTitle}
                    </Disclosure.Button>
                    <Disclosure.Button
                      className={`pr-button pr-button-lg pr-button-teal ${
                        open ? "" : "hidden"
                      }`}
                    >
                      Hide Features List
                    </Disclosure.Button>
                  </div>
                  <Transition className="overflow-hidden px-5 py-8 md:py-16 lg:px-8">
                    <Disclosure.Panel>
                      <div className="drop-shadow-lg">
                        {tableHeading && (
                          <h2 className="rounded-t-3xl bg-pr-dark-gray-500 py-5 text-center text-xl text-white opacity-[83%]">
                            {returnLineBreaks(tableHeading)}
                          </h2>
                        )}
                        {tableText && (
                          <div
                            className={`comparison-table block overflow-x-auto rounded-b-3xl ${
                              tableOrientation == "center"
                                ? "comparison-table-centered"
                                : ""
                            }`}
                          >
                            <RichText text={tableText} />
                          </div>
                        )}
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
        )}
      </ProductContainer>
    </div>
  );
}

export default ProductCardsBlock;

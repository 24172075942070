import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import {
  ContentfulImage,
  MarketoForm,
  ProductContainer,
  RichText
} from "@src/components";
import { IContentfulImage } from "@src/lib/types";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type ProductSubscribeFormBlockProps = {
  image?: IContentfulImage | null;
  marketoFormId?: string | null;
  formHeading?: string | null;
  subheading?: string | null;
  formTheme?: string | null;
  finePrint?: Document | null;
};

export function ProductSubscribeFormBlock({
  image,
  formHeading,
  subheading,
  marketoFormId,
  formTheme,
  finePrint
}: ProductSubscribeFormBlockProps) {
  const [isLoaded, setIsLoaded] = React.useState(false);

  return (
    <div className="flex items-center py-10 md:py-16 lg:h-[500px] lg:py-24">
      <ProductContainer>
        <div
          className={`flex min-w-[418px] flex-col justify-center gap-10 md:flex-row md:gap-12 ${
            isLoaded
              ? "opacity-100 transition delay-300 ease-in-out"
              : "opacity-0"
          }`}
        >
          <div>
            {image && (
              <figure className="relative mx-auto h-[150px] w-[100px]">
                <ContentfulImage
                  image={image}
                  layout="fill"
                  className="object-contain"
                />
              </figure>
            )}
          </div>
          <div className="min-w-[330px]">
            {formHeading && (
              <div className="pb-2 text-pr-5xl">{formHeading}</div>
            )}
            {subheading && (
              <div className="max-w-[330px] pb-2 text-pr-base leading-[1.68] text-[#30323B]">
                {subheading}
              </div>
            )}
            <MarketoForm
              formId={marketoFormId}
              formTheme={formTheme?.toLowerCase().split(" ").join("")}
              setIsLoaded={setIsLoaded}
            />
            {finePrint && (
              <div className="fine-print mb-0 mt-2 text-left text-xs [&_a]:text-pr-teal-500">
                <RichText text={finePrint} />
              </div>
            )}
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductSubscribeFormBlock;

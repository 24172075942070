import { HolidayPageContentModules } from "@src/lib/services/server/contentful";
import React, { createContext, useContext, ReactNode } from "react";

export type HolidayContentContextType = {
  prePlan: HolidayPageContentModules | null;
  buildOut: HolidayPageContentModules | null;
  generateRevenue: HolidayPageContentModules | null;
  enjoy: HolidayPageContentModules | null;
};

const HolidayContentContext = createContext<
  HolidayContentContextType | undefined
>(undefined);

export const useHolidayContent = () => {
  const context = useContext(HolidayContentContext);

  if (!context)
    throw new Error(
      "useHolidayContent must be used within a HolidayContentProvider"
    );

  return context;
};

type HolidayContentProviderProps = {
  children: ReactNode;
  value: HolidayContentContextType;
};

export const HolidayContentProvider = ({
  children,
  value
}: HolidayContentProviderProps) => {
  return (
    <HolidayContentContext.Provider value={value}>
      {children}
    </HolidayContentContext.Provider>
  );
};

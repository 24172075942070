// Note: this was influenced by:
// - https://github.com/happycog/TTD-The-Current-NextJS/blob/main/src/components/MarketoForm/MarketoForm.tsx
// - https://paulie.dev/posts/2022/12/how-to-create-custom-marketo-forms-with-react/
// - https://github.com/charliedieter/react-marketo-hook

import * as React from "react";
import getConfig from "next/config";
import { MktoForm, NextConfig } from "@src/lib/types";

const { publicRuntimeConfig } = getConfig() as NextConfig;
const baseUrl: string = publicRuntimeConfig.marketo.baseUrl;
const munchkinId: string = publicRuntimeConfig.marketo.munchkinId;

export type MarketoFormProps = {
  formId?: string | null;
  formTheme?: string | null;
  setIsLoaded?: (isFormLoaded: boolean) => void;
};

function onFormLoad(form: MktoForm) {
  const formEls = document.querySelectorAll("form[id^='mktoForm']");

  if (formEls.length > 0) {
    formEls.forEach(formEl => {
      const styledEls = Array.from(formEl.querySelectorAll("[style]")).concat(
        formEl
      );

      styledEls.forEach(el => {
        el.removeAttribute("style");
      });

      // disable remote stylesheets and local <style>s
      const styleSheets = Array.from(document.styleSheets);
      styleSheets.forEach(styleSheet => {
        if (
          [mktoForms2BaseStyle, mktoForms2ThemeStyle].indexOf(
            styleSheet.ownerNode
          ) != -1 ||
          formEl.contains(styleSheet.ownerNode)
        ) {
          styleSheet.disabled = true;
        }
      });
    });

    const formId = form.getId().toString();
    const currentForm = document.getElementById(`mktoForm_${formId}`);
    const notYouButton = currentForm?.querySelector(".mktoNotYou");
    if (
      currentForm &&
      notYouButton &&
      !notYouButton.getAttribute("data-active")
    ) {
      notYouButton.setAttribute("data-active", "1");
      notYouButton.addEventListener("click", () => {
        setTimeout(() => window.location.reload(), 50);
      });
    }
  }

  // @TODO: tbd how much of this will be useful:
  // // clean up duplicate form elements at body level that share this data-id
  // const formId = formEl.getAttribute(MKTOFORM_ID_ATTRNAME);
  // const formSelector =
  //   "body  > form[" + MKTOFORM_ID_ATTRNAME + '="' + formId + '"]';
  // const formEls = document.querySelectorAll(formSelector);

  // for (let i = 0; i < formEls.length; i++) {
  //   const formEl = formEls[i];
  //   formEl.parentNode?.removeChild(formEl);
  // }
  // const marketoCSS = document.getElementById("mktoForms2ThemeStyle");
  // //remove direct siblings that are inline style tags of marketoCSS
  // if (marketoCSS) {
  //   const nextSibling = marketoCSS.nextSibling;
  //   const prevSibling = marketoCSS.previousSibling;
  //   if (nextSibling && nextSibling.nodeName === "STYLE") {
  //     nextSibling.parentNode?.removeChild(nextSibling);
  //   }
  //   if (prevSibling && prevSibling.nodeName === "STYLE") {
  //     prevSibling.parentNode?.removeChild(prevSibling);
  //   }
  // }
}

export function MarketoForm({
  formId,
  formTheme,
  setIsLoaded
}: MarketoFormProps) {
  const [scriptAdded, setScriptAdded] = React.useState(
    Boolean(typeof window !== "undefined" && window.MktoForms2)
  );

  // poll for marketo script to load
  React.useEffect(() => {
    if (typeof window !== "undefined" && !window.MktoForms2) {
      const interval = setInterval(() => {
        if (window.MktoForms2) {
          setScriptAdded(true);
          clearInterval(interval);
        }
      }, 200);
      return () => clearInterval(interval);
    }
  }, []);

  React.useEffect(() => {
    if (scriptAdded && window.MktoForms2 && formId) {
      // bail out if form has already been loaded
      if (
        window.MktoForms2._formIds &&
        window.MktoForms2._formIds.includes(formId)
      ) {
        return;
      }

      // store formId so form doesn't get loaded twice
      window.MktoForms2._formIds = [
        ...(window.MktoForms2._formIds || []),
        formId
      ];

      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, form => {
        onFormLoad(form);
        setIsLoaded && setIsLoaded(true);
      });
    }
  }, [scriptAdded, formId, setIsLoaded]);

  const classNames: { [key: string]: string } = {
    onecolumnform: "one-col-form",
    twocolumnform: "two-col-form",
    grayonecolumnform: "one-col-form mt-10",
    graytwocolumnform: "two-col-form gray-two-col mt-8",
    redonecolumnform: "red-form",
    redtwocolumnform: "red-form red-two-col mt-4 mb-10",
    inline: "inline-form",
    getstartedform: "two-col-form get-started-form",
    gatedcontentform: "one-col-form get-started-form gated-content-form",
    "onecolumnform-bluebutton": "one-col-form onecolumnform-bluebutton"
  };

  const formTemplate = classNames[formTheme ? formTheme : ""];

  if (!formId) {
    return null;
  }

  return (
    <div className={formTemplate}>
      <form id={`mktoForm_${formId}`} />
    </div>
  );
}

export default MarketoForm;

import { IContentfulImage } from "@src/lib/types";
import * as React from "react";
import {
  ContentfulImage,
  LoadingIndicator,
  MarketoForm,
  ProductContainer,
  RichText,
  SmartLink
} from "@src/components";
import { Document, INLINES } from "@contentful/rich-text-types";
import { Options } from "@contentful/rich-text-react-renderer";
import Script from "next/script";
import { contentfulRichTextLinks } from "@src/lib/utils";
import {
  ProductContentModulesRtGetStartedForm,
  ProductCtaFormType
} from "@src/lib/services/server/contentful";
import { ProductCtaForm } from "../ProductCtaForm";
import { useState } from "react";

export type ProductRtGetStartedBlockProps = {
  heading?: string | null;
  items?: ProductRtGetStartedBlockCardProps[];
  getStartedForm?: ProductContentModulesRtGetStartedForm;
  cardsLayout?: string | null;
};

export type ProductRtGetStartedBlockCardProps = {
  heading?: string | null;
  icon?: IContentfulImage | null;
  text?: {
    json: Document | null;
  } | null;
  cardsLayout?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

function ProductRtGetStartedBlockCard({
  heading,
  icon,
  text,
  cardsLayout
}: ProductRtGetStartedBlockCardProps) {
  // get all links contained in the card text
  const links = React.useMemo(
    () => contentfulRichTextLinks(text?.json),
    [text?.json]
  );
  // check if there's only one link contained
  const hasOneLink = links.length === 1;
  // if there's only one link, make the whole card a link
  const Wrapper = hasOneLink ? SmartLink : "div";
  const richTextOptions = React.useMemo<Options>(
    () => ({
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
          // don't render a link within a link
          return hasOneLink ? (
            <span>{children}</span>
          ) : (
            <SmartLink href={node.data.uri as string}>{children}</SmartLink>
          );
        }
      }
    }),
    [hasOneLink]
  );

  return (
    // <Wrapper
    //   href={links[0]}
    //   className="group mx-auto block max-w-sm rounded-2xl bg-white px-6 py-8 drop-shadow-lg transition md:mx-0 lg:px-8 lg:py-12"
    // >
    //   <div className="space-y-6 lg:space-y-10">
    //     {heading && (
    //       <h3 className="text-pr-3xl font-normal leading-pr-relaxed tracking-pr-wider lg:text-[1.875rem]">
    //         {returnLineBreaks(heading)}
    //       </h3>
    //     )}
    //     {icon && (
    //       <figure className="relative mx-auto h-10 w-10 lg:h-20 lg:w-20">
    //         <ContentfulImage
    //           image={icon}
    //           layout="fill"
    //           className="object-contain"
    //         />
    //       </figure>
    //     )}
    //     {text?.json && (
    //       <RichText
    //         text={text.json}
    //         lineBreakVisibility="xl"
    //         className="[&_a]:link-underline-animated-sm [&_span]:link-underline-animated-sm mx-auto text-pr-base leading-pr-loose text-pr-dark-gray-400 md:text-pr-lg md:leading-pr-looser [&_a]:text-pr-teal-500 [&_p+p]:mt-2 [&_span]:text-pr-teal-500"
    //         {...richTextOptions}
    //       />
    //     )}
    //   </div>
    // </Wrapper>
    <Wrapper
      href={links[0]}
      className={`group mx-auto block transition md:mx-0 lg:px-8 lg:py-12 ${
        cardsLayout == "horizontal" ? "mx-auto block max-w-sm" : " "
      }`}
    >
      <div
        className={`space-y-6 text-center lg:space-y-10 ${
          cardsLayout == "horizontal" ? "lg:space-y-0 " : " "
        }`}
      >
        {heading && (
          <h3
            className={`text-pr-4xl font-normal leading-pr-relaxed tracking-pr-wider lg:text-[1.875rem] ${
              cardsLayout == "horizontal"
                ? " lg:min-h-[105px] xl:min-h-[75px]"
                : " "
            }`}
          >
            {returnLineBreaks(heading)}
          </h3>
        )}
        {icon && (
          <figure className="relative mx-auto h-10 w-10 lg:h-20 lg:w-20">
            <ContentfulImage
              image={icon}
              layout="fill"
              className="object-contain"
            />
          </figure>
        )}
        {text?.json && (
          <RichText
            text={text.json}
            lineBreakVisibility="xl"
            className={`[&_a]:link-underline-animated-sm [&_span]:link-underline-animated-sm mx-auto text-pr-base leading-pr-loose md:text-pr-lg md:leading-pr-looser [&_a]:text-pr-teal-500 [&_p+p]:mt-2 [&_span]:text-pr-3xl [&_span]:text-pr-teal-500 ${
              cardsLayout == "horizontal" ? "lg:pt-12" : " "
            }`}
            {...richTextOptions}
          />
        )}
      </div>
    </Wrapper>
  );
}

export function ProductRtGetStartedBlock({
  heading,
  items,
  getStartedForm,
  cardsLayout
}: ProductRtGetStartedBlockProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  console.log(getStartedForm);
  return (
    <div className="bg-pr-yellow-200 py-12 md:py-16 lg:pb-72 lg:pt-36">
      <ProductContainer>
        <div className="px-1 md:px-2 lg:px-8">
          <div className="mx-auto mb-10 lg:mb-[5rem]">
            {heading && (
              <h2 className="text-pr-6xl font-semibold leading-pr-relaxed tracking-pr-wider lg:text-[2.75rem]">
                {returnLineBreaks(heading)}
              </h2>
            )}
          </div>

          <div
            className={`flex flex-col gap-5 md:gap-0 ${
              cardsLayout == "horizontal" ? " " : " md:flex-row"
            }`}
          >
            {items && (
              <div
                className={`flex flex-col ${items.length >= 3 ? "" : ""} ${
                  cardsLayout == "horizontal"
                    ? "mx-auto mb-10 gap-8 lg:flex-row lg:gap-10"
                    : " md:w-[35%] md:py-20 md:pr-20 lg:py-10 "
                }`}
              >
                {items.map((item, index) => (
                  <ProductRtGetStartedBlockCard
                    key={index}
                    {...item}
                    cardsLayout={cardsLayout}
                  />
                ))}
              </div>
            )}

            {getStartedForm?.marketoFormId && (
              <>
                {/** "OR" divider */}
                <div
                  className={`-mb-6 mt-16 grid place-items-center overflow-visible border-t border-black md:my-0 md:w-[1px] md:border-l md:border-t-0 ${
                    cardsLayout == "horizontal" ? " hidden " : " "
                  } `}
                >
                  <div className="relative top-[-35px] mx-auto h-[71px] w-[71px] rounded-full border border-black bg-pr-yellow-200 text-center text-2xl leading-[71px] after:content-['OR'] md:left-[-36px] md:top-0" />
                </div>

                <div
                  className={`min-h-[726px] text-center md:w-[65%] md:pl-20 md:text-left ${
                    cardsLayout == "horizontal" ? " md:w-full md:pl-0" : " "
                  }`}
                >
                  <div className="relative h-full py-5 lg:px-8">
                    {getStartedForm.formHeading && (
                      <div className="pb-5 text-pr-4xl">
                        {getStartedForm.formHeading}
                      </div>
                    )}
                    <MarketoForm
                      formId={getStartedForm.marketoFormId}
                      formTheme={"getstartedform"}
                      setIsLoaded={setIsLoaded}
                    />
                    {!isLoaded && (
                      <div className="absolute inset-0 flex items-center justify-center opacity-75">
                        <LoadingIndicator size="lg" />
                      </div>
                    )}
                    {isLoaded && getStartedForm.finePrint && (
                      <div className="fine-print fine-print-get-started text-left text-pr-sm text-[#787779]">
                        <RichText
                          text={getStartedForm.finePrint.json as Document}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductRtGetStartedBlock;

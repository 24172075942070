type EnvironmentVariables = {
  BUILD_ID: string;
  ENVIRONMENT_NAME: string;
  ENABLE_ACCESSIBILITY_CHECKS?: string;
  FASTLY_ALL_PAGES_CACHE_KEY: string;
  ENABLE_SENTRY: string;
  SENTRY_DSN: string;
};

const getEnvironmentVariables = (): EnvironmentVariables => ({
  BUILD_ID: process.env.BUILD_ID || "",
  ENVIRONMENT_NAME: process.env.ENVIRONMENT_NAME || "",
  ENABLE_ACCESSIBILITY_CHECKS: process.env.ENABLE_ACCESSIBILITY_CHECKS,
  FASTLY_ALL_PAGES_CACHE_KEY: process.env.FASTLY_ALL_PAGES_CACHE_KEY || "",
  ENABLE_SENTRY: process.env.ENABLE_SENTRY || "",
  SENTRY_DSN: process.env.SENTRY_DSN || ""
});

export default getEnvironmentVariables;

import * as React from "react";
import { isExternalLink } from "@src/lib/utils";
import Link from "next/link";

export type SmartLinkProps = {
  href: string;
  className?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  linkTag?: boolean;
  ctaId?: string | null;
  ariaLabel?: string | null;
};

export function SmartLink({
  href,
  className,
  children,
  onClick,
  linkTag = false,
  ctaId,
  ariaLabel
}: SmartLinkProps) {
  const id = ctaId || undefined;
  const label = ariaLabel || undefined;
  return linkTag ? (
    <a
      className={className}
      href={href}
      onClick={onClick}
      data-link-tag="true"
      id={id}
      aria-label={label}
    >
      {children}
    </a>
  ) : !isExternalLink(href) ? (
    <Link href={href}>
      <a className={className} onClick={onClick} id={id} aria-label={label}>
        {children}
      </a>
    </Link>
  ) : (
    <a
      className={className}
      href={href}
      onClick={onClick}
      target="_blank"
      rel="noreferrer"
      id={id}
      aria-label={label}
    >
      {children}
    </a>
  );
}

export default SmartLink;

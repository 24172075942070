import * as React from "react";

import { HolidayPageHeroSection } from "@src/lib/services/server/contentful";

import { SmartLink } from "@src/components";

export type HolidayHeroSectionProps = {
  heroContent: HolidayPageHeroSection;
};

const HolidayHeroSection = ({ heroContent }: HolidayHeroSectionProps) => {
  const {
    slideTitle,
    slideButtonLink,
    slideButtonTitle,
    slideDescription,
    slideImage
  } = heroContent;

  return (
    <section
      id="hero"
      className="relative overflow-hidden bg-white px-0 py-8 pr-12 md:pr-20 lg:px-24"
    >
      <div className="mx-auto flex w-full max-w-screen-4xl flex-col-reverse gap-5 px-5 md:flex-row md:gap-8 lg:px-0">
        <div
          id="hero-slide__content-column"
          className="flex basis-1/2 flex-col justify-center self-stretch rounded-br-3xl rounded-tl-3xl bg-pr-yellow-200 p-8 lg:basis-1/3"
        >
          <div
            id="hero-slide__content-column--body"
            className="flex flex-col items-start gap-3 text-center md:text-left"
          >
            <h1 className="mx-auto text-4xl font-bold text-ada-salmon-500 md:text-5xl">
              {slideTitle}
            </h1>
            <p>{slideDescription}</p>
            {slideButtonTitle && (
              <SmartLink
                href={slideButtonLink ?? ""}
                className="pr-button pr-button-lg pr-button ease mx-auto bg-ada-salmon-500 text-white hover:bg-ada-teal-400 lg:mx-0"
              >
                {slideButtonTitle}
              </SmartLink>
            )}
          </div>
        </div>
        <img
          id="hero-slide__image-column"
          className="h-auto basis-1/2 overflow-hidden rounded-br-3xl rounded-tl-3xl bg-ada-blue-400/10 object-cover lg:basis-2/3 2xl:h-96"
          src={slideImage?.url ?? ""}
          alt={slideImage?.description ?? ""}
        ></img>
      </div>
    </section>
  );
};

export { HolidayHeroSection };

import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { contentfulEntryUrl, formatDate } from "@src/lib/utils";
import { BlogPost } from "@src/lib/services/server/contentful";
import {
  CardInterrupter,
  CardFeatured,
  SectionHeading,
  BlogContainer
} from "@src/components";

export type InterrupterProps = {
  sectionHeading?: string | null;
  url: string;
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  meta?: string;
  text?: string | null;
  items: BlogPost[];
};

export function Interrupter({
  sectionHeading,
  url,
  media,
  eyebrow,
  heading,
  meta,
  text,
  items
}: InterrupterProps) {
  const renderItems = (items: BlogPost[]) => {
    return items.map((item, index) => {
      const topics = item.topicsCollection?.items;
      const eyebrow = topics ? topics[0]?.title : null;

      return (
        <CardInterrupter
          key={item.sys.id}
          url={contentfulEntryUrl(item)}
          media={item.previewImage || item.leadImage}
          eyebrow={eyebrow}
          heading={item.previewTitle || item.title}
          meta={formatDate(item.date)}
        />
      );
    });
  };

  return (
    <section>
      <BlogContainer>
        <div className="overflow-hidden pb-5 md:pb-14 lg:pb-28">
          <SectionHeading heading={sectionHeading} borderTop={true} />
          <div className="space-y-6 divide-y divide-gray-200 md:space-y-9">
            <CardFeatured
              url={url}
              media={media}
              eyebrow={eyebrow}
              heading={heading}
              meta={meta}
              text={text}
            />

            <div className="md:pt-6">
              <div className="divide-y divide-gray-200 md:-mx-2.5 md:grid md:grid-cols-3 md:divide-x md:divide-y-0 lg:-mx-5">
                {renderItems(items)}
              </div>
            </div>
          </div>
        </div>
      </BlogContainer>
    </section>
  );
}

export default Interrupter;

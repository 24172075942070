import { init as sentryInit } from "@sentry/browser";
import { captureConsoleIntegration } from "@sentry/integrations";
import getEnvironmentVariables from "../../config/environment";

export const sentry = () => {
  const { ENVIRONMENT_NAME, ENABLE_SENTRY, SENTRY_DSN, BUILD_ID } =
    getEnvironmentVariables();

  // Converting to a string so we only have to deal with one type between client and server side
  if (String(ENABLE_SENTRY) !== "true") {
    return;
  }

  sentryInit({
    dsn: SENTRY_DSN,
    debug: false,
    release: BUILD_ID,
    environment: ENVIRONMENT_NAME,
    sampleRate: 1.0,
    maxBreadcrumbs: 100,
    normalizeDepth: 3,
    integrations: [captureConsoleIntegration({ levels: ["error"] })],
    ignoreErrors: [`Can't find variable: jQuery`, "jQuery is not defined"]
  });
};

export default sentry;

import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText } from "@src/components";

export type ProductToadCalculatorProps = {
  heading?: string | null;
  description?: Document;
  subheading?: string | null;
  subtext?: string | null;
};

export function ProductToadCalculator({
  heading,
  subheading,
  description,
  subtext
}: ProductToadCalculatorProps) {
  const [ordersPerYear, setOrdersPerYear] = React.useState(-1);
  const [orderSize, setOrderSize] = React.useState(-1);
  const [commissionFees, setCommissionFees] = React.useState(-1);
  const [creditCardFees, setCreditCardFees] = React.useState(3);
  const [monthlyFees, setMonthlyFees] = React.useState(49);
  const [dinerFee, setDinerFee] = React.useState(true);
  const [monthlyProfit, setMonthlyProfit] = React.useState("$0.00");
  const [monthlySavings, setMonthlySavings] = React.useState("$0.00");
  const [totalRevenue, setTotalRevenue] = React.useState("$0.00");
  const [emptyForm, setEmptyForm] = React.useState(false);
  const [errorYear, setErrorYear] = React.useState(false);
  const [errorOrderSize, setErrorOrderSize] = React.useState(false);
  const [errorCommission, setErrorCommission] = React.useState(false);
  const [errorCreditCard, setErrorCreditCard] = React.useState(false);

  const Calculate = () => {
    if (
      ordersPerYear < 0 ||
      !Number(ordersPerYear) ||
      orderSize < 0 ||
      !Number(orderSize) ||
      commissionFees < 0 ||
      !Number(commissionFees)
    ) {
      setErrorYear(ordersPerYear < 0);
      if (!Number(ordersPerYear)) {
        setErrorYear(true);
      }
      setErrorOrderSize(orderSize < 0);
      if (!Number(orderSize)) {
        setErrorOrderSize(true);
      }
      setErrorCommission(commissionFees < 0);
      if (!Number(commissionFees)) {
        setErrorCommission(true);
      }
      setEmptyForm(true);
    } else {
      setErrorYear(false);
      setErrorOrderSize(false);
      setErrorCommission(false);
      setEmptyForm(false);
      let profit = 0;
      const revenue = orderSize * ordersPerYear * (1 - commissionFees / 100);

      if (dinerFee) {
        profit =
          orderSize * ordersPerYear -
          (orderSize * creditCardFees * ordersPerYear) / 100 -
          0.99 * ordersPerYear -
          monthlyFees;
      } else {
        profit =
          orderSize * ordersPerYear -
          (orderSize * creditCardFees * ordersPerYear) / 100 -
          monthlyFees;
      }

      setMonthlySavings(
        (Math.floor((profit - revenue) * 100) / 100).toLocaleString("en", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      );
      setTotalRevenue(
        (Math.floor(revenue * 100) / 100).toLocaleString("en", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      );
      setMonthlyProfit(
        (Math.floor(profit * 100) / 100).toLocaleString("en", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      );
    }
  };

  // React.useEffect(() => {
  //   if (
  //     ordersPerYear >= 0 &&
  //     orderSize >= 0 &&
  //     commissionFees >= 0 &&
  //     creditCardFees >= 0
  //   ) {
  //     setEmptyForm(false);
  //   }
  // }, [ordersPerYear, orderSize, commissionFees, creditCardFees, monthlyFees]);

  return (
    <div className="my-10 md:my-16 lg:my-24">
      <ProductContainer>
        <div className="mx-auto max-w-[1010px] space-y-2">
          {heading && <h3 className="text-center text-[30px]">{heading}</h3>}
          {subheading && (
            <div className="text-center text-pr-2xl">{subheading}</div>
          )}
        </div>
        <div className="mt-10 flex max-w-[1020px] flex-col rounded-[26px] bg-pr-yellow-200 lg:mx-auto lg:flex-row">
          <form
            action=""
            className="flex flex-col justify-between gap-5 px-[30px] py-10 lg:w-2/3 lg:justify-normal lg:gap-0 lg:py-[30px]"
          >
            <div>
              <div className="flex flex-col gap-6 border-b border-pr-yellow-500 pb-6 lg:gap-8 lg:pb-[60px]">
                <div className="flex flex-col gap-2 lg:flex-row lg:items-end lg:gap-1">
                  <label className="w-full">
                    <div className={`${errorYear ? "text-[#DC2710] " : ""}`}>
                      Average Orders Per Month
                    </div>
                    <div
                      className={`${
                        errorYear ? "block text-[8px] text-[#DC2710]" : "hidden"
                      }`}
                    >
                      *Please enter an input.
                    </div>
                  </label>
                  <div className="flex flex-row lg:min-w-[262px]">
                    <input
                      type="number"
                      className={`input-percent ${
                        errorYear ? "toad-calc-input-error" : "toad-calc-input"
                      }`}
                      placeholder="Input Value"
                      onChange={e => {
                        setOrdersPerYear(parseFloat(e.target.value));
                      }}
                    />
                  </div>
                </div>
                {/* Order size */}
                <div
                  className={`toad-calc-selected ${
                    errorOrderSize ? " toad-calc-selected-error " : " "
                  } flex flex-col gap-2 lg:flex-row lg:items-center lg:gap-1`}
                >
                  <label htmlFor="orderSize" className="w-full">
                    <div
                      className={`${errorOrderSize ? "text-[#DC2710] " : ""}`}
                    >
                      Average Order Size
                    </div>
                    <div
                      className={`${
                        errorOrderSize
                          ? "block text-[8px] text-[#DC2710]"
                          : "hidden"
                      }`}
                    >
                      *Please select an average from the drop-down.
                    </div>
                  </label>
                  <select
                    id="orderSize"
                    name="orderSize"
                    className={`lg:min-w-[262px] ${
                      errorOrderSize
                        ? "toad-calc-input-error !text-[#DC2710]"
                        : "toad-calc-input"
                    } ${
                      orderSize < 0 ? "text-[#777]" : "text-pr-dark-gray-500"
                    }`}
                    onChange={e => {
                      setOrderSize(parseFloat(e.target.value));
                    }}
                  >
                    <option value="" disabled={true} selected={true}>
                      Select Average
                    </option>
                    <option value="10">$10</option>
                    <option value="25">$25</option>
                    <option value="35">$35</option>
                    <option value="50">$50</option>
                    <option value="75">$75</option>
                    <option value="100">$100</option>
                    <option value="150">$150</option>
                    <option value="200">$200</option>
                  </select>
                </div>
                {/* Commission Fees */}
                <div className="flex flex-col gap-2 lg:flex-row lg:items-end lg:gap-1">
                  <label className="w-full">
                    <div
                      className={`${errorCommission ? "text-[#DC2710] " : ""}`}
                    >
                      Commission Fee
                    </div>
                    <div
                      className={`${
                        errorCommission
                          ? "block text-[8px] text-[#DC2710]"
                          : "hidden"
                      }`}
                    >
                      *Please enter an input.
                    </div>
                  </label>
                  <div className="flex flex-row lg:min-w-[262px]">
                    <input
                      type="number"
                      className={`input-percent ${
                        errorCommission
                          ? "toad-calc-input-error"
                          : "toad-calc-input"
                      }`}
                      placeholder="Input Value"
                      onChange={e => {
                        setCommissionFees(parseFloat(e.target.value));
                      }}
                    />
                    <div
                      className={`border-b text-xl ${
                        errorCommission
                          ? "block border-[#DC2710] text-[#DC2710]"
                          : "border-pr-dark-gray-500 text-pr-dark-gray-500"
                      }`}
                    >
                      %
                    </div>
                  </div>
                </div>
                {/* Montly Takeout + Delivery Fee */}
                <div className="hidden flex-col gap-2 lg:flex-row lg:justify-end lg:gap-1">
                  <label className="flex flex-row items-start">
                    <div>Takeout & Delivery Monthly Fee ($)</div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <ellipse
                          cx="4.71395"
                          cy="4.86482"
                          rx="4.53987"
                          ry="4.49995"
                          fill="#333333"
                          fill-opacity="0.65"
                        />
                        <path
                          d="M4.12054 5.8535V5.77171C4.12216 5.49108 4.14724 5.26738 4.19577 5.1006C4.24592 4.93383 4.31873 4.79913 4.41418 4.6965C4.50963 4.59387 4.6245 4.50086 4.75878 4.41747C4.85908 4.35332 4.94887 4.28677 5.02815 4.21782C5.10742 4.14886 5.17052 4.07269 5.21743 3.98931C5.26435 3.90432 5.28781 3.8097 5.28781 3.70547C5.28781 3.59482 5.26111 3.4978 5.20773 3.41441C5.15434 3.33103 5.08234 3.26688 4.99174 3.22198C4.90276 3.17708 4.80408 3.15463 4.69568 3.15463C4.59052 3.15463 4.49103 3.17788 4.39719 3.22439C4.30336 3.26929 4.22651 3.33664 4.16665 3.42644C4.10679 3.51464 4.07443 3.62449 4.06958 3.75598H3.07947C3.08756 3.43526 3.16521 3.17067 3.31244 2.9622C3.45966 2.75213 3.65461 2.59577 3.89728 2.49314C4.13996 2.38891 4.40771 2.33679 4.70053 2.33679C5.02248 2.33679 5.30722 2.38971 5.55475 2.49555C5.80228 2.59978 5.99642 2.75132 6.13717 2.95017C6.27792 3.14902 6.3483 3.38876 6.3483 3.66939C6.3483 3.85701 6.31675 4.02378 6.25365 4.16971C6.19218 4.31404 6.10562 4.44232 5.99399 4.55458C5.88236 4.66523 5.75051 4.76545 5.59843 4.85525C5.47062 4.93062 5.36546 5.0092 5.28295 5.09098C5.20206 5.17277 5.14139 5.26738 5.10095 5.37482C5.06212 5.48226 5.0419 5.61456 5.04028 5.77171V5.8535H4.12054ZM4.60104 7.39296C4.43926 7.39296 4.30093 7.33683 4.18606 7.22458C4.07282 7.11072 4.017 6.97442 4.01862 6.81566C4.017 6.65851 4.07282 6.5238 4.18606 6.41155C4.30093 6.2993 4.43926 6.24317 4.60104 6.24317C4.75473 6.24317 4.88982 6.2993 5.00631 6.41155C5.12279 6.5238 5.18184 6.65851 5.18346 6.81566C5.18184 6.9215 5.15353 7.01851 5.09852 7.10671C5.04513 7.19331 4.97476 7.26306 4.88739 7.31598C4.80003 7.3673 4.70458 7.39296 4.60104 7.39296Z"
                          fill="#FFF7EC"
                        />
                      </svg>
                    </div>
                  </label>
                  <input
                    type="number"
                    className="toad-calc-input placeholder:text-pr-dark-gray-500"
                    onChange={e => {
                      setMonthlyFees(parseFloat(e.target.value));
                    }}
                    placeholder="$49"
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="pb-2 lg:pb-0 lg:pt-12">
              <div
                className={`hidden ${
                  emptyForm ? "lg:block" : "hidden"
                } -mt-[30px] pb-[10px] text-base text-[#DC2710]`}
              >
                *All the fields are required. Please review the form and fill
                any empty inputs.
              </div>
              {/* Pass back .99 order fee. Yes or No */}
              <div className="flex flex-col gap-5 lg:flex-col lg:items-center lg:justify-center lg:gap-0 lg:pb-0">
                <button
                  type="button"
                  className="pr-button mx-auto bg-[#14838A] px-[36px] py-[14px] text-base font-bold text-white "
                  onClick={Calculate}
                >
                  Calculate
                </button>
                {description && (
                  <p className="pt-8 text-[16px] leading-[21px] lg:pt-12">
                    <RichText text={description} />
                  </p>
                )}
              </div>
            </div>
          </form>
          <div className="rounded-b-[26px] bg-pr-yellow-500 py-10 lg:w-1/2 lg:rounded-r-[26px] lg:rounded-bl-none lg:py-[30px]">
            <div className="flex flex-col items-center gap-[30px] px-[30px]">
              {/* Monthly Savings */}
              <div className="flex w-full flex-col items-center gap-1 rounded-3xl bg-[#FFF7EC] px-[38px] py-5">
                <div className="flex flex-row items-center gap-1">
                  <p className="text-base font-bold tracking-wide">
                    MONTHLY SAVINGS
                  </p>
                  <div>
                    <svg
                      width="19"
                      height="16"
                      viewBox="0 0 19 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Growth"
                        d="M9.46199 1.22107L17.8319 15.1057H1.09204L9.46199 1.22107Z"
                        fill="#5BCA81"
                        stroke="black"
                        stroke-opacity="0.25"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-[12px] italic">
                  with BentoBox Takeout & Delivery*
                </p>
                <div className="text-[48px] font-bold">{monthlySavings}</div>
              </div>

              {/* Monthly Profit w/ Bento */}
              <div className="flex flex-col items-center justify-center gap-1">
                <p className="text-base tracking-wide">MONTHLY REVENUE</p>
                <p className="text-[12px] italic">
                  with BentoBox Takeout & Delivery*
                </p>
                <div className="text-[32px] font-bold">{monthlyProfit}</div>
              </div>

              {/* Monthly Profit w/out Bento */}
              <div className="flex w-full flex-col items-center justify-center gap-1 border-b border-white pb-5">
                <p className="text-base tracking-wide">MONTHLY REVENUE</p>
                <p className="text-[12px] italic">
                  with 3rd party online ordering†
                </p>
                <div className="text-[32px] font-[600]">{totalRevenue}</div>
              </div>

              {subtext && (
                <div className="max-w-[287px] text-center text-[8px]">
                  {subtext}
                </div>
              )}
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductToadCalculator;

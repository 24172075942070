import * as React from "react";
import { contentfulEntryUrl } from "@src/lib/utils";
import { Topic } from "@src/lib/services/server/contentful";
import { CardTopics, SectionHeading, BlogContainer } from "@src/components";

export type TopicsProps = {
  sectionHeading?: string | null;
  items: Topic[];
  totalPerTopic?: { [topicId: string]: number };
};

// @TODO: get count of blogposts for each Topic, need to rewrite method for this

export function Topics({ sectionHeading, items, totalPerTopic }: TopicsProps) {
  const count = items.length,
    remainder = count % 3;

  const renderItems = (items: Topic[]) => {
    return items.map((item, index) => {
      let borderTop: boolean = false;
      let borderBottom: boolean = false;
      let borderRight: boolean = false;

      if (index > 0) {
        borderTop = true;
      }

      if (remainder === 0) {
        if (index < count - 3) {
          borderBottom = true;
        }
      } else if (index < count - remainder) {
        borderBottom = true;
      }

      if ((index + 1) % 3 !== 0) {
        borderRight = true;
      }

      return (
        <CardTopics
          key={item.sys.id}
          url={contentfulEntryUrl(item)}
          heading={item.title}
          text={item.description}
          meta={
            totalPerTopic && totalPerTopic[item.sys.id] > 0
              ? `${totalPerTopic[item.sys.id]} RESOURCE${
                  totalPerTopic[item.sys.id] > 1 ? "S" : ""
                }`
              : undefined
          }
          borderTop={borderTop}
          borderBottom={borderBottom}
          borderRight={borderRight}
        />
      );
    });
  };

  return (
    <section>
      <BlogContainer>
        <div className="overflow-hidden pb-5 md:pb-14 lg:pb-28">
          <SectionHeading heading={sectionHeading} borderTop={true} />
          <div className="-mt-5 md:-mx-2.5 md:grid md:grid-cols-3 lg:-mx-5">
            {renderItems(items)}
          </div>
        </div>
      </BlogContainer>
    </section>
  );
}

export default Topics;

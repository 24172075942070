import { Calendly } from "@src/lib/types";

/**
 * Poll until window.Calendly is available
 */
export function waitUntilCalendlyIsReady(): Promise<Calendly> {
  function pollForCalendly(resolve: (calendly: Calendly) => void) {
    if (window.Calendly) {
      resolve(window.Calendly);
    } else {
      setTimeout(() => pollForCalendly(resolve), 100);
    }
  }

  return new Promise<Calendly>(resolve => pollForCalendly(resolve));
}

/* eslint-disable @next/next/no-img-element */
import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { RichText, ContentfulImage } from "@src/components";
import { Document } from "@contentful/rich-text-types";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type ImageBlockProps = {
  media?: IContentfulImage | null;
  caption?: Document | null;
};

export function ImageBlock({ media, caption }: ImageBlockProps) {
  if (!media) {
    return null;
  }

  const dimensions = {
    width: media.width || Math.round(1828 / 1.5),
    height: media.height || Math.round(1828 / 1.5)
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div className="my-8 md:my-16">
      <div className="xl:-mx-10">
        <figure>
          <ContentfulImage image={media} transforms={transforms} />
        </figure>

        {caption && caption.content.length > 0 && (
          <figcaption className="mt-3.5 text-2xs text-gray-400 md:text-sm">
            <RichText text={caption} />
          </figcaption>
        )}
      </div>
    </div>
  );
}

export default ImageBlock;

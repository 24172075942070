import type {
  DynamicTrendReportCollection as ImportedDynamicTrendReportCollection,
  DynamicTrendReportDynamicSectionsCollection,
  DynamicTrendReportHeroSection,
  DTRDynamicSectionSwiper
} from "@src/lib/services/server/contentful";

import React, { createRef, useEffect, useRef, useState } from "react";
import Chevron from "@/img/icons/chevron.svg";

import {
  DtrDynamicSections,
  DtrHeroSection,
  ProductContainer
} from "@src/components";
import { Disclosure, Transition } from "@headlessui/react";

export interface DynamicTrendReportCollection
  extends ImportedDynamicTrendReportCollection {
  heroSection?: DynamicTrendReportHeroSection | null;
  dynamicSectionsCollection?: DynamicTrendReportDynamicSectionsCollection | null;
}

export interface DynamicTrendReportProps {
  entry: DynamicTrendReportCollection;
}

const DynamicTrendReport = ({ entry }: DynamicTrendReportProps) => {
  const { heroSection, dynamicSectionsCollection } = entry;

  const [stickyClass, setStickyClass] = useState("");
  const [stickyMobileClass, setStickyMobileClass] = useState("relative z-10");
  const [activeSection, setActiveSection] = useState("");

  const navigationItems =
    dynamicSectionsCollection?.items
      .map(section =>
        section?.__typename === "DynamicTrendReportSwiper"
          ? {
              id: `${section.title?.toLowerCase().split(" ").join("-") ?? ""}`,
              title: section.title,
              section: section
            }
          : null
      )
      .filter(
        (
          item
        ): item is {
          id: string;
          title: string | null;
          section: DTRDynamicSectionSwiper;
        } => item !== null
      ) || [];

  const sectionsRef = useRef(
    navigationItems.map(() => createRef<HTMLDivElement>())
  );

  useEffect(() => {
    const handleScroll = () => {
      const pageYOffset = window.scrollY;
      let newActiveSection = "";

      sectionsRef.current.map(section => {
        const offsetTop = section.current?.offsetTop
          ? section.current.offsetTop - 300
          : section.current?.offsetTop;
        const height = section.current?.offsetHeight;

        if (
          offsetTop &&
          height &&
          pageYOffset >= offsetTop &&
          pageYOffset < offsetTop + height
        ) {
          newActiveSection = section.current.id;
        }
      });

      setActiveSection(newActiveSection);

      const threshold = 50;
      setStickyClass(
        pageYOffset > threshold
          ? "fixed top-[80px] lg:top-[92px] left-0 right-0 mx-auto z-10 bg-white py-5"
          : ""
      );
      setStickyMobileClass(
        pageYOffset > threshold
          ? "fixed top-[80px] left-0 right-0 mx-auto z-10 bg-white"
          : "relative z-10"
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [dynamicSectionsCollection]);

  return (
    <>
      <section>
        <ProductContainer>
          <div>
            {/* Desktop Navigation */}
            <div
              className={`hidden flex-row flex-wrap justify-center gap-3 py-6 md:flex lg:gap-5 ${stickyClass}`}
            >
              {navigationItems.map(({ id, title }) => (
                <a
                  key={id}
                  href={`#${id}`}
                  className={`text-pr-lg font-medium uppercase tracking-[0.54px] text-pr-dark-gray-500 lg:text-xl ${
                    activeSection === id
                      ? "border-b-2 border-pr-teal-500 pb-1"
                      : ""
                  }`}
                >
                  {title}
                </a>
              ))}
            </div>

            {/* Mobile Navigation */}
            <div
              className={`mx-auto flex w-full flex-col justify-center md:hidden ${stickyMobileClass}`}
            >
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button>
                      <div className="flex flex-row items-center justify-center gap-5 py-4">
                        <Chevron
                          className={`size-4 stroke-current transition ${
                            open ? "rotate-180" : ""
                          }`}
                        />
                      </div>
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel>
                        <div className="relative z-10 flex flex-col bg-white">
                          {navigationItems.map(({ id, title }) => (
                            <a
                              key={id}
                              href={`#${id}`}
                              className="text-pr-md border-b border-gray-200 py-3 text-center font-medium uppercase tracking-[0.54px] text-pr-dark-gray-500"
                            >
                              {title}
                            </a>
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </ProductContainer>
      </section>

      {heroSection && <DtrHeroSection content={heroSection} />}
      {dynamicSectionsCollection && (
        <DtrDynamicSections
          content={dynamicSectionsCollection}
          sectionsRef={sectionsRef}
        />
      )}
    </>
  );
};

export { DynamicTrendReport };

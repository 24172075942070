import {
  DTRDynamicSectionOneColumnText,
  DTRDynamicSectionSwiper,
  DTRDynamicSectionSwiperCollectionItem,
  DynamicTrendReportDynamicSectionsCollection,
  DynamicTrendReportDynamicSectionsCollectionItem
} from "@src/lib/services/server/contentful";

import { DtrDynamicSwiper } from "@src/components";

import React, { RefObject, MutableRefObject } from "react";
import { FadeInContainer } from "@src/components/FadeInContainer";

export type DtrDynamicSectionsProps = {
  content: DynamicTrendReportDynamicSectionsCollection;
  sectionsRef: MutableRefObject<RefObject<HTMLDivElement>[]>;
};

const DtrDynamicSections = ({
  content,
  sectionsRef
}: DtrDynamicSectionsProps) => {
  const { items: dynamicSectionItems } = content;

  const renderSwiper = (
    { title, description, swiperSlidesCollection }: DTRDynamicSectionSwiper,
    index: number
  ) => {
    const id = title?.toLowerCase().split(" ").join("-") ?? "";
    return (
      <FadeInContainer key={id}>
        <div
          id={id}
          className={`relative mx-auto flex w-full flex-col gap-6 text-balance px-5 py-16 text-center lg:px-8 2xl:px-24 ${
            index % 2 === 0 ? "bg-pr-yellow-200" : "bg-white"
          }`}
          ref={sectionsRef.current[index]}
        >
          <div className="mx-auto w-full max-w-screen-2xl">
            <div className="mb-6 flex flex-col gap-6">
              <h2 className="text-5xl font-bold text-pr-dark-gray-600">
                {title}
              </h2>
              {description && <p>{description}</p>}
            </div>
            {swiperSlidesCollection && (
              <DtrDynamicSwiper
                id={id}
                swiperIndex={index}
                content={
                  swiperSlidesCollection as DTRDynamicSectionSwiperCollectionItem
                }
              />
            )}
          </div>
        </div>
      </FadeInContainer>
    );
  };

  const renderOneColumnText = (
    { title, displayTitle, description, sys }: DTRDynamicSectionOneColumnText,
    index: number
  ) => {
    return (
      <FadeInContainer key={sys.id}>
        <div
          className="relative mx-auto flex w-full flex-col gap-6 text-balance bg-pr-yellow-100 px-5 py-16 text-center text-sm text-pr-dark-gray-400 lg:px-8 2xl:px-24"
          ref={sectionsRef.current[index]}
        >
          <div className="mx-auto w-full max-w-screen-2xl">
            <div className="mb-6 flex flex-col gap-6">
              {displayTitle && <h2 className="text-5xl font-bold">{title}</h2>}
              {description && <p>{description}</p>}
            </div>
          </div>
        </div>
      </FadeInContainer>
    );
  };

  const renderDynamicSection = (
    section: DynamicTrendReportDynamicSectionsCollectionItem,
    index: number
  ) => {
    switch (section.__typename) {
      case "DynamicTrendReportSwiper":
        return renderSwiper(section, index);
      case "DynamicTrendReportOneColumnText":
        return renderOneColumnText(section, index);
      default:
        return null;
    }
  };

  return (
    <>
      {dynamicSectionItems.map((section, index) =>
        renderDynamicSection(
          section as DTRDynamicSectionSwiper | DTRDynamicSectionOneColumnText,
          index
        )
      )}
    </>
  );
};

export { DtrDynamicSections };

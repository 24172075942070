import { HolidayCMColumnCards } from "@src/lib/services/server/contentful";
import * as React from "react";

export type HolidayColumnCardsProps = {
  content: HolidayCMColumnCards;
};

const HolidayColumnCards = ({ content }: HolidayColumnCardsProps) => {
  if (!content.cardsCollection?.items) return null;

  const cards = content.cardsCollection.items;

  return (
    <div
      id={`column-cards-${content.sys.id}`}
      className="mx-auto my-10 flex flex-wrap justify-center gap-6 md:my-16"
    >
      {cards.map(card => (
        <a
          key={card?.title}
          href={card?.url ?? ""}
          className="flex flex-1 basis-full flex-col gap-3 transition-all hover:scale-105 xs:basis-1/3 md:basis-1/5"
        >
          <img
            src={card?.image?.url ?? ""}
            alt={card?.image?.title ?? ""}
            className="aspect-square rounded-3xl object-cover"
          />
          <h3 className="text-center text-base font-bold md:text-lg">
            {card?.title}
          </h3>
        </a>
      ))}
    </div>
  );
};

export { HolidayColumnCards };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatDate(date?: any): string {
  if (typeof date === "string" || typeof date === "number") {
    date = new Date(date);
  }

  if (!(date instanceof Date)) {
    return "";
  }

  return date.toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric"
  });
}

import { IContentfulImage } from "@src/lib/types";

/**
 * @link https://www.contentful.com/developers/docs/references/images-api/
 */
export interface ContentfulImageUrlTransforms {
  format?: "jpg" | "png" | "webp" | "gif" | "avif";
  progressive?: boolean;
  width?: number | string;
  height?: number | string;
  fit?: "pad" | "fill" | "scale" | "crop" | "thumb";
  focus?:
    | "center"
    | "top"
    | "right"
    | "left"
    | "bottom"
    | "top_right"
    | "top_left"
    | "bottom_right"
    | "bottom_left"
    | "face"
    | "faces";
  radius?: number | string;
  quality?: number | string;
  backgroundColor?: string;
}

export function contentfulImageUrl(
  image: IContentfulImage,
  transforms?: ContentfulImageUrlTransforms
): string {
  if (!image.url) {
    return "";
  }

  const url = image.url.startsWith("//") ? `https:${image.url}` : image.url;

  const urlSearchParams = new URLSearchParams();

  if (transforms?.format) {
    urlSearchParams.set("fm", transforms.format);
  }

  if (transforms?.progressive) {
    urlSearchParams.set("fl", "progressive");
  }
  if (transforms?.width) {
    urlSearchParams.set("w", String(transforms.width));
  }

  if (transforms?.height) {
    urlSearchParams.set("h", String(transforms.height));
  }

  if (transforms?.fit) {
    urlSearchParams.set("fit", transforms.fit);
  }

  if (transforms?.focus) {
    urlSearchParams.set("f", transforms.focus);
  }

  if (transforms?.radius) {
    urlSearchParams.set("r", String(transforms.radius));
  }

  if (transforms?.quality) {
    urlSearchParams.set("q", String(transforms.quality));
  }

  if (transforms?.backgroundColor) {
    urlSearchParams.set("bg", transforms.backgroundColor);
  }

  const queryString = urlSearchParams.toString();

  return queryString ? `${url}?${queryString}` : url;
}

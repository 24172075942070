import React, { useState, SVGProps } from "react";

import {
  BentoCoreModulesCollection,
  BentoCoreModulesCollectionItem
} from "@src/lib/services/server/contentful";

import {
  BentoCoreDetails,
  BentoCoreGraphic,
  ProductContainer
} from "@src/components";

export type BentoCoreProps = {
  content: BentoCoreModulesCollection;
} & SVGProps<SVGSVGElement>;

const BentoCore: React.FC<BentoCoreProps> = ({ content }) => {
  const [activePath, setActivePath] = useState<string | null>("BentoCore");
  const handleClick = (id: string) => setActivePath(id);
  const { items } = content;

  return (
    <ProductContainer>
      <div className="my-24 flex flex-col-reverse items-center gap-6 md:flex-row">
        <div className="flex-1 grow-0 md:basis-1/2">
          <BentoCoreGraphic handleClick={handleClick} activePath={activePath} />
        </div>
        <div className="flex-1 grow-0 md:basis-1/2">
          <BentoCoreDetails
            items={items as BentoCoreModulesCollectionItem[]}
            activePath={activePath}
          />
        </div>
      </div>
    </ProductContainer>
  );
};

export { BentoCore };

import * as React from "react";

export type ProductFilterButtonProps = {
  heading: string;
};

const ProductFilterButton = ({ heading }: ProductFilterButtonProps) => {
  const id = heading.toLowerCase().split(" ").join("-");

  return (
    <a
      href={"#" + id}
      className="pr-button pr-button-lg pr-button whitespace-nowrap bg-ada-teal-400 text-pr-sm text-white ease-in hover:bg-ada-salmon-500 md:text-pr-lg"
    >
      {heading}
    </a>
  );
};

export { ProductFilterButton };

import * as React from "react";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { ProductContainer, ContentfulImage, SmartLink } from "@src/components";
import { IContentfulImage } from "@src/lib/types";
import { ProductHomepageCarouselCarouselItem } from "@src/lib/services/server/contentful";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export type ProductHeroHomepageProps = {
  heading?: string | null;
  text?: string | null;
  image?: IContentfulImage | null;
  mobileCarousel?: ProductHomepageCarouselCarouselItem[];
  desktopCarousel?: ProductHomepageCarouselCarouselItem[];
  backgroundImage: IContentfulImage | null;
  heroStyle?: string | null;
  headingColor?: string | null;
  textColor?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductHeroHomepage({
  heading,
  text,
  image,
  mobileCarousel,
  desktopCarousel,
  backgroundImage,
  heroStyle,
  headingColor,
  textColor,
  ctaTitle,
  ctaUrl,
  ctaStyle
}: ProductHeroHomepageProps) {
  const imageDimensions = {
    width: image?.width || 1476,
    height: image?.height || 898
  };

  const imageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: imageDimensions.width,
    height: imageDimensions.height
  };

  const backgroundImageDimensions = {
    width: 1600,
    height: 1600
  };

  const backgroundImageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: backgroundImageDimensions.width,
    height: backgroundImageDimensions.height
  };

  const defaultHeroStyle: string = "teal";
  const defaultTextColor: string = "white";
  const defaultCtaStyle: string = "black";

  const classNames: { [key: string]: string } = {
    red: `bg-pr-red-500 relative overflow-hidden pb-9 ${
      backgroundImage ? "pt-12" : "pt-8"
    }`,
    green: `bg-pr-green-500 relative overflow-hidden pb-9 ${
      backgroundImage ? "pt-12" : "pt-8"
    }`,
    yellow: `bg-pr-yellow-500 relative overflow-hidden pb-9 ${
      backgroundImage ? "pt-12" : "pt-8"
    }`,
    teal: `bg-pr-teal-600 relative overflow-hidden pb-9 ${
      backgroundImage ? "pt-12" : "pt-8"
    }`,
    cream: `bg-pr-yellow-200 relative overflow-hidden pb-9 ${
      backgroundImage ? "pt-12" : "pt-8"
    }`
  };

  const textColClassNames: { [key: string]: string } = {
    white: "text-white",
    red: "text-pr-red-500",
    green: "text-pr-green-500",
    yellow: "text-pr-yellow-500",
    teal: "text-pr-teal-600"
  };

  const ctaClassNames: { [key: string]: string } = {
    red: "pr-button-red",
    green: "pr-button bg-pr-green-500 text-white",
    yellow: "pr-button bg-pr-yellow-500 text-white",
    teal: "pr-button text-white bg-pr-teal-500 text-white",
    black: "pr-button-black"
  };

  const className = classNames[heroStyle ? heroStyle : defaultHeroStyle];
  const headingClassName =
    textColClassNames[headingColor ? headingColor : defaultTextColor];
  const textColClassName =
    textColClassNames[textColor ? textColor : defaultTextColor];
  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <div className={`${className}`}>
      {backgroundImage && (
        <figure className="absolute top-0 h-[35%] w-full object-cover object-left-top md:right-0 md:h-full md:w-1/2">
          <ContentfulImage
            image={backgroundImage}
            transforms={backgroundImageTransforms}
            layout="fill"
            className="object-cover object-left-top"
            priority={true}
          />
        </figure>
      )}

      <ProductContainer>
        <div className="relative grid gap-[35px] md:grid-cols-2 md:gap-0">
          {heading && (
            <h1 className={`type-pr-beta md:hidden ${headingClassName}`}>
              {returnLineBreaks(heading)}
            </h1>
          )}
          <div className="order-2 flex w-full flex-col items-start justify-center space-y-8 md:order-1">
            <div className="md:pr-[20%] lg:space-y-9">
              {heading && (
                <h1
                  className={`type-pr-beta hidden md:block ${headingClassName}`}
                >
                  {returnLineBreaks(heading)}
                </h1>
              )}

              {text && (
                <div className={`type-pr-zeta ${textColClassName}`}>
                  <p>{returnLineBreaks(text)}</p>
                </div>
              )}
            </div>

            {ctaUrl && ctaTitle && (
              <SmartLink
                href={ctaUrl}
                className={`pr-button pr-button-lg mx-auto !mt-4 lg:mx-0 ${ctaClassName}`}
              >
                {ctaTitle}
              </SmartLink>
            )}
          </div>
          <div
            className={`${
              mobileCarousel && mobileCarousel.length > 0
                ? "hidden md:flex "
                : " flex "
            } ${
              desktopCarousel && desktopCarousel.length > 0
                ? "md:hidden "
                : " flex "
            } order-1 -mx-5 flex-col justify-end md:order-2 md:mx-0 md:ml-[clamp(-15%,2.5vw,-18.75rem)]`}
          >
            <figure>
              <ContentfulImage
                image={image}
                transforms={imageTransforms}
                priority={true}
              />
            </figure>
          </div>
          {desktopCarousel && desktopCarousel.length > 0 && (
            <div className="order-1 -mx-5 hidden flex-col justify-end md:order-2 md:mx-0 md:ml-[clamp(-15%,2.5vw,-18.75rem)] lg:flex">
              <Swiper
                modules={[Autoplay]}
                spaceBetween={16}
                slidesPerView={"auto"}
                centerInsufficientSlides={true}
                className="homepageHero"
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false
                }}
              >
                {desktopCarousel.map((item, index) => (
                  <SwiperSlide key={index}>
                    <figure>
                      <img
                        src={item.url || ""}
                        alt={item.description || ""}
                      ></img>
                    </figure>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          {mobileCarousel && mobileCarousel.length > 0 && (
            <div className="order-1 flex-col justify-end md:hidden">
              <Swiper
                modules={[Autoplay]}
                slidesPerView={"auto"}
                centerInsufficientSlides={true}
                className="homepageHero"
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false
                }}
              >
                {mobileCarousel.map((item, index) => (
                  <SwiperSlide key={index}>
                    <figure>
                      <img
                        src={item.url || ""}
                        alt={item.description || ""}
                      ></img>
                    </figure>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductHeroHomepage;

/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import { ContentfulImage, RichText, MarketoForm } from "@src/components";
import IframeResizer from "iframe-resizer-react";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { StringValueNode, validate } from "graphql";
import { keyMapping } from "@src/lib/utils";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type CtaFormProps = {
  media?: IContentfulImage | null;
  eyebrow?: string | null;
  heading?: string | null;
  text?: Document | null;
  ctaTitle?: string | null;
  theme?: string | null;
  headingStyle?: string | null;
  centeredEyebrow?: boolean;
  responsive?: boolean;
  formKey?: string | null;
  source?: string | null;
  formId?: string | null;
  ctaId?: string | null;
  formTheme?: string | null;
  finePrint?: Document | null;
};

const processGa = (str: string) => {
  return str.split(".").slice(-2).join(".");
};

export function CtaForm({
  media,
  eyebrow,
  heading,
  text,
  ctaTitle,
  theme = "red",
  headingStyle = "large",
  centeredEyebrow = false,
  responsive = false,
  formKey,
  source,
  formId,
  ctaId,
  formTheme,
  finePrint
}: CtaFormProps) {
  const router = useRouter();
  const cookies = parseCookies();
  const queryParsed = router.query;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hiddenFields: string[] = [];
  const id = ctaId || undefined;

  const dimensions = {
    width: media?.width || 228,
    height: media?.height || 346
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  // set hidden fields from params
  for (const [key, value] of Object.entries(queryParsed)) {
    if (Object.keys(keyMapping).includes(key)) {
      const valueString = value as string;
      hiddenFields.push(`${key}=${encodeURIComponent(valueString)}`);
    }
  }

  // set hidden fields from cookies
  for (const [key, value] of Object.entries(cookies)) {
    if (Object.keys(keyMapping).includes(key)) {
      hiddenFields.push(
        `${keyMapping[key]}=${
          key == "_ga"
            ? processGa(cookies[key])
            : encodeURIComponent(cookies[key])
        }`
      );
    }
  }

  const defaultTheme: string = "red";
  const defaultHeadingStyle: string = "large";

  const classNames: { [key: string]: string } = {
    red: "lg:pt-7 lg:pb-10 bg-red-500 text-white",
    black: "lg:pt-7 lg:pb-10 bg-black text-white",
    gray: "lg:py-10 bg-blue-100",
    grayctagatedcontent: "lg:py-[50px] bg-blue-100"
  };

  const proseClassNames: { [key: string]: string } = {
    red: `text-white prose-p:text-sm md:prose-p:text-base prose-a:text-white prose-strong:text-white ${
      heading || eyebrow ? "mt-2.5" : ""
    } ${heading && headingStyle == "large" ? "lg:mt-7" : "lg:mt-3.5"}`,
    black: `text-white prose-p:text-sm md:prose-p:text-base prose-a:text-white prose-strong:text-white ${
      heading || eyebrow ? "mt-2.5" : ""
    } ${heading && headingStyle == "large" ? "lg:mt-7" : "lg:mt-3.5"}`,
    gray: `prose-p:text-sm md:prose-p:text-base text-gray-400 ${
      heading || eyebrow ? "mt-2.5" : ""
    } ${heading && headingStyle == "large" ? "lg:mt-7" : "lg:mt-3.5"}`,
    grayctagatedcontent: `prose-p:text-sm md:prose-p:text-base text-gray-400 ${
      heading || eyebrow ? "mt-2.5" : ""
    } ${heading && headingStyle == "large" ? "lg:mt-7" : "lg:mt-3.5"}`
  };

  const linkClassNames: { [key: string]: string } = {
    red: "button-text-red",
    black: "button-text-black",
    gray: "button-text-red",
    grayctagatedcontent: "button-text-red"
  };

  const headingClassNames: { [key: string]: string } = {
    large: "type-beta",
    medium: "type-delta"
  };

  if (formTheme) {
    if (formTheme == "redtwocolumnform" || formTheme == "redonecolumnform") {
      theme = "red";
    } else if (
      formTheme == "graytwocolumnform" ||
      formTheme == "grayonecolumnform"
    ) {
      theme = "grayctagatedcontent";
    }
  }

  const className = classNames[theme ? theme : defaultTheme];
  const proseClassName = proseClassNames[theme ? theme : defaultTheme];
  const linkClassName = linkClassNames[theme ? theme : defaultTheme];
  const headingClassName =
    headingClassNames[headingStyle ? headingStyle : defaultHeadingStyle];

  const defaultFormKey: string = "ctablogsubscription";

  // blog demo request form in pardot: https://pi.pardot.com/form/read/id/8272

  // can remove this after switchover to marketo because source will no longer be a thing
  const formSrcs: { [key: string]: string } = {
    ctablogsubscription: "https://go.getbento.com/l/667503/2022-06-30/45p92",
    ctademorequest: "https://go.getbento.com/l/667503/2022-06-30/45nlg",
    ctagatedcontent: source as string
  };
  const formSrc = formSrcs[formKey ? formKey : defaultFormKey];
  const [src, setSrc] = useState(formSrc);

  useEffect(() => {
    // set Submission_URL
    hiddenFields.push(
      `Submission_URL=${encodeURIComponent(window.location.href)}`
    );

    setSrc(
      hiddenFields.length
        ? `${formSrc}?${hiddenFields.join("&")}`
        : `${formSrc}`
    );
  }, [hiddenFields, formSrc]);
  const [visible, setVisible] = useState(false);

  // bailing out if iframe src is not valid, can remove this after switchover to marketo
  if (source && !source.includes("go.getbento.com")) {
    return null;
  }

  const toggle = () => {
    setVisible(!visible);
  };

  const renderCta = (formKey: string) => {
    switch (formKey) {
      case "ctagatedcontent":
        return (
          <div className={`p-5 lg:px-7 ${className}`}>
            <div className={`flex gap-4 lg:gap-7`}>
              {media && !visible && (
                <div className="flex shrink-0 items-start">
                  <figure className="w-[144px] lg:ml-4">
                    <ContentfulImage image={media} transforms={transforms} />
                  </figure>
                </div>
              )}
              <div className="grow">
                {eyebrow && (
                  <p
                    className={`type-eta ${
                      centeredEyebrow ? "text-center" : ""
                    }`}
                  >
                    {eyebrow}
                  </p>
                )}
                {heading && (
                  <h3
                    className={`${eyebrow ? "mt-2.5" : ""} ${
                      eyebrow && !centeredEyebrow ? "lg:mt-3.5" : ""
                    } ${
                      eyebrow && centeredEyebrow ? "lg:mt-7" : ""
                    } ${headingClassName} `}
                  >
                    {heading}
                  </h3>
                )}
                {text && (
                  <div className={`prose max-w-none ${proseClassName}`}>
                    <RichText text={text} />
                  </div>
                )}
                {ctaTitle && !visible && (
                  <div
                    className={`hidden justify-start sm:flex ${
                      heading || eyebrow || text ? "mt-7" : ""
                    }`}
                  >
                    <button
                      className={`${linkClassName}`}
                      onClick={toggle}
                      id={id}
                    >
                      {ctaTitle}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {ctaTitle && !visible && (
              <div
                className={`flex justify-center sm:hidden ${
                  heading || eyebrow || text ? "mt-5" : ""
                }`}
              >
                <button
                  className={`w-full ${linkClassName}`}
                  onClick={toggle}
                  id={id}
                >
                  {ctaTitle}
                </button>
              </div>
            )}
            {formId && (
              <div className={visible ? "" : "hidden"}>
                <MarketoForm formId={formId} formTheme={formTheme} />
                {finePrint && formTheme && (
                  <div className={`${formTheme}`}>
                    <RichText text={finePrint} />
                  </div>
                )}
              </div>
            )}
            {visible && src && !formId && (
              <IframeResizer
                src={src}
                style={{ width: "100%", marginTop: "2.5rem" }}
                loading="lazy"
              />
            )}
          </div>
        );
      default:
        return (
          <div
            className={`flex flex-col gap-8 p-5 lg:px-7 lg:pb-10 lg:pt-7 ${className} ${
              responsive ? "lg:flex-row lg:gap-12" : ""
            }`}
          >
            <div
              className={`flex items-center gap-4 lg:gap-12 ${
                responsive ? "lg:w-1/2" : ""
              }`}
            >
              <div>
                {eyebrow && (
                  <p
                    className={`type-eta ${
                      centeredEyebrow ? "text-center" : ""
                    }`}
                  >
                    {eyebrow}
                  </p>
                )}
                {heading && (
                  <h3
                    className={`${eyebrow ? "mt-2.5" : ""} ${
                      eyebrow && !centeredEyebrow ? "lg:mt-3.5" : ""
                    } ${
                      eyebrow && centeredEyebrow ? "lg:mt-7" : ""
                    } ${headingClassName} `}
                  >
                    {heading}
                  </h3>
                )}
                {text && (
                  <div className={`prose max-w-none ${proseClassName}`}>
                    <RichText text={text} />
                  </div>
                )}
              </div>
            </div>
            <div
              className={`flex items-center ${responsive ? "lg:w-1/2" : ""}`}
            >
              {formId && (
                <div className="w-full">
                  <MarketoForm formId={formId} formTheme={formTheme} />
                  {finePrint && formTheme && (
                    <div className={`${formTheme}`}>
                      <RichText text={finePrint} />
                    </div>
                  )}
                </div>
              )}
              {src && !formId && (
                <IframeResizer
                  src={src}
                  style={{ width: "100%" }}
                  loading="lazy"
                />
              )}
            </div>
          </div>
        );
    }
  };

  return <>{renderCta(formKey as string)}</>;
}

export default CtaForm;

import { PricingContentModules3ColumnCta } from "@src/lib/services/server/contentful";
import * as React from "react";
import { ProductContainer } from "../ProductContainer";

export type Pricing3ColumnCtaProps = {
  content: PricingContentModules3ColumnCta;
};

const Pricing3ColumnCta = ({ content }: Pricing3ColumnCtaProps) => {
  const { title, description, ctaTitle, ctaUrl, list, image } = content;

  return (
    <div className="w-full bg-gradient-to-b from-ada-salmon-500 from-50% to-pr-yellow-200 to-50%">
      <ProductContainer>
        <div className="flex flex-col gap-12 rounded-xl bg-pr-yellow-200 p-6 text-center outline outline-1 -outline-offset-1 outline-pr-dark-gray-300 lg:flex-row lg:text-left">
          <div className="flex basis-full flex-col content-center items-center justify-center gap-6 lg:ml-6 lg:items-start">
            <div className="max-w-xs leading-relaxed">
              <h3 className="text-3xl font-bold">{title}</h3>
              <p className="text-balance">{description}</p>
            </div>
            <a
              href={ctaUrl ?? ""}
              className="w-fit rounded-full border border-ada-salmon-500 px-5 py-2 text-ada-salmon-500 transition delay-75 hover:bg-ada-salmon-500 hover:text-pr-yellow-200"
            >
              {ctaTitle}
            </a>
          </div>
          <div className="basis-full content-center">
            <ul className="leading-10">
              {list?.split("\n").map(item => (
                <li
                  key={item}
                  className="border-t border-black first-of-type:border-none"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="basis-full content-center">
            <img
              src={image?.url ?? ""}
              alt={image?.title ?? ""}
              className="w-full rounded-xl outline outline-1 -outline-offset-1 outline-pr-dark-gray-300"
            ></img>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
};

export { Pricing3ColumnCta };

import * as React from "react";
import { Statistic } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText } from "@src/components";

export type ProductStatisticsBlockProps = {
  items: Statistic[];
};

export function ProductStatisticsBlock({ items }: ProductStatisticsBlockProps) {
  const renderItems = (items: Statistic[]) => {
    return items.map((item, index) => {
      const text = item.text?.json as Document | undefined;
      switch (index) {
        case 0:
          return (
            <div className="flex pr-[88px] md:p-0" key={index}>
              <div className="flex min-h-[110px] grow items-center justify-center rounded-r-[80px] bg-pr-teal-600 p-5 lg:rounded-r-[150px] lg:py-12">
                <div className="max-w-[240px] space-y-2 text-center lg:space-y-3">
                  <h3 className="type-pr-beta">{item.heading}</h3>
                  {text && (
                    <div className="text-pr-xs lg:text-pr-xl">
                      <RichText text={text} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        case 1:
          return (
            <div className="flex pl-[88px] md:p-0" key={index}>
              <div className="flex min-h-[110px] grow items-center justify-center space-y-2 rounded-t-[80px] bg-pr-dark-gray-700 p-5 lg:rounded-t-[150px] lg:py-12">
                <div className="max-w-[240px] space-y-2 text-center lg:space-y-3">
                  <h3 className="type-pr-beta">{item.heading}</h3>
                  {text && (
                    <div className="text-pr-xs lg:text-pr-xl">
                      <RichText text={text} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        case 2:
          return (
            <div className="flex pr-[88px] md:p-0" key={index}>
              <div className="flex min-h-[110px] grow items-center justify-center space-y-2 rounded-bl-[80px] rounded-tr-[80px] bg-pr-red-500 p-5 lg:rounded-bl-[150px] lg:rounded-tr-[150px] lg:py-12">
                <div className="max-w-[240px] space-y-2 text-center lg:space-y-3">
                  <h3 className="type-pr-beta">{item.heading}</h3>
                  {text && (
                    <div className="text-pr-xs lg:text-pr-xl">
                      <RichText text={text} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
      }
    });
  };

  return (
    <div className="my-10 md:my-16 lg:my-24">
      <ProductContainer fullWidth={true}>
        <div className="grid gap-2 text-white md:-mx-5 md:grid-cols-3 md:gap-0 lg:-mx-8 2xl:-mx-24">
          {renderItems(items)}
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductStatisticsBlock;
